import React, { useState, useEffect } from "react";
import classNames from "classnames";
import { useIntl } from "react-intl";
import styles from "./CountdownWidget.module.scss";
import i18n from "./CountdownWidget.i18n";

function pad(input: string, width = 2): string {
  const z = "0";
  return input.length >= width ? input : new Array(width - input.length + 1).join(z) + input;
}

function isCloseToFin(inputDelta: number): boolean {
  const secsLeft = inputDelta / 1000;
  if (secsLeft <= 0 || secsLeft <= 60 * 5) {
    // 5 minutes
    return true;
  }
  return false;
}

function getTimezoneAwareDelta(endsAt: Date, countUp = false): number {
  const now = new Date();
  if (countUp) {
    return now.getTime() - endsAt.getTime() + now.getTimezoneOffset() / 60000;
  }
  return endsAt.getTime() - now.getTime() + now.getTimezoneOffset() / 60000;
}

export interface IProps {
  title: string;
  subTitle?: React.ReactNode;
  endsAt: Date;
  countUp?: boolean;
  isFlashingWhenCloseToFin?: boolean;
  className?: string;
  style?: React.CSSProperties;
}

export default function CountdownWidget({
  title,
  subTitle,
  endsAt,
  countUp,
  isFlashingWhenCloseToFin = true,
  className,
  style,
}: IProps): React.ReactElement {
  const { formatMessage } = useIntl();
  const [delta, setDelta] = useState<number>(getTimezoneAwareDelta(endsAt, countUp));
  const [isCloseToFinish, setIsCloseToFinish] = useState<boolean>(isCloseToFin(delta));
  const seconds = Math.floor((delta / 1000) % 60);
  const minutes = Math.floor((delta / 1000 / 60) % 60);
  const hours = Math.floor((delta / (1000 * 60 * 60)) % 24);
  const days = Math.floor(delta / (1000 * 60 * 60 * 24));

  useEffect(() => {
    const interval = setInterval(() => {
      const newDelta = getTimezoneAwareDelta(endsAt, countUp);
      setDelta(newDelta);
      setIsCloseToFinish(isCloseToFin(newDelta));
    }, 1000);
    return (): void => clearInterval(interval);
  }, [endsAt, countUp]);

  return (
    <div
      className={classNames(
        styles.countdown,
        isFlashingWhenCloseToFin && isCloseToFinish && styles.closeToFin,
        className,
      )}
      style={style}
    >
      <div className={styles.title}>{title}</div>
      {subTitle && <div className={styles.subTitle}>{subTitle}</div>}
      <div className={styles.timer}>
        <span style={{ whiteSpace: "nowrap" }}>
          <div className={`${styles.segment} ${styles.days}`}>
            <div className={styles.value}>{days > 0 ? pad(days.toString()) : "00"}</div>
            <div className={styles.identifier}>{formatMessage(i18n["countdown-days"])}</div>
          </div>
          <div className={`${styles.segment} ${styles.hours}`}>
            <div className={styles.value}>{hours > 0 ? pad(hours.toString()) : "00"}</div>
            <div className={styles.identifier}>{formatMessage(i18n["countdown-hours"])}</div>
          </div>
        </span>
        <span style={{ whiteSpace: "nowrap" }}>
          <div className={`${styles.segment} ${styles.minutes}`}>
            <div className={styles.value}> {minutes > 0 ? pad(minutes.toString()) : "00"}</div>
            <div className={styles.identifier}>{formatMessage(i18n["countdown-minutes"])}</div>
          </div>
          <div className={`${styles.segment} ${styles.seconds}`}>
            <div className={styles.value}>{seconds > 0 ? pad(seconds.toString()) : "00"}</div>
            <div className={styles.identifier}>{formatMessage(i18n["countdown-seconds"])}</div>
          </div>
        </span>
      </div>
    </div>
  );
}
