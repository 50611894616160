import React, { useContext } from "react";
import { Routes as ReactRoutes, Route, Navigate, useLocation, Outlet } from "react-router-dom";
import { EFeatureToggleId, FeatureTogglesContext, UserContext } from "../contexts";
import * as ROUTES from "./paths";
import * as PAGES from "./lazyPages";

function RoutesAccount(): React.ReactElement {
  const { isFeatureToggleEnabled } = useContext(FeatureTogglesContext);
  const { isLoggedIn, isGm } = useContext(UserContext);
  return (
    <>
      {!isLoggedIn && (
        <Route path={ROUTES.LOGIN} element={<Outlet />}>
          <Route index element={<PAGES.LoginPage />} />
          <Route path="?action" element={<PAGES.LoginPage />} /> {/* TODO: perform actions on security-center route */}
        </Route>
      )}
      {!isLoggedIn && isFeatureToggleEnabled(EFeatureToggleId.PAGE_ACCOUNT_REGISTRATION) && (
        <Route path={ROUTES.REGISTER} element={<PAGES.RegisterPage />} />
      )}
      {isLoggedIn && isFeatureToggleEnabled(EFeatureToggleId.PAGE_COLLECTIONS_USERS) && (
        <Route path={ROUTES.USERS} element={<Outlet />}>
          {isGm ? (
            <Route index element={<PAGES.UsersPage />} />
          ) : (
            <Route index element={<Navigate replace to={ROUTES.HOME} />} />
          )}
          <Route path=":username" element={<Outlet />}>
            <Route index element={<Navigate replace to="profile" />} />
            <Route path="profile" element={<PAGES.UserProfilePage />} />
            <Route path="characters" element={<PAGES.UserCharactersPage />} />
            <Route path="settings" element={<PAGES.UserSettingsPage />} />
            <Route path="bank" element={<PAGES.UserBankPage />} />
            <Route path="friendlist" element={<PAGES.UserFriendlistPage />} />
            <Route path="logs" element={<PAGES.UserLogsPage />} />
          </Route>
        </Route>
      )}
    </>
  );
}

function RoutesAnnouncements(): React.ReactElement {
  const { isFeatureToggleEnabled } = useContext(FeatureTogglesContext);
  return (
    <>
      {isFeatureToggleEnabled(EFeatureToggleId.PAGE_ANNOUNCEMENTS_EVENTS) && (
        <Route path={`${ROUTES.EVENTS}/:id`} element={<PAGES.EventsPage />} />
      )}
      {isFeatureToggleEnabled(EFeatureToggleId.PAGE_ANNOUNCEMENTS_GM_NOTES) && (
        <Route path={ROUTES.GM_NOTES} element={<PAGES.GmNotesPage />} />
      )}
      {isFeatureToggleEnabled(EFeatureToggleId.PAGE_ANNOUNCEMENTS_NOTICES) && (
        <Route path={ROUTES.NOTICES} element={<PAGES.NoticesPage />} />
      )}
      {isFeatureToggleEnabled(EFeatureToggleId.PAGE_ANNOUNCEMENTS_UPDATES) && (
        <Route path={ROUTES.UPDATES} element={<PAGES.UpdatesPage />} />
      )}
    </>
  );
}

function RoutesCollections(): React.ReactElement {
  const { isFeatureToggleEnabled } = useContext(FeatureTogglesContext);
  const { isLoggedIn, isGm } = useContext(UserContext);
  return (
    <>
      {isLoggedIn && isFeatureToggleEnabled(EFeatureToggleId.PAGE_COLLECTIONS_CHARACTERS) && (
        <Route path={ROUTES.CHARACTERS} element={<Outlet />}>
          {isGm ? (
            <Route index element={<PAGES.HomePage />} /> /* TODO: CharactersPage */
          ) : (
            <Route index element={<Navigate replace to={ROUTES.HOME} />} />
          )}
          <Route path=":characterId" element={<PAGES.HomePage />} /> {/* TODO: CharacterPage */}
        </Route>
      )}
    </>
  );
}

function RoutesGame(): React.ReactElement {
  const { isFeatureToggleEnabled } = useContext(FeatureTogglesContext);
  return (
    <>
      {isFeatureToggleEnabled(EFeatureToggleId.PAGE_GAME_ADVENTURERS_GUIDE) && (
        <Route path={ROUTES.ADVENTURERS_GUIDE} element={<PAGES.AdventurersGuidePage />} />
      )}
      {isFeatureToggleEnabled(EFeatureToggleId.PAGE_GAME_CLASS_INTRODUCTION) && (
        <Route path={ROUTES.CLASS_INTRODUCTION} element={<PAGES.ClassIntroductionPage />} />
      )}
      {isFeatureToggleEnabled(EFeatureToggleId.PAGE_GAME_FEATURES) && (
        <Route path={ROUTES.FEATURES} element={<PAGES.FeaturesPage />} />
      )}
      {isFeatureToggleEnabled(EFeatureToggleId.PAGE_GAME_HISTORY) && (
        <Route path={ROUTES.HISTORY} element={<PAGES.HistoryPage />} />
      )}
      {isFeatureToggleEnabled(EFeatureToggleId.PAGE_GAME_LORE) && (
        <Route path={ROUTES.LORE} element={<PAGES.LorePage />} />
      )}
      {isFeatureToggleEnabled(EFeatureToggleId.PAGE_GAME_STATISTICS) && (
        <Route path={ROUTES.STATISTICS} element={<PAGES.StatisticsPage />} />
      )}
      {isFeatureToggleEnabled(EFeatureToggleId.PAGE_GAME_RULES) && (
        <Route path={ROUTES.RULES} element={<PAGES.RulesPage />} />
      )}
    </>
  );
}

function RoutesLegal(): React.ReactElement {
  const { isFeatureToggleEnabled } = useContext(FeatureTogglesContext);
  return (
    <>
      {isFeatureToggleEnabled(EFeatureToggleId.PAGE_LEGAL_IMPRINT) && (
        <Route path={ROUTES.IMPRINT} element={<PAGES.ImprintPage />} />
      )}
      {isFeatureToggleEnabled(EFeatureToggleId.PAGE_LEGAL_EULA) && (
        <Route path={ROUTES.EULA} element={<PAGES.EulaPage />} />
      )}
      {isFeatureToggleEnabled(EFeatureToggleId.PAGE_LEGAL_TOS) && (
        <Route path={ROUTES.TOS} element={<PAGES.TosPage />} />
      )}
      {isFeatureToggleEnabled(EFeatureToggleId.PAGE_LEGAL_PRIVACY_POLICY) && (
        <Route path={ROUTES.PRIVACY_POLICY} element={<PAGES.PrivacyPolicyPage />} />
      )}
    </>
  );
}

function RoutesWiki(): React.ReactElement {
  const { isFeatureToggleEnabled } = useContext(FeatureTogglesContext);
  return (
    <>
      {isFeatureToggleEnabled(EFeatureToggleId.PAGE_WIKI_CLASSES) && (
        <Route path={ROUTES.CLASSES} element={<Outlet />}>
          <Route index element={<PAGES.ClassesPage />} />
          <Route path=":id" element={<PAGES.ClassPage />} />
        </Route>
      )}
      {isFeatureToggleEnabled(EFeatureToggleId.PAGE_WIKI_CRAFTING) && (
        <Route path={ROUTES.CRAFTING} element={<PAGES.CraftingPage />} />
      )}
      {isFeatureToggleEnabled(EFeatureToggleId.PAGE_WIKI_ITEMS) && (
        <Route path={ROUTES.ITEMS} element={<Outlet />}>
          <Route index element={<PAGES.ItemsPage />} />
          <Route path=":itemCategoryId" element={<Outlet />}>
            <Route index element={<PAGES.ItemsPage />} /> {/* TODO: ItemCategoryPage */}
            <Route path=":itemTypeId" element={<PAGES.ItemsPage />} /> {/* TODO: ItemTypePage */}
          </Route>
        </Route>
      )}
      {isFeatureToggleEnabled(EFeatureToggleId.PAGE_WIKI_MAPS) && (
        <Route path={ROUTES.MAPS} element={<Outlet />}>
          <Route index element={<PAGES.MapsPage />} />
          <Route path=":id" element={<PAGES.MapsPage />} /> {/* TODO: MapPage */}
        </Route>
      )}
      {isFeatureToggleEnabled(EFeatureToggleId.PAGE_WIKI_MONSTERS) && (
        <Route path={ROUTES.MONSTERS} element={<Outlet />}>
          <Route index element={<PAGES.MonstersPage />} />
          <Route path=":monsterModelId" element={<PAGES.MonstersPage />} /> {/* TODO: MonsterModelPage */}
        </Route>
      )}
      {isFeatureToggleEnabled(EFeatureToggleId.PAGE_WIKI_RACES) && (
        <Route path={ROUTES.RACES} element={<PAGES.RacesPage />} />
      )}
      {isFeatureToggleEnabled(EFeatureToggleId.PAGE_WIKI_SKILLS) && (
        <Route path={ROUTES.SKILLS} element={<PAGES.SkillsPage />}>
          <Route index element={<PAGES.SkillsPage />} />
          <Route path=":skillModelId" element={<PAGES.SkillsPage />} /> {/* TODO: SkillModelPage */}
        </Route>
      )}
    </>
  );
}

function RoutesMultimedia(): React.ReactElement {
  const { isFeatureToggleEnabled } = useContext(FeatureTogglesContext);
  return (
    <>
      {isFeatureToggleEnabled(EFeatureToggleId.PAGE_MULTIMEDIA_ARTWORKS) && (
        <Route path={ROUTES.ARTWORKS} element={<PAGES.ArtworksPage />} />
      )}
      {isFeatureToggleEnabled(EFeatureToggleId.PAGE_MULTIMEDIA_DOWNLOADS) && (
        <Route path={ROUTES.DOWNLOADS} element={<PAGES.DownloadsPage />} />
      )}
      {isFeatureToggleEnabled(EFeatureToggleId.PAGE_MULTIMEDIA_SCREENSHOTS) && (
        <Route path={ROUTES.SCREENSHOTS} element={<PAGES.ScreenshotsPage />} />
      )}
      {isFeatureToggleEnabled(EFeatureToggleId.PAGE_MULTIMEDIA_SOUNDTRACKS) && (
        <Route path={ROUTES.SOUNDTRACKS} element={<PAGES.SoundtracksPage />} />
      )}
      {isFeatureToggleEnabled(EFeatureToggleId.PAGE_MULTIMEDIA_VIDEOS) && (
        <Route path={ROUTES.VIDEOS} element={<PAGES.VideosPage />} />
      )}
      {isFeatureToggleEnabled(EFeatureToggleId.PAGE_MULTIMEDIA_WALLPAPERS) && (
        <Route path={ROUTES.WALLPAPERS} element={<PAGES.WallpapersPage />} />
      )}
    </>
  );
}

function RoutesSupport(): React.ReactElement {
  const { isFeatureToggleEnabled } = useContext(FeatureTogglesContext);
  const { isGm } = useContext(UserContext);
  return (
    <>
      {isGm && isFeatureToggleEnabled(EFeatureToggleId.PAGE_SUPPORT_ADMIN) && (
        <Route path={ROUTES.ADMIN} element={<PAGES.AdminPage />} />
      )}
      {isFeatureToggleEnabled(EFeatureToggleId.PAGE_SUPPORT_SUBMIT_TICKET) && (
        <Route path={ROUTES.SUBMIT_TICKET} element={<PAGES.SubmitTicketPage />} />
      )}
      {isFeatureToggleEnabled(EFeatureToggleId.PAGE_SUPPORT_CONTACT) && (
        <Route path={ROUTES.CONTACT} element={<PAGES.ContactPage />} />
      )}
      {isFeatureToggleEnabled(EFeatureToggleId.PAGE_SUPPORT_DEVELOPMENT) && (
        <Route path={ROUTES.DEVELOPMENT} element={<PAGES.DevelopmentPage />} />
      )}
      {isFeatureToggleEnabled(EFeatureToggleId.PAGE_SUPPORT_FAQ) && (
        <Route path={ROUTES.FAQ} element={<PAGES.FaqPage />} />
      )}
      {isFeatureToggleEnabled(EFeatureToggleId.PAGE_SUPPORT_HELP) && (
        <Route path={ROUTES.HELP} element={<PAGES.HelpPage />} />
      )}
      {isFeatureToggleEnabled(EFeatureToggleId.PAGE_SUPPORT_SECURITYCENTER) && (
        <Route path={ROUTES.SECURITY_CENTER} element={<PAGES.SecurityCenterPage />}>
          <Route path={ROUTES.VERIFY_EMAIL.replace(/^\//, "")} element={<PAGES.SecurityCenterPage action="xx" />} />
          <Route path={ROUTES.FORGOT_PASSWORD.replace(/^\//, "")} element={<PAGES.SecurityCenterPage action="xx" />} />
          <Route
            path={ROUTES.RESEND_VERIFICATION_MAIL.replace(/^\//, "")}
            element={<PAGES.SecurityCenterPage action="xx" />}
          />
          <Route path={ROUTES.RESET_PASSWORD.replace(/^\//, "")} element={<PAGES.SecurityCenterPage action="xx" />} />
        </Route>
      )}
    </>
  );
}

function RoutesRedirect(): React.ReactElement {
  const { search } = useLocation();
  return (
    <>
      <Route path="/team" element={<Navigate replace to={`${ROUTES.DEVELOPMENT}${search}`} />} />
      <Route path="/gallery" element={<Navigate replace to={`${ROUTES.SCREENSHOTS}${search}`} />} />
      <Route path="/server-statistics" element={<Navigate replace to={`${ROUTES.STATISTICS}${search}`} />} />
      <Route
        path={ROUTES.VERIFY_EMAIL}
        element={<Navigate replace to={`${ROUTES.SECURITY_CENTER}${ROUTES.VERIFY_EMAIL}${search}`} />}
      />
      <Route
        path={ROUTES.FORGOT_PASSWORD}
        element={<Navigate replace to={`${ROUTES.SECURITY_CENTER}${ROUTES.FORGOT_PASSWORD}${search}`} />}
      />
      <Route
        path={ROUTES.RESEND_VERIFICATION_MAIL}
        element={<Navigate replace to={`${ROUTES.SECURITY_CENTER}${ROUTES.RESEND_VERIFICATION_MAIL}${search}`} />}
      />
      <Route
        path={ROUTES.RESET_PASSWORD}
        element={<Navigate replace to={`${ROUTES.SECURITY_CENTER}${ROUTES.RESET_PASSWORD}${search}`} />}
      />
    </>
  );
}

export default function Routes(): React.ReactElement {
  const { search } = useLocation();
  const { user } = useContext(UserContext);
  let defaultRoute = ROUTES.HOME;
  if (user) defaultRoute = `${ROUTES.USERS}/${user.username}`;
  return (
    <ReactRoutes>
      <Route path={ROUTES.HOME} element={<PAGES.HomePage />} />
      {RoutesAccount()}
      {RoutesAnnouncements()}
      {RoutesCollections()}
      {RoutesGame()}
      {RoutesLegal()}
      {RoutesWiki()}
      {RoutesMultimedia()}
      {RoutesSupport()}
      {RoutesRedirect()}
      <Route path="*" element={<Navigate replace to={`${defaultRoute}${search}`} />} />
      {/* TODO: for wildcard *, create a NotFoundPage that displays HomePage with a notification */}
    </ReactRoutes>
  );
}
