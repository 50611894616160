import { defineMessages } from "react-intl";

export default defineMessages({
  online: {
    id: "header.serverStatus.online",
    defaultMessage: "Online",
    description: "",
  },
  offline: {
    id: "header.serverStatus.offline",
    defaultMessage: "Offline",
    description: "",
  },
  unknown: {
    id: "header.serverStatus.unknown",
    defaultMessage: "Unknown",
    description: "",
  },
  gameServer: {
    id: "header.serverStatus.gameServer",
    defaultMessage: "Game Server",
    description: "",
  },
  loginServer: {
    id: "header.serverStatus.loginServer",
    defaultMessage: "Login Server",
    description: "",
  },
  patchServer: {
    id: "header.serverStatus.patchServer",
    defaultMessage: "Patch Server",
    description: "",
  },
});
