import { DefaultOptions } from "@apollo/client";

const defaultOptions: DefaultOptions = {
  watchQuery: {
    fetchPolicy: "cache-and-network",
    errorPolicy: "all",
  },
  query: {
    fetchPolicy: "network-only", // TODO: enable caching when the main functionality is given
    errorPolicy: "all",
  },
  mutate: {
    errorPolicy: "all",
  },
};

export default defaultOptions;
