import { IWebsiteCharacterRace } from "../../interfaces";
import { archer, cleric, summoner, wizard } from "../characterClasses";

const characterRace: IWebsiteCharacterRace = {
  id: "highelf",
  isEnabled: true,
  i18nTag: {
    en: "He",
    de: "He",
  },
  i18nName: {
    en: "High-Elf",
    de: "Hochelf",
  },
  i18nDescription: {
    en: "Messenger from the Gods. Little is known about the High-Elves, only that they have long life spans, beautiful faces and bodies. High-Elves are regarded as the second most powerful beings, next to Dragons.",
    /*
    Messenger from the Gods. Little is known about the High-Elves, only that they have long life spans,
    beautiful faces and bodies. High-Elves are regarded as the second most powerful beings, next to Dragons.
    */
    de: "Götterboten. Über die Hochelfen ist wenig bekannt. Man weiß nur, dass sie eine hohe Lebenserwartung, schöne Gesichter und Körper haben. Hochelfen sind neben den Drachen die zweitmächtigsten Wesen.",
  },
  mainAttributesBases: {
    vitality: 7,
    dexterity: 11,
    intelligence: 13,
    strength: 7,
  },
  allowsCharacterClasses: [archer, cleric, summoner, wizard],
};

export default characterRace;
