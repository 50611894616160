import { useIntl } from "react-intl";
import { ILanguage } from "../interfaces";
import { getSupportedLocales } from "../i18n";
import i18n from "./useSupportedLanguages.i18n";
import { ASSETS_COUNTRY_FLAGS } from "../assets";

/**
 * A hook to get all supported languages.
 * @returns A list of supported languages.
 * NOTE: This is not 100% DRY. Consider changing the following as well:
 * - package.json
 * - src/i18n/getTranslations.ts
 * - src/i18n/storage.ts
 */
export default function useSupportedLanguages(): [Array<ILanguage>] {
  const { formatMessage } = useIntl();

  const languages: Array<ILanguage> = [
    {
      locale: "en-GB",
      isEnabled: true,
      label: formatMessage(i18n.en),
      iconSrc: `${ASSETS_COUNTRY_FLAGS}/gb.gif`,
    },
    {
      locale: "de",
      isEnabled: true,
      label: formatMessage(i18n.de),
      iconSrc: `${ASSETS_COUNTRY_FLAGS}/de.gif`,
    },
    {
      locale: "ko",
      isEnabled: true,
      label: formatMessage(i18n.ko),
      iconSrc: `${ASSETS_COUNTRY_FLAGS}/kr.gif`,
    },
    {
      locale: "tr",
      isEnabled: true,
      label: formatMessage(i18n.tr),
      iconSrc: `${ASSETS_COUNTRY_FLAGS}/tr.gif`,
    },
    {
      locale: "pt",
      isEnabled: true,
      label: formatMessage(i18n.pt),
      iconSrc: `${ASSETS_COUNTRY_FLAGS}/br.gif`,
    },
    {
      locale: "he",
      isEnabled: true,
      label: formatMessage(i18n.he),
      iconSrc: `${ASSETS_COUNTRY_FLAGS}/il.gif`,
      isRtl: true,
    },
  ];

  const supportedLocales = getSupportedLocales();
  if (supportedLocales.length !== languages.length) {
    // TODO: we should not use 2 places of definining the languages
    throw new Error("something is wrong");
  }

  return [languages];
}
