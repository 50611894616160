import React, { useCallback, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars } from "@fortawesome/free-solid-svg-icons";
import classNames from "classnames";
import { IfUserIsLoggedOut } from "../../../contexts";
import styles from "./Header.module.scss";
import ResponsiveMenu from "../ResponsiveMenu/ResponsiveMenu";
import {
  Logo,
  MusicPlayer,
  Navigation,
  ServerStatus,
  ServerTime,
  LanguageSelector,
  AccountControls,
} from "./components";

export interface IProps {
  className?: string;
  style?: React.CSSProperties;
}

export default function Header({ className, style }: IProps): React.ReactElement {
  const [isResonsiveMenuVisible, setIsResponsiveMenuVisible] = useState<boolean>(false);
  const toggleResponsiveMenu = useCallback(() => {
    setIsResponsiveMenuVisible(!isResonsiveMenuVisible);
  }, [isResonsiveMenuVisible]);

  return (
    <>
      <ResponsiveMenu
        className={classNames(styles.responsiveMenu, isResonsiveMenuVisible && styles.visible)}
        onCloseMenu={toggleResponsiveMenu}
      />
      <header className={classNames(styles.header, className)} style={style}>
        <ServerTime className={styles.serverTime} />
        <div className={styles.content}>
          <a className={styles.navigationBtn} onClick={toggleResponsiveMenu}>
            <FontAwesomeIcon icon={faBars} />
          </a>
          <Logo className={styles.logo} />
          <Navigation className={styles.navigation} />
          <MusicPlayer className={styles.musicPlayer} />
          <ServerStatus className={styles.serverStatus} />
          <AccountControls className={styles.accountControls} />
          <IfUserIsLoggedOut>
            <LanguageSelector className={styles.languageSelector} />
          </IfUserIsLoggedOut>
        </div>
      </header>
    </>
  );
}
