import React, { useCallback, useState } from "react";
import FeatureTogglesContext from "./FeatureTogglesContext";
import IFeatureTogglesContext from "./IFeatureTogglesContext";
import initialState from "./initialState";
import EFeatureToggleId from "./EFeatureToggleId";

export interface IProps {
  children: React.ReactNode;
}

export default function FeatureTogglesProvider({ children }: IProps): React.ReactElement {
  const [featureToggles] = useState<Map<EFeatureToggleId, boolean>>(initialState.featureToggles);

  const isFeatureToggleEnabled = useCallback(
    (id: EFeatureToggleId): boolean => {
      return featureToggles.get(id) || false;
    },
    [featureToggles],
  );

  const context: IFeatureTogglesContext = {
    featureToggles,
    isFeatureToggleEnabled,
  };
  return <FeatureTogglesContext.Provider value={context}>{children}</FeatureTogglesContext.Provider>;
}
