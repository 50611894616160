import React from "react";
import { IFaqEntry } from "../../interfaces";

const entries: Array<IFaqEntry> = [];
export default entries;

entries.push({
  question: "재생할 수 있습니까?",
  answer: <>아니요. 그러나 #announcements의 향후 이벤트에 대해서는 계속 지켜봐 주시기 바랍니다.</>,
});
entries.push({
  question: "오프라인 / 독립적으로 재생할 수 있습니까?",
  answer: <>아니.</>,
});
entries.push({
  question: "왜 안돼?",
  answer: (
    <>
      이 단계는 기능이 거의없고 매우 불안정한 매우 불안정한 개발 서버입니다. 로그인. <br /> 기술이 아닌 사용자에게는
      환경 설정이 간단하지 않습니다.
    </>
  ),
});
entries.push({
  question: "언제 출시 되나요?",
  answer: (
    <>
      인내심, 그것은 취미 프로젝트입니다. <br />
      2018 년 12 월에 처음으로 공개 된 스 니크 피크는 2019 년에 더 많은 이벤트를 시청하십시오.
    </>
  ),
});
entries.push({
  question: "서버는 어디에서 다운로드 할 수 있습니까?",
  answer: <>당신은 지금 할 수 없습니다.</>,
});
entries.push({
  question: "클라이언트는 어디에서 다운로드 할 수 있습니까?",
  answer: (
    <>
      여기에는 원래 게임 클라이언트 모음이 있습니다. <br />
      다른 클라이언트 버전이 있으면 저에게 연락하십시오. http://bit.ly/2ti5bAC
    </>
  ),
});
entries.push({
  question: "내가 도움이 될 수 있습니다?",
  answer: (
    <>
      소프트웨어 개발 또는 리버스 엔지니어링에 경험이없고 기꺼이하지 않는 한 이 프로젝트에 시간을 투자해야합니다
      <br />비 기술적 인 도움이 필요한 경우 요청됩니다 #general_english 채널에서
    </>
  ),
});
entries.push({
  question: "어디서 기부 할 수 있습니까?",
  answer: (
    <>
      우리는 어떤 종류의 기부도받지 않습니다. <br /> 우리는 경기.
    </>
  ),
});
entries.push({
  question: "무엇이 필요합니까?",
  answer: (
    <>
      위 링크와 다른 게임 클라이언트 (특히 초기 한국어 버전 또는 최신 Xiones 클라이언트)가있는 경우 저에게 연락하십시오.{" "}
      <br /> 지금은 더 이상 필요한 것이 없습니다.
    </>
  ),
});
entries.push({
  question: "게임이 Windows 10 (64 비트)에서 실행됩니까?",
  answer: <>예.</>,
});
entries.push({
  question: "내 이름을 예약 할 수 있습니까",
  answer: <>아니.</>,
});
entries.push({
  question: "업데이트가 있습니까?",
  answer: (
    <>
      새로운 것이 있으면 @Moderator 또는 @Admin의 #announcements에 표시됩니다. <br />
      한편 지금까지 진행 한 내용에 대한 비디오 (계속 업데이트)를 시청하십시오. <br />
      <a href="http://bit.ly/2TJQnGo" target="_blank" rel="noopener noreferrer">
        http://bit.ly/2TJQnGo
      </a>
      <br />
      <a href="http://bit.ly/2URBYIe" target="_blank" rel="noopener noreferrer">
        http://bit.ly/2URBYIe
      </a>
      <br />
      또는 <a href="https://fhxrestoration.com/"> https://fhxrestoration.com/ </a>에서 홈페이지를 홈페이지를
      방문하십시오.
    </>
  ),
});
entries.push({
  question: "이 프로젝트는 무엇입니까",
  answer: (
    <>
      목표는 FHX (Ferentus, Herrcot, Xiones) 서버 에뮬레이터를 개발하여 원래 게임 서버처럼 작동하는 원래 게임 클라이언트
      <br />
      이를 위해 게임의 네트워크 프로토콜을 리버스 엔지니어링하고 새로운 커뮤니케이션을 구현합니다. 게임 레이어.
      <br />
      서버 측에는 구현해야 할 논리가 많이 있습니다 (NPC 컨트롤, 스폰, 텔레포트, 이동, 인벤토리, 아이템, 캐릭터, 로그인
      ..). <br /> 운 좋게도 게임 클라이언트 자체에 저장된 게임에 대한 많은 정보가 있습니다. <br /> 현재 V1은 V1에
      초점을두고 있습니다. V1은 이후 버전보다 간단한 네트워크 프로토콜이지만 다른 게임 버전처럼 보입니다. 서버 측에서
      매우 유사하게 작동하므로 대부분의 클라이언트 버전에서 대부분의 자료를 지원합니다. 순간.
    </>
  ),
});
entries.push({
  question: "현재 상태는 무엇입니까?",
  answer: <>여기, 불일치 및 진행 상황에 대한 정보를 수시로 업데이트합니다. 유튜브 채널.</>,
});
entries.push({
  question: "# git-feed 채널은 무엇입니까",
  answer: (
    <>
      Git은 개발자가 소스 코드 변경을 추적하기 위해 주로 사용하는 도구입니다. 각 변경 (커밋) 변경된 내용에 대한 설명을
      포함합니다. 이 채널에서 봇은 자동으로 커밋 메시지를 게시하여 업데이트 상태를 유지할 수 있습니다. 우리가
      대략하고있는 일. <br />
      게임이 원래처럼 작동하도록 게임을 재구성하고 균형을 맞추는 방법은 무엇입니까? 아이템, NPC 및 스킬에 관한 모든
      독창적 인 가치가 있습니다. <br /> 정확한 전투 계산은 여전히 열려 있으며 오리지널 담을 검증해야합니다. 데이터
      (스크린 샷, 비디오, 로그).
    </>
  ),
});
entries.push({
  question: "게임에 새로운 아이템, 스킬, 맵 및 NPC를 추가 할 수 있습니까",
  answer: <>현재 새 모델이나 애니메이션을 만들 수 없지만 기존 모델을 재사용하고 변경 통계.</>,
});
entries.push({
  question: "게임에 새로운 레이스 / 클래스를 추가 할 수 있습니까?",
  answer: (
    <>
      아닙니다. 아마도 부분적으로 구현 된 종족 (인간, 난쟁이 등)을 이용할 수는 있지만 모델과 애니메이션이 없습니다. 또한
      게임 플레이를 완전히 바꿀 것입니다.
    </>
  ),
});
entries.push({
  question: "새로운 해상도 / 종횡비를 지원합니까?",
  answer: <>예.</>,
});
entries.push({
  question: "XYZ의 비디오를 게시 할 수 있습니까?",
  answer: <>우리에게 메시지를 보내십시오. ;-)</>,
});
entries.push({
  question: "어떻게 시작 했습니까?",
  answer: (
    <>
      이 Discord 채널을 설립하고 올해 7 월에 협력을 시작했습니다. @Wildriff 네트워크 프로토콜을 리버스 엔지니어링하는
      그의 임무에서 매우 엄격했습니다. 그러나 그것은하지 않았다 @AcarX (이 게임을하지 않은 외부 사람)가 우리에게 도움이
      될 때까지 이륙하십시오. 네트워크 프로토콜의 기본 사항을 이해하고 개념 증명을 제공했습니다. 리버스 엔지니어링
      기술을 덜 사용하여 구현할 수 있습니다. 몇몇의 다른 몇 년 동안 주로 독립적으로 다른 부분에서 독립적으로 작업을 시작
      게임 (@Robb (rbb138))은 2014 년에지도 형식의 일부를 알아 낸 @ b4ttl3m4st3r (Tobi) 게임의 텍스트 버전에서 일하고
      게임에 대한 많은 정보를 추출했습니다. @Marcel (Cynicer)은 2012 년부터 .tbl (게임 데이터 파일)
      작업을하고있었습니다. <br />
      지난 몇 개월 동안 대부분의 진전이 정직합니다.
    </>
  ),
});
