import { BorderedBox } from "@fhx/ui";
import classNames from "classnames";
import React from "react";
import styles from "./DefaultTable.module.scss";

interface IProps {
  children: Array<React.ReactElement> | React.ReactElement;
  className?: string;
  style?: React.CSSProperties;
}

export default function DefaultTable({ children, className, style }: IProps): React.ReactElement {
  return (
    <BorderedBox type="TOOLTIP_3" className={classNames(styles.tableWrapper, className)} style={style}>
      <table>{children}</table>
    </BorderedBox>
  );
}
