/* eslint-disable react/jsx-curly-brace-presence */
import React, { useCallback, useContext, useMemo, useState } from "react";
import { useIntl } from "react-intl";
import { BorderedBox, Button, Checkbox, EButtonType } from "@fhx/ui";
import ReCAPTCHA from "react-google-recaptcha";
import classNames from "classnames";
import { validate as validateEmailAddress } from "email-validator";
import i18n from "./DeprecatedRegisterWindow.i18n";
import styles from "./DeprecatedRegisterWindow.module.scss";
import { getLocale } from "../../../../i18n";
import IRegistrationData from "./IRegistrationData";
import RegistrationInput from "./components/RegistrationInput/RegistrationInput";
import { validatePassword, validateUsername } from "../../../../helpers";
import { LOGIN_SERVER_RECAPTCHA_KEY } from "../../../../config";
import { PRIVACY_POLICY } from "../../../../routes/paths";
import {
  FeatureTogglesContext,
  EFeatureToggleId,
  IfFeatureToggleIsDisabled,
  IfFeatureToggleIsEnabled,
} from "../../../../contexts";
import DefaultWindow from "../../DefaultWindow/DefaultWindow";

export interface IProps {
  onSubmit?: (registrationData: IRegistrationData) => void;
}

export default function DeprecatedRegisterWindow({ onSubmit }: IProps): React.ReactElement {
  const { formatMessage } = useIntl();
  const [username, setUsername] = useState<string>("");
  const [emailAddress, setEmailAddress] = useState<string>("");
  const [emailAddressConfirm, setEmailAddressConfirm] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const [passwordConfirm, setPasswordConfirm] = useState<string>("");
  const [reCaptchaToken, setReCaptchaToken] = useState<string>("");
  const { isFeatureToggleEnabled } = useContext(FeatureTogglesContext);
  const [privacyPolicyAccepted, setPrivacyPolicyAccepted] = useState<boolean>(false);
  const [attemptAmount, setAttemptAmount] = useState<number>(0);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const googleRecaptchaV3Policy = useMemo(() => {
    <div>
      This site is protected by reCAPTCHA and the Google{" "}
      <a href="https://policies.google.com/privacy" target="_blank" rel="noopener noreferrer">
        Privacy Policy
      </a>{" "}
      and{" "}
      <a href="https://policies.google.com/terms" target="_blank" rel="noopener noreferrer">
        Terms of Service
      </a>{" "}
      apply.
    </div>;
  }, []);

  // eslint-disable-next-line complexity
  const validateInput = useCallback((): boolean => {
    if (username === "") return false;
    if (emailAddress === "") return false;
    if (emailAddressConfirm === "") return false;
    if (emailAddress !== emailAddressConfirm) return false;
    if (password === "") return false;
    if (passwordConfirm === "") return false;
    if (password !== passwordConfirm) return false;
    if (reCaptchaToken === "") return false;
    if (!privacyPolicyAccepted) return false;
    if (!validateUsername(username)) return false;
    if (!validateEmailAddress(emailAddress)) return false;
    return true;
  }, [emailAddress, emailAddressConfirm, password, passwordConfirm, privacyPolicyAccepted, reCaptchaToken, username]);

  const handleSubmitForm = useCallback((): void => {
    if (isFeatureToggleEnabled(EFeatureToggleId.ACCOUNT_REGISTRATION) && validateInput()) {
      if (onSubmit) onSubmit({ username, emailAddress, password, reCaptchaToken });
      setReCaptchaToken("");
      setAttemptAmount(attemptAmount + 1);
    }
  }, [
    attemptAmount,
    emailAddress,
    isFeatureToggleEnabled,
    onSubmit,
    password,
    reCaptchaToken,
    username,
    validateInput,
  ]);

  const renderInfoBox = useCallback((): React.ReactNode => {
    return (
      <BorderedBox type="MAIN_2" className={styles.instructions}>
        <h3>{formatMessage(i18n.title)}</h3>
        <ul>
          <li>{formatMessage(i18n.rule1)}</li>
          <li>{formatMessage(i18n.rule2)}</li>
          <li>
            {formatMessage(i18n.rule3)} <br />
            <strong>({formatMessage(i18n.rule3Info)})</strong>
          </li>
          <li>{formatMessage(i18n.rule4)}</li>

          <li>{formatMessage(i18n.rule5)}</li>
          <li>{formatMessage(i18n.rule6)}</li>
        </ul>
      </BorderedBox>
    );
  }, [formatMessage]);

  return (
    <DefaultWindow className={styles.window}>
      <form className={styles.form}>
        <div className={styles.header}>Registration Form</div>
        {renderInfoBox()}
        <>
          <div className={classNames(styles.row, styles.username)}>
            <RegistrationInput
              backgroundType="username"
              type="text"
              value={username}
              placeholder={formatMessage(i18n["username-ph"])}
              onChange={(event): void => {
                setUsername(event.target.value);
              }}
              className={styles.input}
              isInvalid={username !== "" && !validateUsername(username)}
            />
            <BorderedBox
              type="MAIN_2"
              isGapless
              className={styles.reCaptchaBox}
              contentClassName={styles.reCaptchaBoxContent}
            >
              <ReCAPTCHA
                key={attemptAmount}
                sitekey={LOGIN_SERVER_RECAPTCHA_KEY}
                onChange={(token): void => {
                  if (token) setReCaptchaToken(token);
                }}
                theme="dark"
                hl={getLocale()}
              />
            </BorderedBox>
          </div>
          <div className={classNames(styles.row, styles.emailAddress)}>
            <RegistrationInput
              backgroundType="email"
              type="text"
              value={emailAddress}
              placeholder={formatMessage(i18n["email-ph"])}
              onChange={(event): void => {
                setEmailAddress(event.target.value);
              }}
              className={styles.input}
              isInvalid={emailAddress !== "" && !validateEmailAddress(emailAddress)}
            />
            <RegistrationInput
              backgroundType="confirm"
              type="text"
              value={emailAddressConfirm}
              placeholder={formatMessage(i18n["emailConfirm-ph"])}
              onChange={(event): void => {
                setEmailAddressConfirm(event.target.value);
              }}
              className={styles.input}
              isInvalid={emailAddress !== "" && emailAddressConfirm !== emailAddress}
            />
          </div>
          <div className={classNames(styles.row, styles.password)}>
            <RegistrationInput
              backgroundType="password"
              type="password"
              value={password}
              placeholder={formatMessage(i18n["password-ph"])}
              onChange={(event): void => {
                setPassword(event.target.value);
              }}
              className={styles.input}
              isInvalid={password !== "" && !validatePassword(password)}
            />
            <RegistrationInput
              backgroundType="confirm"
              type="password"
              value={passwordConfirm}
              placeholder={formatMessage(i18n["passwordConfirm-ph"])}
              onChange={(event): void => {
                setPasswordConfirm(event.target.value);
              }}
              className={styles.input}
              isInvalid={password !== "" && passwordConfirm !== password}
            />
          </div>
          <div className={classNames(styles.row, styles.captchaPolicy)}>
            <div>
              <div className={styles.privacyPolicy}>
                <div className={styles.checkBox}>
                  <Checkbox
                    id="privacyPolicy"
                    type="OPTION_BTN"
                    checked={privacyPolicyAccepted}
                    onChange={(event): void => {
                      setPrivacyPolicyAccepted(event.target.checked);
                    }}
                  />{" "}
                  <label htmlFor="privacyPolicy">By registering, I accept the current</label>
                </div>{" "}
                <a href={PRIVACY_POLICY} target="_blank" rel="noopener noreferrer">
                  privacy policy
                </a>
              </div>
            </div>
          </div>
          <div className={classNames(styles.row, styles.buttons)}>
            <IfFeatureToggleIsEnabled id={EFeatureToggleId.ACCOUNT_REGISTRATION}>
              <Button
                type={EButtonType.GUILD_BASE1_345X39}
                onClick={handleSubmitForm}
                className={styles.submitButton}
                isDisabled={!validateInput()}
              >
                {formatMessage(i18n.createAccount)}
              </Button>
            </IfFeatureToggleIsEnabled>
            <IfFeatureToggleIsDisabled id={EFeatureToggleId.ACCOUNT_REGISTRATION}>
              {formatMessage(i18n.registrationIsDisabled)}
            </IfFeatureToggleIsDisabled>
          </div>
        </>
      </form>
    </DefaultWindow>
  );
}
