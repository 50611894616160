import React from "react";
import { IntlProvider as ReactIntlProvider, MessageFormatElement } from "react-intl";
import { getLocale, getDefaultLocale } from "../storage";

export interface IProps {
  children: React.ReactNode;
  messages?: Record<string, string> | Record<string, MessageFormatElement[]>;
  locale?: string;
  defaultLocale?: string;
}

export default function IntlProvider({ messages, children, locale, defaultLocale }: IProps): React.ReactElement {
  return (
    <ReactIntlProvider
      locale={locale || getLocale()}
      defaultLocale={defaultLocale || getDefaultLocale()}
      messages={messages}
    >
      {children}
    </ReactIntlProvider>
  );
}
