import { defineMessages } from "react-intl";

export default defineMessages({
  faq: {
    id: "window.faq.faq",
    defaultMessage: "FAQ",
    description: "",
  },
  search: {
    id: "window.faq.search",
    defaultMessage: "Search",
    description: "",
  },
  noResults: {
    id: "window.faq.noResults",
    defaultMessage: "No results found",
    description: "",
  },
});
