import React from "react";
import { useIntl } from "react-intl";
import i18n from "./useSocialLinks.i18n";
import { ISocialLink } from "../interfaces";
import styles from "./useSocialLinks.module.scss";
import { DISCORD_URL, FACEBOOK_URL, GITLAB_URL, TWITCH_URL, TWITTER_URL, YOUTUBE_URL } from "../config";
import { ReactComponent as DiscordIcon } from "./img/social/discord.svg";
import { ReactComponent as FacebookIcon } from "./img/social/facebook.svg";
import { ReactComponent as TwitterIcon } from "./img/social/twitter.svg";
import { ReactComponent as YoutubeIcon } from "./img/social/youtube.svg";
import { ReactComponent as TwitchIcon } from "./img/social/twitch.svg";
import { ReactComponent as GitlabIcon } from "./img/social/gitlab.svg";

export default function useSocialLinks(): [Array<ISocialLink>] {
  const { formatMessage } = useIntl();

  const links: Array<ISocialLink> = [
    {
      id: "discord",
      target: "_blank",
      route: DISCORD_URL,
      isEnabled: true,
      label: <DiscordIcon title={formatMessage(i18n.discord)} className={styles.discord} />,
    },
    {
      id: "facebook",
      target: "_blank",
      route: FACEBOOK_URL,
      isEnabled: true,
      label: <FacebookIcon title={formatMessage(i18n.facebook)} className={styles.facebook} />,
    },
    {
      id: "twitter",
      target: "_blank",
      route: TWITTER_URL,
      isEnabled: false, // TODO
      label: <TwitterIcon title={formatMessage(i18n.twitter)} className={styles.twitter} />,
    },
    {
      id: "youtube",
      target: "_blank",
      route: YOUTUBE_URL,
      isEnabled: true,
      label: <YoutubeIcon title={formatMessage(i18n.youtube)} className={styles.youtube} />,
    },
    {
      id: "twitch",
      target: "_blank",
      route: TWITCH_URL,
      isEnabled: true,
      label: <TwitchIcon title={formatMessage(i18n.twitch)} className={styles.twitch} />,
    },
    {
      id: "gitlab",
      target: "_blank",
      route: GITLAB_URL,
      isEnabled: true,
      label: <GitlabIcon title={formatMessage(i18n.gitlab)} className={styles.gitlab} />,
    },
  ];

  return [links];
}
