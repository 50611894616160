import React, { useCallback, useContext } from "react";
import { PromptWindow } from "@fhx/ui";
import { useIntl } from "react-intl";
import { UserContext } from "../../../../contexts";
import i18n from "./LogOutWindow.i18n";

export interface IProps {
  onClose?: () => void;
}

export default function LogOutWindow({ onClose }: IProps): React.ReactElement {
  const { formatMessage } = useIntl();
  const { logout } = useContext(UserContext);

  const onCancel = useCallback(() => {
    if (onClose) onClose();
  }, [onClose]);

  return (
    <PromptWindow
      onSubmit={(): void => {
        logout().finally(() => onClose && onClose());
      }}
      onCancel={onCancel}
    >
      {formatMessage(i18n.areYouSure)}
    </PromptWindow>
  );
}
