import { defineMessages } from "react-intl";

export default defineMessages({
  "countdown-days": {
    id: "page.countdown.days",
    defaultMessage: "Days",
    description: "",
  },
  "countdown-hours": {
    id: "page.countdown.hours",
    defaultMessage: "Hours",
    description: "",
  },
  "countdown-minutes": {
    id: "page.countdown.minutes",
    defaultMessage: "Minutes",
    description: "",
  },
  "countdown-seconds": {
    id: "page.countdown.seconds",
    defaultMessage: "Seconds",
    description: "",
  },
});
