import React, { useCallback, useContext, useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { Button, EButtonType, Spinner } from "@fhx/ui";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimesCircle } from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";
import classNames from "classnames";
import styles from "./LoginWindow.module.scss";
import i18n from "./LoginWindow.i18n";
import { validateUsername } from "../../../../helpers";
import { Input } from "../../../common";
import { FORGOT_PASSWORD, RESEND_VERIFICATION_MAIL } from "../../../../routes/paths";
import { UserContext, IfFeatureToggleIsDisabled, EFeatureToggleId, FeatureTogglesContext } from "../../../../contexts";
import DefaultWindow from "../../DefaultWindow/DefaultWindow";
import { ALLOWED_USERNAMES_FOR_LOGIN } from "../../../../config";

type IStatus = "IDLE" | "LOADING" | "SUCCESS" | "FAILED";

export interface IProps {
  onClose?: () => void;
  onStatusChanged?: (status: IStatus) => void;
  className?: string;
  style?: React.CSSProperties;
}

export default function LoginWindow({ onClose, onStatusChanged, className, style }: IProps): React.ReactElement {
  const { formatMessage } = useIntl();
  const { login: contextLogin } = useContext(UserContext);
  const { isFeatureToggleEnabled } = useContext(FeatureTogglesContext);
  const [username, setUsername] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const [status, setStatus] = useState<IStatus>("IDLE");
  const [message, setMessage] = useState<string>();
  const isLogInFeatureEnabled = isFeatureToggleEnabled(EFeatureToggleId.ACCOUNT_LOGIN);

  const login = useCallback((): void => {
    const isInputSatisfying = username !== "" && password !== "";
    const isLoginAllowed = isLogInFeatureEnabled || ALLOWED_USERNAMES_FOR_LOGIN.includes(username);
    if (!isLoginAllowed || !isInputSatisfying) return;
    setStatus("LOADING");
    setMessage(undefined);
    contextLogin(username, password)
      .then((result) => {
        if (result === "OK") {
          setStatus("SUCCESS");
        } else {
          setStatus("FAILED");
        }
        switch (result) {
          case "OK":
            setMessage(formatMessage(i18n.successfullyLoggedIn));
            break;
          case "INVALID_CREDENTIALS":
            setMessage(formatMessage(i18n.invalidUsernameOrPassword));
            break;
          case "INVALID_CAPTCHA":
            setMessage(formatMessage(i18n.invalidCaptcha));
            break;
          case "EMAIL_NOT_VERIFIED":
            setMessage(formatMessage(i18n.emailNotVerified));
            break;
          case "BANNED":
            setMessage(formatMessage(i18n.accountIsBanned));
            break;
          default:
            setMessage(formatMessage(i18n.unknownReason));
        }
      })
      .catch((error) => {
        setStatus("FAILED");
        setMessage(formatMessage(i18n.timeout));
        console.error(error);
      })
      .finally(() => {
        setPassword("");
      });
  }, [isLogInFeatureEnabled, username, password, contextLogin, formatMessage]);

  useEffect(() => {
    if (onStatusChanged) onStatusChanged(status);
    if (onClose && status === "SUCCESS") onClose();
  }, [onClose, onStatusChanged, status]);

  return (
    <DefaultWindow
      header={formatMessage(i18n.login)}
      footer={
        <>
          <div>
            <Link to={`${RESEND_VERIFICATION_MAIL}`} onClick={(): void => onClose && onClose()}>
              {formatMessage(i18n.resendVerificationMail)}
            </Link>
            <br />
            <Link to={`${FORGOT_PASSWORD}`} onClick={(): void => onClose && onClose()}>
              {formatMessage(i18n.forgotUsernameOrPassword)}
            </Link>
          </div>
          <Button type={EButtonType.CN} className={styles.submitButton} onClick={login}>
            {formatMessage(i18n.login)}
          </Button>
        </>
      }
      className={classNames(styles.window, className)}
      style={style}
    >
      {status === "LOADING" && <Spinner className={styles.spinner} />}
      {status !== "LOADING" && (
        <>
          <Input
            type="text"
            tint={username !== "" && !validateUsername(username) ? "error" : undefined}
            label={formatMessage(i18n.username)}
            placeholder={`${formatMessage(i18n.username)} ...`}
            value={username}
            onChange={(event): void => {
              setUsername(event.target.value);
            }}
            onKeyDown={(event): void => {
              if (event.key === "Enter") login();
            }}
            className={styles.input}
            wrapperClassName={styles.inputWrapper}
            autoFocus
          />
          <Input
            type="password"
            label={formatMessage(i18n.password)}
            placeholder={`${formatMessage(i18n.password)} ...`}
            value={password}
            onChange={(event): void => {
              setPassword(event.target.value);
            }}
            onKeyDown={(event): void => {
              if (event.key === "Enter") login();
            }}
            className={styles.input}
            wrapperClassName={styles.inputWrapper}
          />
          <IfFeatureToggleIsDisabled id={EFeatureToggleId.ACCOUNT_LOGIN}>
            <div className={classNames(styles.status, styles.error)}>
              <FontAwesomeIcon icon={faTimesCircle} className={styles.statusIcon} />
              {formatMessage(i18n.loginIsCurrentlyDisabled)}
            </div>
          </IfFeatureToggleIsDisabled>
          {status === "FAILED" && message && (
            <div className={classNames(styles.status, styles.error)}>
              <FontAwesomeIcon icon={faTimesCircle} className={styles.statusIcon} />
              {message}
            </div>
          )}
          {status === "SUCCESS" && (
            <div className={classNames(styles.status, styles.ok)}>
              <FontAwesomeIcon icon={faTimesCircle} className={styles.statusIcon} />
              {message}
            </div>
          )}
        </>
      )}
    </DefaultWindow>
  );
}
