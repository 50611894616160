import { gql } from "@apollo/client";
import { IAnnouncement, IAnnouncementI18n } from "@fhx/api-interfaces";

export const QUERY = gql`
  query getAnnouncement($id: Float) {
    announcements(filter: { idIs: $id }) {
      id
      title
      text
      createdAt
      i18n {
        id
        title
        text
      }
    }
  }
`;

interface IPickedAnnouncement extends Pick<IAnnouncement, "id" | "title" | "text" | "createdAt"> {
  i18n: Array<Pick<IAnnouncementI18n, "id" | "title" | "text">>;
}

export interface IQueryVariables {
  id: string;
}

export interface IQueryResultData {
  announcements: Array<IPickedAnnouncement>;
}
