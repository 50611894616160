import { IMainAttributeMultipliers } from "@fhx/interfaces";

const defaultAttributMultipliers: IMainAttributeMultipliers = {
  vitalityHitpoints: 0,
  vitalityStamina: 0,
  intelligenceMana: 0,
  strenghtPhyAttack: 0,
  dexterityPhyAttack: 0,
  intelligenceMagAttack: 0,
  strenghtPhyDef: 0,
  intelligenceMagDef: 0,
  dexterityAccuracy: 0,
  dexterityEvasionMelee: 0,
  dexterityEvasionMagic: 0,
  strenghtResistMelee: 0,
  strenghtResistAttack: 0,
  strenghtResistRanged: 0,
  intelligenceResistCurse: 0,
  dexterityMovementSpeed: 0,
};

export default defaultAttributMultipliers;
