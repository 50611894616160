/* eslint-disable react/jsx-curly-brace-presence */
import React from "react";
import { IWebsiteCharacterClass } from "../../interfaces";
import { ECharacterStrengthMeter } from "../../enums";
import defaultAttributMultipliers from "./defaultAttributMultipliers";
import { ASSETS_WEBSITE_CLASSES } from "../../assets";

const GALLERY_PATH = `${ASSETS_WEBSITE_CLASSES}/wizard/screenshots`;

const wizard: IWebsiteCharacterClass = {
  id: "wizard",
  isEnabled: true,
  i18nTag: {
    en: "Wi",
    de: "Ma",
  },
  i18nName: {
    en: "Wizard",
    de: "Magier",
  },
  roles: ["Ranged damage", "Enemy control"],
  i18nDescription: {
    en: "Wizards specialize in time management and casting spells. They are able to cast virtually all known elemental spells. Each of these spells can produce destructive forces, so they act as the most powerful damage dealers in a party.",
    /*
    Wizards are masters of time and sorcery. They are proficient in nearly every elemental spell and are capable of unleashing enormous destructiveness.
    Their main status value is INT. A higher level of intelligence enables them to cast devastating spell combinations.
    They can only wear light armour and thus need little strength. VIT must not be disregarded but isn't of high importance because wizards attack from a long range.
    DEX is only needed in the beginning to move around quickly.
    */
    de: "Magier sind auf das Ausüben von Magie und Beeinflussen der Zeit spezialisiert. Sie können annähernd alle bekannten, grundlegenden Zauber anwenden. Jeder dieser Magier kann zerstörerische Kräfte heraufbeschwören, aber erst in einer Gruppe von Magiern wird ihre Kraft maßlos.",
  },
  strengthMeters: [
    { id: ECharacterStrengthMeter.ATTACK, value: 100 },
    { id: ECharacterStrengthMeter.DEFENSE, value: 10 },
    { id: ECharacterStrengthMeter.PARTY, value: 60 },
    { id: ECharacterStrengthMeter.SOLO, value: 80 },
  ],
  difficulty: "EASY",
  offensive: "HIGHT",
  defense: "LOW",
  i18nPreferredAttributes: {
    en: (
      <>
        <ul>
          <li>INT (for more damage and bigger mana pool)</li>
          <li>STR (in order to meet requirements for weapon)</li>
        </ul>
      </>
    ),
  },
  i18nFurtherDescription: {
    en: (
      <>
        Wizards have an easy life when it comes to soloing as long as they select their targets wisely. If they{" "}
        {"don't"} get hit at all by their enemy (where slowing skills may help) they {"don't"} start to totter because
        enemies are dying in no time due to the high damage output. This is especially true for melee enemies. Ranged
        weapons may easily harm the wizard and can (like most other melee enemies) heavily harm him/her when reached.
        Using the ”Memorize” / ”Teleoprt” ability, the wizard can quickly travel from grinding spots back to town and
        vise versa in just a minute.
      </>
    ),
  },
  i18nRolesDescription: {
    en: (
      <>
        <ul>
          <li>
            <b>Damage Role:</b> The wizard is by far the class with the most damage output in the game and is therefor
            destined to take over the damage part of a raid or party in most situations.
          </li>
          <li>
            <b>Supporting Role:</b> Using {"it's"} slowing skills, a wizard is capable of doing a support job which
            would relieve the tank or raid member from taking damage from slowed targets that can not follow up. This,
            however, only works if the enemy is weak enough to not evade the slowing effects and preferably has no
            ranged attacks. The skill ”Invisible” can also help to make an ambush succsessfull when used clever.
          </li>
        </ul>
      </>
    ),
  },
  preferredWeapons: ["staff", "wand", "shield"],
  preferredArmor: ["robe", "criclet", "glovesMagic", "bootsMagic", "cape"],
  preferredAccessoires: ["ring", "necklace"],
  gallerEntries: [
    "3lol.JPG",
    "Herrcot73 (3).jpg",
    "Herrcot128 (2).jpg",
    "Herrcot193 (2).jpg",
    "Herrcot334.jpg",
    "ultimate mana nuke.jpg",
    "xDDDDDD.jpg",
    "Xiones1 (3).jpg",
    "Xiones2 (2).jpg",
    "Xiones5 (3).jpg",
    "xiones6_original.jpg",
    "Xiones9 (3).jpg",
    "Xiones9 (4).jpg",
    "Xiones16 (4).jpg",
    "Xiones26 (2).jpg",
    "Xiones29 (2).jpg",
    "Xiones32 (5).jpg",
  ].map((entry) => `${GALLERY_PATH}/${entry}`),
  classSkillModels: [],
  classSkillModelsV2: [],
  classSkillComboModels: [],
  classSkillComboModelsV2: [],
  attributeMultipliers: defaultAttributMultipliers,
};

export default wizard;
