import { defineMessages } from "react-intl";

export default defineMessages({
  collapse: {
    id: "window.announcements.entry.collapse",
    defaultMessage: "collapse",
    description: "",
  },
  expand: {
    id: "window.announcements.entry.expand",
    defaultMessage: "expand",
    description: "",
  },
  showMore: {
    id: "window.announcements.entry.showMore",
    defaultMessage: "Show more",
    description: "",
  },
  bookmark: {
    id: "window.announcements.entry.bookmark",
    defaultMessage: "Bookmark",
    description: "",
  },
});
