import React, { useCallback } from "react";
import { useIntl } from "react-intl";
import Select from "react-select";
import classNames from "classnames";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faGlobeAmericas } from "@fortawesome/free-solid-svg-icons";
import styles from "./LanguageSelector.module.scss";
import i18n from "./LanguageSelector.i18n";
import { setDesiredLocale } from "../../../../../i18n";
import { useSupportedLanguages } from "../../../../../hooks";
import { ILanguage, ISelectableOption } from "../../../../../interfaces";

function getOptionForLanguage(language: ILanguage): ISelectableOption {
  return {
    value: language.locale,
    label: (
      <>
        <img src={language.iconSrc} alt="" /> {language.label}
      </>
    ),
  };
}

export interface IProps {
  className?: string;
  style?: React.CSSProperties;
}

export default function LanguageSelector({ className, style }: IProps): React.ReactElement {
  const { formatMessage, locale } = useIntl();
  const [languages] = useSupportedLanguages();

  const handleChangeLanguage = useCallback((loc: string): void => {
    setDesiredLocale(loc);
    window.location.reload();
  }, []);

  const options = languages.map(getOptionForLanguage);

  return (
    <div className={classNames(styles.languageSelector, className)} style={style}>
      <label htmlFor="language">
        <FontAwesomeIcon icon={faGlobeAmericas} title={formatMessage(i18n.languageLabel)} />
      </label>
      <Select
        id="language"
        options={options}
        value={options.find((option) => option.value === locale)}
        onChange={(selectedOption): void => {
          handleChangeLanguage((selectedOption as ISelectableOption).value);
        }}
        classNamePrefix="Select"
        className={classNames(styles.select)}
      />
    </div>
  );
}
