import { IWebsiteCharacterClass } from "../../interfaces";
import { ECharacterStrengthMeter } from "../../enums";
import defaultAttributMultipliers from "./defaultAttributMultipliers";

const thief: IWebsiteCharacterClass = {
  id: "thief",
  isEnabled: false,
  i18nTag: {
    en: "Tf",
    de: "Di",
  },
  i18nName: {
    en: "Thief",
    de: "Dieb",
  },
  roles: [],
  i18nDescription: {
    en: "Rumor! This class has been teased by older game versions but has never been published (yet)...",
    de: "",
  },
  strengthMeters: [
    { id: ECharacterStrengthMeter.ATTACK, value: 0 },
    { id: ECharacterStrengthMeter.DEFENSE, value: 0 },
    { id: ECharacterStrengthMeter.PARTY, value: 0 },
    { id: ECharacterStrengthMeter.SOLO, value: 0 },
  ],
  difficulty: undefined,
  offensive: undefined,
  defense: undefined,
  classSkillModels: [],
  classSkillModelsV2: [],
  classSkillComboModels: [],
  classSkillComboModelsV2: [],
  attributeMultipliers: defaultAttributMultipliers,
};

export default thief;
