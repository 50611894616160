import React, { useCallback, useState } from "react";
import classNames from "classnames";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMinusSquare, faPlusSquare } from "@fortawesome/free-solid-svg-icons";
import { SlideDown } from "react-slidedown";
import styles from "./FaqEntry.module.scss";
import "react-slidedown/lib/slidedown.css";

export interface IProps {
  question: React.ReactNode;
  answer: React.ReactNode;
  className?: string;
  style?: React.CSSProperties;
}

export default function FaqEntry({ question, answer, className = "", style }: IProps): React.ReactElement {
  const [isExpanded, setIsExpanded] = useState<boolean>(false);

  const toggleExpanded = useCallback((): void => {
    setIsExpanded(!isExpanded);
  }, [isExpanded]);

  return (
    <div className={classNames(styles.entry, className)} style={style}>
      <div className={styles.question} onClick={toggleExpanded}>
        <div className={styles.collapseExpandButton}>
          {isExpanded && <FontAwesomeIcon icon={faMinusSquare} />}
          {!isExpanded && <FontAwesomeIcon icon={faPlusSquare} />}
        </div>
        <div className={styles.questionContent}>{question}</div>
      </div>
      <SlideDown>{isExpanded && <div className={styles.answer}>{answer}</div>}</SlideDown>
    </div>
  );
}
