import React, { useContext } from "react";
import EFeatureToggleId from "../EFeatureToggleId";
import FeatureTogglesContext from "../FeatureTogglesContext";

export interface IProps {
  id: EFeatureToggleId;
  children: React.ReactNode;
}

export default function IfFeatureToggleIsEnabled({ children, id }: IProps): React.ReactElement {
  const { isFeatureToggleEnabled } = useContext(FeatureTogglesContext);
  return <>{isFeatureToggleEnabled(id) && children}</>;
}
