import React, { useCallback } from "react";
import { BrowserRouter } from "react-router-dom";
import { ApolloProvider } from "@apollo/client";
import { DefaultThemeWrapper, MouseTooltip, MouseTooltipProvider, IntlProvider as IntlProviderUi } from "@fhx/ui";
import styles from "./App.module.scss";
import { DefaultView, MaintenanceView } from "../views";
import { getLocale, IntlProvider } from "../../i18n";
import ScrollToTop from "./components/ScrollToTop/ScrollToTop";
import { getApolloClient, getApolloClientLink } from "../../helpers";
import { NotificationsProvider, UserProvider, FeatureTogglesProvider, ModalsProvider } from "../../contexts";
import { APP_IS_UNDER_MAINTENANCE } from "../../config";
import getMessagesForCurrentLocale from "../../i18n/getMessagesForCurrentLocale";

export default function App(): React.ReactElement {
  const apolloClient = getApolloClient();

  const onAuthTokenChanged = useCallback(
    (authToken: string | undefined) => {
      apolloClient.setLink(getApolloClientLink(authToken));
      apolloClient.resetStore().catch(console.error);
    },
    [apolloClient],
  );

  return (
    <DefaultThemeWrapper>
      <div className={styles.app}>
        <IntlProvider messages={getMessagesForCurrentLocale()}>
          <IntlProviderUi locale={getLocale()}>
            <ModalsProvider>
              <NotificationsProvider>
                <MouseTooltipProvider>
                  <MouseTooltip />
                  <UserProvider onAuthTokenChanged={onAuthTokenChanged}>
                    <ApolloProvider client={apolloClient}>
                      <BrowserRouter>
                        <FeatureTogglesProvider>
                          <ScrollToTop />
                          {APP_IS_UNDER_MAINTENANCE ? <MaintenanceView /> : <DefaultView />}
                        </FeatureTogglesProvider>
                      </BrowserRouter>
                    </ApolloProvider>
                  </UserProvider>
                </MouseTooltipProvider>
              </NotificationsProvider>
            </ModalsProvider>
          </IntlProviderUi>
        </IntlProvider>
      </div>
    </DefaultThemeWrapper>
  );
}
