import React, { useState, useEffect } from "react";
import { useIntl } from "react-intl";
import classNames from "classnames";
import { getUtcTime } from "../../../../../helpers";
import styles from "./ServerTime.module.scss";
import i18n from "./ServerTime.i18n";

export interface IProps {
  className?: string;
  style?: React.CSSProperties;
}

export default function ServerTime({ className, style }: IProps): React.ReactElement {
  const { formatMessage, locale } = useIntl();
  const [currentServerDate, setCurrentServerDate] = useState<Date>(getUtcTime());

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentServerDate(getUtcTime());
    }, 1000);
    return (): void => clearInterval(interval);
  }, [setCurrentServerDate]);

  return (
    <div className={classNames(className, styles.serverTime)} style={style}>
      {formatMessage(i18n.serverTime)} {currentServerDate.toLocaleString(locale)} (UTC)
    </div>
  );
}
