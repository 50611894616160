import { sendGetRequest } from "./helpers";
import { ILoginServerResponse, IUser } from "./interfaces";

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface IRequestBody {}

export type IResponseBody = ILoginServerResponse<Array<IUser>>;

export default function users(token: string): Promise<IResponseBody | undefined> {
  return sendGetRequest<IRequestBody, IResponseBody>(
    "/users",
    {},
    { withCredentials: true, headers: { Authorization: `Bearer ${token}` } },
  );
}
