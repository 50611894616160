import React from "react";
import { ErrorMessageWindow } from "../../../../windows";
import styles from "./ErrorBoundary.module.scss";

type IErrorInfo = {
  componentStack: string;
};

interface IState {
  hasError: boolean;
  error: Error | undefined;
  errorInfo: IErrorInfo | undefined;
}

export interface IProps {
  children: React.ReactNode;
}

export default class ErrorBoundary extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);
    this.state = { hasError: false, error: undefined, errorInfo: undefined };
  }

  componentDidCatch(error: Error, errorInfo: IErrorInfo): void {
    this.setState({ hasError: true, error, errorInfo });
  }

  render(): React.ReactNode {
    const { children } = this.props;
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { hasError, error, errorInfo } = this.state;
    if (hasError) {
      return <ErrorMessageWindow className={styles.errorMessageWindow} />;
    }
    return children;
  }
}
