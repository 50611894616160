import React, { useCallback, useContext } from "react";
import classNames from "classnames";
import { NotificationsContext } from "../../../contexts";
import styles from "./NotificationsArea.module.scss";
import Notification from "./components/Notification/Notification";

export interface IProps {
  className?: string;
  style?: React.CSSProperties;
}

export default function NotificationsArea({ className, style }: IProps): React.ReactElement {
  const { removeByIndex, notifications } = useContext(NotificationsContext);

  const removeNotificationByIndex = useCallback(
    (index: number): void => {
      removeByIndex(index);
    },
    [removeByIndex],
  );

  return (
    <div className={classNames(styles.area, className)} style={style}>
      {notifications.map((entry, index) => (
        <Notification
          key={entry.id || index}
          meta={entry}
          className={styles.notification}
          onClose={(): void => removeNotificationByIndex(index)}
        />
      ))}
    </div>
  );
}
