import { IWebsiteCharacterRace } from "../../interfaces";
import { cleric, necromancer, wizard } from "../characterClasses";

const characterRace: IWebsiteCharacterRace = {
  id: "gnome",
  isEnabled: false,
  i18nTag: {
    en: "Gn",
    de: "Gn",
  },
  i18nName: {
    en: "Gnome",
    de: "Gnom",
  },
  i18nDescription: {
    en: "They are small in size and cute looks, but their high mind their magic cannot be compare to others. They look like a Dwarf, but in spirit they are charming and little bit of shy in personality.",
    de: "",
  },
  mainAttributesBases: {
    vitality: 7,
    dexterity: 9,
    intelligence: 15,
    strength: 9,
  },
  allowsCharacterClasses: [cleric, necromancer, wizard],
};

export default characterRace;
