import { defineMessages } from "react-intl";

export default defineMessages({
  description: {
    id: "forgotPasswordWindow.description",
    defaultMessage:
      "If you have forgotten your FHX Restoration password, we can send you an email to reset your password. All we need is your username or email address:",
    description: "",
  },
  notFoundDescription: {
    id: "forgotPasswordWindow.notFoundDescription",
    defaultMessage:
      "We cannot find a verified user with this name or email address. Is the account's email address verified? Please make sure that you have entered your correct username or email address.",
    description: "",
  },
  successDescription: {
    id: "forgotPasswordWindow.successDescription",
    defaultMessage:
      "We have sent you an email with instructions on how to reset your password. Please check your mailbox and spam folder.",
    description: "",
  },
  title: {
    id: "forgotPasswordWindow.title",
    defaultMessage: "Forgot Password",
    description: "",
  },
  usernameOrEmail: {
    id: "forgotPasswordWindow.usernameOrEmail",
    defaultMessage: "Username or Email",
    description: "",
  },
  cancel: {
    id: "forgotPasswordWindow.cancel",
    defaultMessage: "Cancel",
    description: "",
  },
  back: {
    id: "forgotPasswordWindow.back",
    defaultMessage: "Back",
    description: "",
  },
  close: {
    id: "forgotPasswordWindow.close",
    defaultMessage: "Close",
    description: "",
  },
  sendMail: {
    id: "forgotPasswordWindow.sendMail",
    defaultMessage: "Send Mail",
    description: "",
  },
});
