import { IWebsiteCharacterRace } from "../../interfaces";
import {
  archer,
  cleric,
  enchanter,
  knight,
  necromancer,
  paladin,
  summoner,
  thief,
  warrior,
  wizard,
} from "../characterClasses";

const characterRace: IWebsiteCharacterRace = {
  id: "human",
  isEnabled: false,
  i18nTag: {
    en: "Hn",
    de: "Mn",
  },
  i18nName: {
    en: "Human",
    de: "Mensch",
  },
  i18nDescription: {
    en: "They are the most visible race in continent of Idios. They have been around 4000 years but relatively short in history, yet they have quickly established their power in short time. They threathened the other non humans to improve their lives and almost caused a great war in Rog, but they are trying hard to keep the peace these days.",
    de: "",
  },
  mainAttributesBases: {
    vitality: 10,
    dexterity: 9,
    intelligence: 9,
    strength: 10,
  },
  allowsCharacterClasses: [archer, cleric, enchanter, knight, necromancer, paladin, summoner, thief, warrior, wizard],
};

export default characterRace;
