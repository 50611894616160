import React from "react";
import { IFaqEntry } from "../../interfaces";

const entries: Array<IFaqEntry> = [];
export default entries;

entries.push({
  question: "Ist es spielbar?",
  answer: <>Noch nicht.</>,
});
entries.push({
  question: "Kann ich es offline / alleine spielen?",
  answer: <>Nein.</>,
});
entries.push({
  question: "Warum nicht?",
  answer: (
    <>
      In dieser Phase handelt es sich um einen sehr instabilen Entwicklungsserver mit sehr limitierter Funktion.
      <br /> Das Aufsetzen der Servers und dessen Umgebung ist für nicht technische Benutzer nicht trivial.
    </>
  ),
});
entries.push({
  question: "Wann wird es veröffentlicht?",
  answer: (
    <>
      Immer mit der Ruhe, es ist ein Hobbyprojekt. Wir haben unser Vorhaben erstmal im Rahmen eines Sneak Peaks im
      Dezember 2018 vorgestellt. In diesem Projekt steckt viel Arbeit und liebe zum Detail, daher kann die Restaurierung
      eine Weile dauern.
    </>
  ),
});
entries.push({
  question: "Wo kann ich den Server herunterladen?",
  answer: <>Nirgens.</>,
});
entries.push({
  question: "Wo kann ich die Clients herunterladen?",
  answer: (
    <>
      Es gibt eine Sammlung von originalen Spiele Clients sind verfügbar sind.
      <br />
      Wenn du eine andere, nicht aufgelistete, Client-Version hast, melde dich bitte bei uns. http://bit.ly/2ti5bAC
    </>
  ),
});
entries.push({
  question: "Kann ich helfen?",
  answer: (
    <>
      Wenn du Erfahrung in der Softwareentwicklung oder im Reverse Engineering hast und bereit bist Zeit in dieses
      Projekt zu investieren, ja. <br />
      Falls nicht-technische Hilfe benötigt wird, wird diese im Discord Channel #general_english angekündigt.
    </>
  ),
});
entries.push({
  question: "Wo kann ich spenden?",
  answer: (
    <>
      Wir akzeptieren keine Spenden jeglicher Art.
      <br />
      Wir zielen NICHT darauf ab finanziell von dem Spiel zu profitieren.
    </>
  ),
});
entries.push({
  question: "Was brauchst du?",
  answer: (
    <>
      Wenn Sie andere Spiele-Clients als im obigen Link haben (insbesondere frühe koreanische Versionen oder ein neuerer
      Xiones-Client), wenden Sie sich bitte an mich.
      <br />
      Im Moment wird nichts mehr benötigt.
    </>
  ),
});
entries.push({
  question: "Läuft das Spiel unter Windows 10 (64bit)?",
  answer: <>Ja.</>,
});
entries.push({
  question: "Kann ich meinen Namen reservieren?",
  answer: <>Nein.</>,
});
entries.push({
  question: "Gibt es irgendwelche Updates?",
  answer: (
    <>
      Wenn es Neuikeiten gibt, wird es in Discord unter #announcements von @Moderator oder @Admin geposted. <br />
      Zwischendurch posten wir immer wieder kleinere Fortschritte auf unseren Video-Kanälen: <br />
      <a href="http://bit.ly/2TJQnGo" target="_blank" rel="noopener noreferrer">
        http://bit.ly/2TJQnGo
      </a>
      <br />
      <a href="http://bit.ly/2URBYIe" target="_blank" rel="noopener noreferrer">
        http://bit.ly/2URBYIe
      </a>
      <br />
      Oder schau auf unserer Website <a href="https://fhxrestoration.com/"> https://fhxrestoration.com/ </a>
      vorbei!
    </>
  ),
});
entries.push({
  question: "Worum geht es in diesem Projekt?",
  answer: (
    <>
      Ziel dieses Projektes ist es eine FHX (Ferentus, Herrcot, Xiones) Serverinfrastruktur zu entwickeln die mit den
      originalen Spiele Clients zusammenarbeitet. <br />
      Um das umzusetzen müssen die das originale Netzwerkprotokoll der Spieleclients ausarbeiten (Reverse Engineeren)
      und neu implementieren. <br />
      Es gibt auch viel Logik auf der Serverseite, die implementiert werden muss (NPC-Kontrolle, Spawns, Teleports,
      Charakter Bewegung, Inventar, Gegenstände, Charakter, Login, ..). <br /> Zum Glück sind Informationen über das
      Spiel im Spieleclient selbst gespeichert. <br /> Im Moment konzentrieren wir uns auf V1, da es die erste
      (verfügbare) Iteration des Spiels mit einem einfacheren Netzwerkprotokoll ist, aber es sieht so aus, als würden
      die anderen Spielversionen auf der Serverseite sehr ähnlich funktionieren.
    </>
  ),
});
entries.push({
  question: "Wie ist der aktuelle Stand?",
  answer: (
    <>
      Wir werden dich von Zeit zu Zeit über den Fortschritt hier, auf Discord und auf unserem YouTube-Kanal auf dem
      Laufenden halten.
    </>
  ),
});
entries.push({
  question: "Worum geht es im #git-feed Kanal?",
  answer: (
    <>
      Git ist ein Tool, das hauptsächlich von Entwicklern verwendet wird, um Änderungen im Quellcode nachzuverfolgen.
      Jeder commit enthält eine Beschreibung der Änderungen. In diesem Kanal werden diese commit-Nachrichten automatisch
      von einem Bot veröffentlicht. <br />
      Wie baust ihr das Spiel neu auf und balancierst es so, dass es genau wie das Original funktioniert? Wir haben alle
      ursprünglichen Werte in Bezug auf Gegenstände, NSCs und Fertigkeiten. <br /> Die genauen Kampfberechnungen sind
      noch offen und müssen unter Verwendung der Originaldaten (Screenshots, Videos, Protokolle) ausgearbeitet und
      verifiziert werden.
    </>
  ),
});
entries.push({
  question: "Kannst du dem Spiel neue Gegenstände, Fertigkeiten, Karten und NPCs hinzufügen?",
  answer: (
    <>
      Derzeit können wir keine neuen 3D Modelle oder Animationen erstellen, sondern vorhandene wiederverwenden und die
      Eigenschaften ändern.
    </>
  ),
});
entries.push({
  question: "Kannst du dem Spiel neue Rassen / Klassen hinzufügen?",
  answer: (
    <>
      Nein. Vielleicht könnten wir die teilweise implementierten Rassen (Mensch, Zwerg, ..) nutzen, aber wir haben keine
      Modelle und Animationen. Auch würde es das Spielgeschehen komplett verändern.
    </>
  ),
});
entries.push({
  question: "Werden modernere neue Auflösungen / Seitenverhältnisse unterstützt werden?",
  answer: <>Ja.</>,
});
entries.push({
  question: "Könnt ihr ein Video von XYZ posten?",
  answer: <>Schreib uns einfach an. ;-)</>,
});
entries.push({
  question: "Wie hat das Projekt angefangen?",
  answer: (
    <>
      Wir haben diesen Discord-Kanal gegründet und arbeiten seit Juli 2018 zusammen. @Wildriff war von anfang an sehr
      Motiviert das Netzwerkprotokoll rauszuarbeiten. Aber größere Fortschritte erzielten wir erst als AcarX (ein
      externer Helfer, der dieses Spiel nicht gespielt hat) uns half, die Grundlagen des Netzwerkprotokolls zu
      verstehen, und uns eine Proof-of-Concept-Implementierung zur Verfügung stellte. Diese haben wir übernommen und auf
      basis dessen das Protokoll weiter erarbeitet. Einige Andere haben vor vielen Jahren angefangen, größtenteils
      unabhängig voneinander an verschiedenen Teilen des Spiels zu arbeiten. Robb (rbb138) hat 2014 Teile des
      Kartenformats dokumentiert, b4ttl3m4st3r (Tobi) hat eine Textversion des Spiels entwickelt und viele Informationen
      über das Spiel zusammengetragen. Marcel (Cynicer) verbringt seit 2012 viel Zeit damit die .tbl
      (Spieledatendateien) zu dokumentieren und Spieledaten zu extrahieren, mit den meisten Fortschritten in den letzten
      Monaten, um ehrlich zu sein).
    </>
  ),
});
