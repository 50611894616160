import React, { useCallback, useState } from "react";
import { useIntl } from "react-intl";
import { Button, EButtonType, Spinner } from "@fhx/ui";
import { validate as validateEmailAddress } from "email-validator";
import axios from "axios";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheckCircle, faTimesCircle } from "@fortawesome/free-solid-svg-icons";
import classNames from "classnames";
import styles from "./ForgotPasswordWindow.module.scss";
import i18n from "./ForgotPasswordWindow.i18n";
import { AUTH_SERVER_HOST } from "../../../../config";
import { validateUsername } from "../../../../helpers";
import { Input, ReCaptcha } from "../../../common";
import DefaultWindow from "../../DefaultWindow/DefaultWindow";

type IStatus = "IDLE" | "LOADING" | "SUCCESS" | "NOT_FOUND" | "BAD_REQUEST";

export interface IProps {
  username?: string;
  onClose?: () => void;
  className?: string;
  style?: React.CSSProperties;
}

// eslint-disable-next-line complexity
export default function ForgotPasswordWindow({ username, onClose, className, style }: IProps): React.ReactElement {
  const { formatMessage } = useIntl();
  const [reCaptchaToken, setReCaptchaToken] = useState<string>("");
  const [attemptAmount, setAttemptAmount] = useState<number>(0);
  const [usernameOrEmail, setUsernameOrEmail] = useState<string>(username || "");
  const [status, setStatus] = useState<IStatus>("IDLE");

  const handleCancel = useCallback((): void => {
    if (onClose) onClose();
  }, [onClose]);

  const isUsernameOrEmailAddressValid = useCallback((input: string): boolean => {
    return validateEmailAddress(input) || validateUsername(input);
  }, []);

  const validateForm = useCallback((): boolean => {
    if (usernameOrEmail === "") return false;
    if (reCaptchaToken === "") return false;
    if (!isUsernameOrEmailAddressValid(usernameOrEmail)) return false;
    return true;
  }, [isUsernameOrEmailAddressValid, reCaptchaToken, usernameOrEmail]);

  const renderIsLoading = useCallback((): React.ReactNode => {
    return <Spinner className={styles.spinner} />;
  }, []);

  const renderNotFound = useCallback((): React.ReactNode => {
    return (
      <>
        <div className={classNames(styles.statusIcon, styles.error)}>
          <FontAwesomeIcon icon={faTimesCircle} />
        </div>
        <div className={styles.description}>{formatMessage(i18n.notFoundDescription)}</div>
      </>
    );
  }, [formatMessage]);

  const renderSuccess = useCallback((): React.ReactNode => {
    return (
      <>
        <div className={classNames(styles.statusIcon, styles.ok)}>
          <FontAwesomeIcon icon={faCheckCircle} />
        </div>
        <div className={styles.description}>{formatMessage(i18n.successDescription)}</div>
      </>
    );
  }, [formatMessage]);

  const sendRequest = useCallback(async (): Promise<void> => {
    setStatus("LOADING");
    await axios
      .post(`${AUTH_SERVER_HOST}/sendPasswordResetEmail`, { usernameOrEmail, reCaptchaToken })
      .then(() => {
        setStatus("SUCCESS");
      })
      .catch((error) => {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
        switch (error?.response?.status) {
          case 404: // Not Found
          case 401:
            setStatus("NOT_FOUND");
            break;
          case 400: // Bad Request
            setStatus("BAD_REQUEST");
            break;
          default:
            // Unknown
            setStatus("BAD_REQUEST");
            break;
        }
      });
  }, [reCaptchaToken, usernameOrEmail]);

  const handleSubmit = useCallback((): void => {
    if (validateForm()) {
      sendRequest().catch((error) => console.error(error));
      setReCaptchaToken("");
      setAttemptAmount(attemptAmount + 1);
    }
  }, [attemptAmount, sendRequest, validateForm]);

  let footer = <></>;
  switch (status) {
    case "LOADING":
      footer = <></>;
      break;
    case "NOT_FOUND":
      footer = (
        <Button type={EButtonType.CN} onClick={(): void => setStatus("IDLE")} className={styles.submitButton}>
          {formatMessage(i18n.back)}
        </Button>
      );
      break;
    case "SUCCESS":
      footer = (
        <Button type={EButtonType.CN} onClick={handleCancel} className={styles.submitButton}>
          {formatMessage(i18n.close)}
        </Button>
      );
      break;
    case "BAD_REQUEST":
    case "IDLE":
      footer = (
        <>
          <Button type={EButtonType.PVP_INFO} onClick={handleCancel} className={styles.submitButton}>
            {formatMessage(i18n.cancel)}
          </Button>
          <Button
            type={EButtonType.CN}
            onClick={handleSubmit}
            className={styles.submitButton}
            isDisabled={!validateForm()}
          >
            {formatMessage(i18n.sendMail)}
          </Button>
        </>
      );
      break;
    default:
      throw new Error(`Unknown status '${JSON.stringify(status)}'.`);
  }

  return (
    <DefaultWindow
      header={formatMessage(i18n.title)}
      footer={footer}
      className={classNames(styles.window, className)}
      style={style}
    >
      {status === "SUCCESS" && renderSuccess()}
      {status === "LOADING" && renderIsLoading()}
      {status === "NOT_FOUND" && renderNotFound()}
      {(status === "IDLE" || status === "BAD_REQUEST") && (
        <>
          <div className={styles.description}>{formatMessage(i18n.description)}</div>
          <Input
            type="text"
            tint={usernameOrEmail !== "" && !isUsernameOrEmailAddressValid(usernameOrEmail) ? "error" : undefined}
            label={formatMessage(i18n.usernameOrEmail)}
            placeholder={`${formatMessage(i18n.usernameOrEmail)} ...`}
            value={usernameOrEmail}
            onChange={(event): void => {
              setUsernameOrEmail(event.target.value);
            }}
            wrapperClassName={styles.inputWrapper}
          />
          <div className={styles.reCaptchaWrapper}>
            <ReCaptcha
              onChange={(token): void => {
                if (token) setReCaptchaToken(token);
              }}
            />
          </div>
        </>
      )}
    </DefaultWindow>
  );
}
