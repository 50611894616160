import React, { useCallback, useEffect, useState } from "react";
import NotificationsContext from "./NotificationsContext";
import INotificationsContext from "./INotificationsContext";
import initialState from "./initialState";
import INotificationMeta from "./INotificationMeta";
import { STATIC_NOTIFICATIONS } from "../../config";

const DEFAULT_TIMEOUT_MS = 5000;

export interface IProps {
  children: React.ReactNode;
}

export default function NotificationsProvider({ children }: IProps): React.ReactElement {
  const [notifications, setNotifications] = useState<Array<INotificationMeta>>(initialState.notifications);

  const removeByIndex = useCallback(
    (index: number): number => {
      const newNotifications = Array.from(notifications);
      if (index > -1) newNotifications.splice(index, 1);
      setNotifications(newNotifications);
      return newNotifications.length;
    },
    [notifications],
  );

  const removeNotificationByMetaAfterTimeout = useCallback(
    (meta: INotificationMeta, timeoutMs = DEFAULT_TIMEOUT_MS): void => {
      setTimeout(() => {
        setNotifications((old) => old.filter((entry) => entry !== meta));
      }, timeoutMs);
    },
    [],
  );

  const push = useCallback(
    (meta: INotificationMeta): number => {
      const metaWithDefaults = meta;
      if (metaWithDefaults.timeoutMs === undefined) metaWithDefaults.timeoutMs = DEFAULT_TIMEOUT_MS;
      if (metaWithDefaults.isClosable === undefined) metaWithDefaults.isClosable = true;
      setNotifications((old) => [...old, metaWithDefaults]);
      if (metaWithDefaults.timeoutMs !== 0)
        removeNotificationByMetaAfterTimeout(metaWithDefaults, metaWithDefaults.timeoutMs);
      return notifications.length + 1;
    },
    [notifications.length, removeNotificationByMetaAfterTimeout],
  );

  const pop = useCallback(
    (amount = 1): number => {
      const newNotifications = Array.from(notifications);
      // eslint-disable-next-line no-plusplus
      for (let i = 0; i++; i <= amount) newNotifications.pop();
      setNotifications(newNotifications);
      return newNotifications.length;
    },
    [notifications],
  );

  const shift = useCallback(
    (amount = 1): number => {
      const newNotifications = Array.from(notifications);
      // eslint-disable-next-line no-plusplus
      for (let i = 0; i++; i <= amount) newNotifications.shift();
      setNotifications(newNotifications);
      return newNotifications.length;
    },
    [notifications],
  );

  const clear = useCallback((): number => {
    setNotifications([]);
    return 0;
  }, []);

  useEffect(() => {
    STATIC_NOTIFICATIONS.forEach((entry) => push(entry));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [STATIC_NOTIFICATIONS]);

  const context: INotificationsContext = {
    notifications,
    removeByIndex,
    push,
    pop,
    shift,
    clear,
  };
  return <NotificationsContext.Provider value={context}>{children}</NotificationsContext.Provider>;
}
