import { defineMessages } from "react-intl";

export default defineMessages({
  title: {
    id: "page.registerForm.title",
    defaultMessage: "Registration",
    description: "",
  },
  registrationIsDisabled: {
    id: "page.registerForm.registrationIsDisabled",
    defaultMessage: "Account creation is currently disabled.",
    description: "",
  },
  rule1: {
    id: "page.registerForm.rule1",
    defaultMessage: "All fields are mandatory",
    description: "",
  },
  rule2: {
    id: "page.registerForm.rule2",
    defaultMessage: "Username must consist of 3 to 20 (lower case only) alphanumeric characters",
    description: "",
  },
  rule3: {
    id: "page.registerForm.rule3",
    defaultMessage: "E-mail address must be a valid and actively used email address",
    description: "",
  },
  rule3Info: {
    id: "page.registerForm.rule3.info",
    defaultMessage: "Email validation might be required for upcoming features",
    description: "",
  },
  rule4: {
    id: "page.registerForm.rule4",
    defaultMessage: "Password must consist of 8 to 30 characters",
    description: "",
  },
  rule5: {
    id: "page.registerForm.rule5",
    defaultMessage: "Confirmation fields must verify their corresponding fields with the exact same value",
    description: "",
  },
  rule6: {
    id: "page.registerForm.rule6",
    defaultMessage: '"I\'m not a robot" (Captcha) must be solved correctly',
    description: "",
  },
  "username-ph": {
    id: "page.registerForm.username.placeHolder",
    defaultMessage: "Username",
    description: "",
  },
  "email-ph": {
    id: "page.registerForm.email.placeHolder",
    defaultMessage: "E-Mail Address",
    description: "",
  },
  "emailConfirm-ph": {
    id: "page.registerForm.emailConfirm.placeHolder",
    defaultMessage: "Confirm E-Mail Address",
    description: "",
  },
  "password-ph": {
    id: "page.registerForm.password.placeHolder",
    defaultMessage: "Password",
    description: "",
  },
  "passwordConfirm-ph": {
    id: "page.registerForm.passwordConfirm.placeHolder",
    defaultMessage: "Confirm password",
    description: "",
  },
  createAccount: {
    id: "page.registerForm.createAccount",
    defaultMessage: "Create account",
    description: "",
  },
});
