import { IWebsiteCharacterClass } from "../../interfaces";
import { ECharacterStrengthMeter } from "../../enums";
import defaultAttributMultipliers from "./defaultAttributMultipliers";

const cleric: IWebsiteCharacterClass = {
  id: "cleric",
  isEnabled: true,
  i18nTag: {
    en: "Cl",
    de: "Kl",
  },
  i18nName: {
    en: "Cleric",
    de: "Kleriker",
  },
  roles: ["Healing", "Support"],
  i18nDescription: {
    en: "Clerics are people that make God's protection real. Clerics cast healing spells and additional buff magic to increase party member's abilities. They can even teleport characters from place to place.",
    /*
    Clerics are the embodiment of the divine protection. They dominate healing spells and magic, with which they can reinforce group members.
    Cleric can divine wrath by their hands forward, but they find more pleasure in it, to heal and protect others.
    Clerics are on the whole continent of Rog high. You are always in the service of others, on behalf of higher values and of mercy.
    The main value is INT status, so he increased his magical energy. So much more healing spells and protection spells, they can conjure.
    However, the other status values are for the equipment, moving and, of course, needed for life.
    */
    de: "Kleriker machen Gottes Schutz wahr. Kleriker sprechen heilende Zaubersprüche aus und können die Fähigkeiten der Mitglieder einer Gruppe auf magische Weise stärken. Sie können sogar Charaktere von Ort zu Ort teleportieren.",
  },
  strengthMeters: [
    { id: ECharacterStrengthMeter.ATTACK, value: 40 },
    { id: ECharacterStrengthMeter.DEFENSE, value: 60 },
    { id: ECharacterStrengthMeter.PARTY, value: 100 },
    { id: ECharacterStrengthMeter.SOLO, value: 30 },
  ],
  difficulty: "HARD",
  offensive: "LOW",
  defense: "LOW",
  i18nTips: [
    {
      en: "Archers do not like the cleric's Haste buff because it overwrites the archer's speed buff which is better for them. Because of this you should care of not buffing archers with the Haste-GrowUp-Combo.",
    },
  ],
  preferredWeapons: ["staff", "wand", "shield"],
  preferredArmor: ["robe", "criclet", "glovesMagic", "bootsMagic", "cape"],
  preferredAccessoires: ["ring", "necklace"],
  classSkillModels: [],
  classSkillModelsV2: [],
  classSkillComboModels: [],
  classSkillComboModelsV2: [],
  attributeMultipliers: defaultAttributMultipliers,
};

export default cleric;
