import React from "react";
import classNames from "classnames";
import styles from "./TernaryStatusIcon.module.scss";

type IStatus = "OK" | "ADEQUATE" | "BAD";
export interface IProps {
  status?: IStatus;
  className?: string;
  style?: React.CSSProperties;
}

export default function TernaryStatusIcon({ status = "OK", className = "", style }: IProps): React.ReactElement {
  return <div className={classNames(styles.statusIcon, styles[status], className)} style={style} />;
}
