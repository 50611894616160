import { IWebsiteCharacterClass } from "../../interfaces";
import { ECharacterStrengthMeter } from "../../enums";
import defaultAttributMultipliers from "./defaultAttributMultipliers";

const archer: IWebsiteCharacterClass = {
  id: "archer",
  isEnabled: true,
  i18nTag: {
    en: "Ac",
    de: "Sc",
  },
  i18nName: {
    en: "Archer",
    de: "Schütze",
  },
  roles: ["Ranged damage", "Pulling of mobs", "Enemy control"],
  i18nDescription: {
    en: "Archers are trained by elves. They specialize in bow and agility related skills.",
    /*
    Archers are specialised on fighting with bow and dagger.
    Their dexterity and mobility make them nearly invincible in the plains and hills and their arrows find the weak points of their enemies as if they were directed by the spirits of the elves.
    They attract enemies with speed and long range weapons and also deal high damage.
    Their main status value DEX is needed to string together multiple attacks.
    They don't need much STR because they only wear light armour. Of course they need a little life energy though they bascically attack from afar.
    */
    de: "Schützen werden von Elfen ausgebildet. Sie sind auf den Bogen und auf Fähigkeiten, die mit der Beweglichkeit zusammenhängen, spezialisiert.",
  },
  strengthMeters: [
    { id: ECharacterStrengthMeter.ATTACK, value: 80 },
    { id: ECharacterStrengthMeter.DEFENSE, value: 40 },
    { id: ECharacterStrengthMeter.PARTY, value: 40 },
    { id: ECharacterStrengthMeter.SOLO, value: 40 },
  ],
  difficulty: "MEDIUM",
  offensive: "HIGHT",
  defense: "LOW",
  preferredWeapons: ["bow", "dagger"],
  preferredArmor: ["jacket", "pants", "helmet", "gloves", "boots", "cape"],
  preferredAccessoires: ["ring", "necklace"],
  classSkillModels: [],
  classSkillModelsV2: [],
  classSkillComboModels: [],
  classSkillComboModelsV2: [],
  attributeMultipliers: defaultAttributMultipliers,
};

export default archer;
