import {
  SUBMIT_TICKET_NAME_VALID_REGEX,
  SUBMIT_TICKET_CHARACTER_LENGTH_MIN,
  SUBMIT_TICKET_CHARACTER_LENGTH_MAX,
} from "../../config";

export default function validateName(characterName: string): boolean {
  if (characterName.length < SUBMIT_TICKET_CHARACTER_LENGTH_MIN) return false;
  if (characterName.length > SUBMIT_TICKET_CHARACTER_LENGTH_MAX) return false;
  if (!SUBMIT_TICKET_NAME_VALID_REGEX.test(characterName)) return false;
  return true;
}
