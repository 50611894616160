import React, { useContext } from "react";
import classNames from "classnames";
import { Modal } from "@fhx/ui";
import { ModalsContext } from "../../../contexts";
import styles from "./ModalsArea.module.scss";

export interface IProps {
  className?: string;
  style?: React.CSSProperties;
}

export default function ModalsArea({ className, style }: IProps): React.ReactElement {
  const { removeByIndex, modals } = useContext(ModalsContext);

  return (
    <div className={classNames(styles.area, className)} style={style}>
      {modals.map((entry, index) => (
        <>
          <Modal
            key={entry.id || index}
            isSimplyClosable={entry.isSimplyClosable}
            closeFunc={(): number => removeByIndex(index)}
          >
            {entry.content}
          </Modal>
        </>
      ))}
    </div>
  );
}
