import { StylesConfig } from "react-select/src/styles";
import { ASSETS_INTERFACE_CURSORS } from "../../../assets";
import { OptionType, IsMulti, GroupType } from "./Select";

const CURSOR_POINTER = `${ASSETS_INTERFACE_CURSORS}/interface.gif`;
const COLOR_BLUE = "#039be5";
const COLOR_GRAY = "#AAA";
const COLOR_DARK_GRAY = "#888";

const styles: StylesConfig<OptionType, IsMulti, GroupType> = {
  /* wrapper of the select component */
  container: (base) => ({
    ...base,
    // TODO: display: "inline-block",
    letterSpacing: "1px",
    fontSize: "16px",
  }),

  /* visible component */
  control: (base, props: { isDisabled: boolean; isFocused: boolean; menuIsOpen: boolean }) => ({
    ...base,
    cursor: `url(${CURSOR_POINTER}), pointer`,
    border: `solid ${COLOR_DARK_GRAY} 1px`,
    borderRadius: "2px",
    color: COLOR_GRAY,
    backgroundColor: "rgba(150,150,150,0.2)",
    boxShadow: "none",
    opacity: props.isDisabled ? 0.5 : 1,
    "&:hover": props.menuIsOpen
      ? {
          borderColor: COLOR_BLUE,
          boxShadow: "0 0 12px 4px rgba(3,155,229,0.25)",
        }
      : {},
    "&":
      props.isFocused || props.menuIsOpen
        ? {
            borderColor: COLOR_BLUE,
            boxShadow: "0 0 12px 4px rgba(3,155,229,0.25)",
          }
        : {},
  }),

  /* wrapper of currently select value */
  valueContainer: (base) => ({
    ...base,
  }),

  /* currently select value (for single select) */
  singleValue: (base, props: { isDisabled: boolean }) => ({
    ...base,
    "&": !props.isDisabled
      ? {
          color: "white",
        }
      : {},
  }),

  placeholder: (base) => ({
    ...base,
    whiteSpace: "nowrap",
  }),

  /* input for searching elements */
  input: (base) => ({
    ...base,
    color: "white",
  }),

  /* arrow icon */
  dropdownIndicator: (base, props: { isFocused: boolean }) => ({
    ...base,
    "&": props.isFocused ? { color: "white" } : {},
    "&:hover": { color: "white" },
  }),

  /* vertical line, which separates the selected value from the arrow icon */
  indicatorSeparator: (base, props: { isFocused: boolean }) => ({
    ...base,
    "&": props.isFocused ? { backgroundColor: "white" } : { backgroundColor: COLOR_DARK_GRAY },
  }),

  /* drop-down menu */
  menu: (base) => ({
    ...base,
    // borderRadius: 0,
    backgroundColor: "rgba(50,50,50,1)",
    color: COLOR_GRAY,
    hyphens: "auto",
    marginTop: 0, // kill the gap
    wordWrap: "break-word", // prevent menu to scroll horizontally
    border: `solid ${COLOR_BLUE} 1px`,
    borderTop: "none",
    boxShadow: "0 0 12px 4px rgba(3,155,229,0.25)",
  }),

  /* list of options, child of "menu" */
  menuList: (base) => ({
    ...base,
    padding: 0, // kill the white space on first and last option
  }),

  /* style for a single option */
  option: (base, props: { isDisabled: boolean; isFocused: boolean; isSelected: boolean }) => ({
    ...base,
    color: COLOR_GRAY,
    cursor: `url(${CURSOR_POINTER}), pointer`,
    // eslint-disable-next-line no-nested-ternary
    "&": props.isSelected
      ? props.isFocused
        ? {
            color: "white",
            backgroundColor: COLOR_BLUE,
          }
        : {
            color: "black",
            backgroundColor: "#FFF",
          }
      : props.isFocused
      ? {
          color: "white",
          backgroundColor: COLOR_BLUE,
        }
      : {
          background: "none",
        },
    "&:active": {
      color: "white",
      backgroundColor: COLOR_BLUE,
    },
  }),
};

export default styles;
