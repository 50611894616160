import React from "react";
import { useIntl } from "react-intl";
import ReCAPTCHA from "react-google-recaptcha";
import classNames from "classnames";
import { BorderedBox } from "@fhx/ui";
import styles from "./ReCaptcha.module.scss";
import { LOGIN_SERVER_RECAPTCHA_KEY } from "../../../config";

export interface IProps {
  onChange?: (token: string | null) => void;
  onExpired?: () => void;
  tabindex?: number;
  className?: string;
  style?: React.CSSProperties;
}

export default function ReCaptcha({ onChange, onExpired, tabindex, className, style }: IProps): React.ReactElement {
  const { locale } = useIntl();

  return (
    <BorderedBox
      type="MAIN_2"
      isGapless
      className={classNames(styles.box, className)}
      contentClassName={styles.boxContent}
      style={style}
    >
      <ReCAPTCHA
        sitekey={LOGIN_SERVER_RECAPTCHA_KEY}
        onChange={onChange}
        onExpired={onExpired}
        tabindex={tabindex}
        theme="dark"
        hl={locale}
      />
    </BorderedBox>
  );
}
