/* eslint-disable react/jsx-curly-brace-presence */
import React from "react";
import { IFaqEntry } from "../../interfaces";

const entries: Array<IFaqEntry> = [];
export default entries;

entries.push({
  question: "Is it playable?",
  answer: "No, but the release is planned at the 28th of January 2022.",
});

entries.push({
  question: "Can I reserve my name?",
  answer: "No, first come first serve. However, we will resolve disputed names.",
});

entries.push({
  question: "Where can I download the server?",
  answer: (
    <>
      You {"can't"}, {"it's"} not an open source project (yet).
    </>
  ),
});

entries.push({
  question: "Where can I download the client(s)?",
  answer: (
    <>
      There is a collection of original game clients available here. If you have any other client version please contact
      us.
      <br />
      <a
        href="https://drive.google.com/drive/folders/1ZVATsUidf-GcMA67VS8CI7RIeCwCz-yn"
        target="_blank"
        rel="noopener noreferrer"
      >
        https://drive.google.com/drive/folders/1ZVATsUidf-GcMA67VS8CI7RIeCwCz-yn
      </a>
    </>
  ),
});

entries.push({
  question: "Can I help?",
  answer: (
    <>
      Unless you are experienced in software development or reverse engineering and are willing to invest time in this
      project, no.
    </>
  ),
});

entries.push({
  question: "Where can I donate?",
  answer: <>We do NOT accept donations of any kind. We do NOT intend to profit financially from the game.</>,
});

entries.push({
  question: "What do you need?",
  answer: (
    <>
      If you have different game clients than in the link above (especially early Korean versions or a more recent
      Xiones client) please contact me. At the moment there is nothing more needed.
    </>
  ),
});

entries.push({
  question: "Does the game run on Windows 10 (64bit)?",
  answer: "Yes.",
});

entries.push({
  question: "What are the system requirements for the game?",
  answer: "Any 64-bit capable PC from the last 10 years will probably do, the game is pretty old.",
});

entries.push({
  question: "What is this project about?",
  answer: (
    <>
      The goal is to develop a FHX (Ferentus, Herrcot, Xiones) server emulator to work with the original game clients,
      which behaves just like the original game server. To do so we reverse engineer the network protocol of the game
      and implement a new communication layer for the game. There is also lot of logic on the server side to be
      implemented (NPC control, spawns, teleports, movement, inventory, items, character, login,..). Luckily there is a
      lot of information about the game stored in the game client itself.
      <br />
      We are using the original game client and are not intending to reimplement it (which is a big project in itself).
      However, when we find bugs in the client we try to fix them. This is very hard for us because we
      {"don't"} have the source code for the client and have to patch everything on assembler/machine code level.
    </>
  ),
});

entries.push({
  question: "Which version of the game will be playable? V1 or V3?",
  answer: (
    <>
      We started off working based on the V1 (Korean Herrcot) client. As we progressed we (partially) worked out various
      file formats (e.g. map, etc.) and added all V1 content to the latest V3 (Xiones) client. We have now combined
      client {'"V4"'}, which contains all assets and game data of both version combined in one.
      <br />
      At the moment we are working towards a version where both worlds available simultaneously and have a connection
      (portal) between. This is just a rough plan and may change in the future. Since we have players from different
      versions we want to have something to offer for all of you.
    </>
  ),
});

entries.push({
  question: "What is the #git-feed channel about?",
  answer: (
    <>
      Git is a tool mainly used by developer to keep track of source code changes. Each change (commit) include a
      description what has changed. In this channel a bot automatically posts those commit messages to keep you updated
      so you can see that we are active and what we are roughly doing.
    </>
  ),
});

entries.push({
  question: "How do you rebuild and balance the game so that it works just like original?",
  answer: (
    <>
      We have all original values regarding items, NPCs and skills. The exact combat calculations are still open and
      need to be worked out and verified using original dame data (screenshots, videos, logs).
    </>
  ),
});

entries.push({
  question: "Can you add new items, skills, maps and NPCs to the game?",
  answer: <>Currently we cannot create new models or animations, but reuse existing one and change the stats.</>,
});

entries.push({
  question: "Can you add new races/classes to the game?",
  answer: (
    <>
      No. Maybe we could make use of the partly implemented races (human, dwarf,..), but we have no models and
      animations. Also it would change the game play completely.
    </>
  ),
});

entries.push({
  question: "Will you support new resolutions/aspect ratios?",
  answer: "Yes.",
});

entries.push({
  question: "How did you get started?",
  answer: (
    <>
      We founded this Discord channel and started working together in July 2018 . Wildriff was very rigid in his mission
      to reverse engineer the network protocol. But it {"didn't"} take off until AcarX (an external guy, who
      {"didn't"} play this game) helped us to understand the basics of the network protocol and provided us with a
      proof-of-concept implementation, which we could take over with less reverse engineering skills. Several others
      started working many years back mostly independently on different parts of the game Robbie figured out parts of
      the map format in 2014, b4ttl3m4st3r worked in the text version of the game and extracted a lot of informations
      for the game and Cynicer was working on the .tbl (game data files) since 2012). Since that day we are working
      continously on our mission to bring the game back.
    </>
  ),
});
