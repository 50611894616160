import { IWebsiteCharacterClass } from "../../interfaces";
import { ECharacterStrengthMeter } from "../../enums";
import defaultAttributMultipliers from "./defaultAttributMultipliers";

const enchanter: IWebsiteCharacterClass = {
  id: "enchanter",
  isEnabled: false,
  i18nTag: {
    en: "En",
    de: "Za",
  },
  i18nName: {
    en: "Enchanter",
    de: "Zauberer",
  },
  roles: [],
  i18nDescription: {
    en: "Rumor has it! This class was mentioned in older games, but never published...",
    de: "",
  },
  strengthMeters: [
    { id: ECharacterStrengthMeter.ATTACK, value: 0 },
    { id: ECharacterStrengthMeter.DEFENSE, value: 0 },
    { id: ECharacterStrengthMeter.PARTY, value: 0 },
    { id: ECharacterStrengthMeter.SOLO, value: 0 },
  ],
  difficulty: undefined,
  offensive: undefined,
  defense: undefined,
  classSkillModels: [],
  classSkillModelsV2: [],
  classSkillComboModels: [],
  classSkillComboModelsV2: [],
  attributeMultipliers: defaultAttributMultipliers,
};

export default enchanter;
