import axios from "axios";

export default function checkHttpResponse(url: string): Promise<boolean> {
  return new Promise((resolve) => {
    axios
      .get(url)
      .then((response) => {
        if (response.status === 200) {
          resolve(true);
        } else {
          resolve(false);
        }
      })
      .catch(() => resolve(false));
  });
}
