import React, { Suspense } from "react";
import classNames from "classnames";
import { useLocation } from "react-router-dom";
import { Spinner } from "@fhx/ui";
import ErrorBoundary from "./components/ErrorBoundary/ErrorBoundary";
import styles from "./Content.module.scss";
import Routes from "../../../routes/Routes";

export interface IProps {
  className?: string;
  style?: React.CSSProperties;
}

export default function Content({ className, style }: IProps): React.ReactElement {
  const { pathname } = useLocation();
  return (
    <div className={classNames(styles.content, className)} style={style}>
      <ErrorBoundary key={pathname}>
        <Suspense
          fallback={
            <div className={styles.contentIsLoading}>
              <Spinner />
            </div>
          }
        >
          <Routes />
        </Suspense>
      </ErrorBoundary>
    </div>
  );
}
