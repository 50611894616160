export default function encryptCaesar(plaintext: string, shiftAmount: number): string {
  let ciphertext = "";
  for (let i = 0; i < plaintext.length; i++) {
    const plainCharacter = plaintext.charCodeAt(i);
    if (plainCharacter >= 97 && plainCharacter <= 122) {
      ciphertext += String.fromCharCode(((plainCharacter - 97 + shiftAmount) % 26) + 97);
    } else if (plainCharacter >= 65 && plainCharacter <= 90) {
      ciphertext += String.fromCharCode(((plainCharacter - 65 + shiftAmount) % 26) + 65);
    } else {
      ciphertext += String.fromCharCode(plainCharacter);
    }
  }
  return ciphertext;
}
