import React, { useContext } from "react";
import UserContext from "../UserContext";

export interface IProps {
  children: React.ReactNode;
}

export default function IfUserIsLoggedOut({ children }: IProps): React.ReactElement {
  const { isLoggedIn } = useContext(UserContext);
  return <>{!isLoggedIn && children}</>;
}
