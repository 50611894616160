import React, { useState, useCallback, useMemo, useEffect } from "react";
import { useIntl } from "react-intl";
import { Button, EButtonType, Spinner } from "@fhx/ui";
import axios from "axios";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheckCircle, faTimesCircle } from "@fortawesome/free-solid-svg-icons";
import classNames from "classnames";
import styles from "./VerifyEmailWindow.module.scss";
import i18n from "./VerifyEmailWindow.i18n";
import { AUTH_SERVER_HOST } from "../../../../config";
import { verifyEmailTokenSyntax } from "../../../../helpers";
import DefaultWindow from "../../DefaultWindow/DefaultWindow";

type IStatus = "LOADING" | "SUCCESS" | "FAILED";

export interface IProps {
  token: string;
  onClose?: () => void;
  className?: string;
  style?: React.CSSProperties;
}

export default function VerifyEmailWindow({ token, onClose, className, style }: IProps): React.ReactElement {
  const { formatMessage } = useIntl();
  const [status, setStatus] = useState<IStatus>("LOADING");
  const isTokenSyntaxValid = useMemo(() => verifyEmailTokenSyntax(token), [token]);

  useEffect(() => {
    if (status === "LOADING") {
      if (isTokenSyntaxValid && status === "LOADING") {
        axios
          .post(`${AUTH_SERVER_HOST}/verifyEmail`, { emailToken: token })
          .then(() => setStatus("SUCCESS"))
          .catch((error) => {
            console.error(error);
            setStatus("FAILED");
          });
      } else {
        setStatus("FAILED");
      }
    }
  }, [isTokenSyntaxValid, token, status]);

  const handleClose = useCallback((): void => {
    if (onClose) onClose();
  }, [onClose]);

  const renderIsLoading = useCallback((): React.ReactElement => {
    return <Spinner className={styles.spinner} />;
  }, []);

  const renderSuccess = useCallback((): React.ReactNode => {
    return (
      <>
        <div className={classNames(styles.statusIcon, styles.ok)}>
          <FontAwesomeIcon icon={faCheckCircle} />
        </div>
        <div className={styles.description}>{formatMessage(i18n.successDescription)}</div>
      </>
    );
  }, [formatMessage]);

  const renderFailed = useCallback((): React.ReactNode => {
    return (
      <>
        <div className={classNames(styles.statusIcon, styles.error)}>
          <FontAwesomeIcon icon={faTimesCircle} />
        </div>
        <div className={styles.description}>{formatMessage(i18n.failDescription)}</div>
      </>
    );
  }, [formatMessage]);

  let footer = <></>;
  switch (status) {
    case "LOADING":
      footer = <></>;
      break;
    case "FAILED":
    case "SUCCESS":
      footer = (
        <Button type={EButtonType.CN} onClick={handleClose} className={styles.submitButton}>
          {formatMessage(i18n.close)}
        </Button>
      );
      break;
    default:
      throw new Error(`Unknown status '${JSON.stringify(status)}'.`);
  }

  return (
    <DefaultWindow
      header={formatMessage(i18n.title)}
      footer={footer}
      className={classNames(styles.window, className)}
      style={style}
    >
      {status === "LOADING" && renderIsLoading()}
      {status === "FAILED" && renderFailed()}
      {status === "SUCCESS" && renderSuccess()}
    </DefaultWindow>
  );
}
