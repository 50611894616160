import { MessageFormatElement } from "react-intl";
import getTranslations from "./getTranslations";
import { getLocale, getIsoLocale } from "./storage";

export default function getMessagesForCurrentLocale(): Record<string, string> | Record<string, MessageFormatElement[]> {
  const translations: Record<string, Record<string, string>> = getTranslations();
  const locale = getLocale();
  const messages = translations[getIsoLocale(locale)];
  return messages;
}
