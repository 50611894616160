/* eslint-disable react/jsx-props-no-spreading */
import React, { useCallback, useRef } from "react";
import classNames from "classnames";
import ReactTooltip from "react-tooltip";
import ReactSelect, { GroupTypeBase, NamedProps } from "react-select";
import { useIntl } from "react-intl";
import styles from "./Select.module.scss";
import selectStyle from "./selectStyle";
import i18n from "./Select.i18n";

export type OptionType = { label: React.ReactNode; value: string | number };
export type IsMulti = false;
export type GroupType = GroupTypeBase<OptionType>;

export interface IProps extends NamedProps<OptionType, IsMulti, GroupType> {
  label?: React.ReactNode;
  tint?: "ok" | "error" | "warning";
  tooltipId?: string;
  tooltipContent?: React.ReactNode;
  wrapperClassName?: string;
  wrapperStyle?: React.CSSProperties;
}

export default function Select({
  label,
  tint,
  tooltipId,
  tooltipContent,
  wrapperClassName,
  wrapperStyle,
  ...args
}: IProps): React.ReactElement {
  const selectRef = useRef<ReactSelect<OptionType, IsMulti, GroupType>>(null);
  const { formatMessage } = useIntl();
  const focus = useCallback((): void => {
    if (selectRef.current) selectRef.current.focus();
  }, []);

  return (
    <div className={classNames(styles.wrapper, tint && styles[tint], wrapperClassName)} style={wrapperStyle}>
      {label && (
        <label onClick={focus} className={styles.label}>
          {label}
        </label>
      )}
      <ReactSelect
        ref={selectRef}
        data-tip
        data-for={tooltipId}
        className={classNames(styles.select, args.className)}
        classNamePrefix="Select"
        noOptionsMessage={(): string => formatMessage(i18n.noOptions)}
        styles={selectStyle}
        {...args}
      />
      {tooltipContent && (
        <ReactTooltip
          id={tooltipId}
          type="light"
          place="right"
          effect="solid"
          event="keydown"
          eventOff="blur"
          arrowColor="#888"
          className={styles.tooltip}
        >
          <div className={styles.tooltipContent}>{tooltipContent}</div>
        </ReactTooltip>
      )}
    </div>
  );
}
