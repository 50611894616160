/* eslint-disable react/jsx-props-no-spreading */
import React from "react";
import classNames from "classnames";
import styles from "./RegistrationInput.module.scss";

export interface IProps extends React.InputHTMLAttributes<HTMLInputElement> {
  backgroundType: "username" | "email" | "password" | "confirm";
  isInvalid?: boolean;
}

export default function RegistrationInput({ backgroundType, isInvalid = false, ...args }: IProps): React.ReactElement {
  return (
    <div
      className={classNames(
        styles.inputWrapper,
        styles[backgroundType],
        isInvalid && styles.isInvalid,
        args.readOnly && styles.readOnly,
        args.className && args.className,
      )}
      style={args.style}
    >
      <input {...args} />
    </div>
  );
}
