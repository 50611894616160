import React from "react";
import { useIntl } from "react-intl";
import ReactTooltip from "react-tooltip";
import classNames from "classnames";
import { BorderedBox } from "@fhx/ui";
import styles from "./ServerStatus.module.scss";
import ServerEntry from "./components/ServerEntry/ServerEntry";
import { TernaryStatusIcon } from "../../../../common";
import i18n from "./ServerStatus.i18n";
import { checkGameServerTriState, checkLoginServerTriState, checkPatchServerTriState } from "./helpers";

export interface IProps {
  className?: string;
  style?: React.CSSProperties;
}

export default function ServerStatus({ className, style }: IProps): React.ReactElement {
  const { formatMessage } = useIntl();

  return (
    <>
      <div data-tip data-for="serverStatusTooltip" className={classNames(styles.serverStatus, className)} style={style}>
        <ServerEntry name={formatMessage(i18n.loginServer)} validator={checkLoginServerTriState} />
        <ServerEntry name={formatMessage(i18n.patchServer)} validator={checkPatchServerTriState} />
        <ServerEntry name={formatMessage(i18n.gameServer)} validator={checkGameServerTriState} />
      </div>
      <ReactTooltip id="serverStatusTooltip" className={styles.tooltip}>
        <BorderedBox type="TOOLTIP" contentClassName={styles.tooltipContentBoxContent}>
          <div>
            <TernaryStatusIcon status="OK" />
            {formatMessage(i18n.online)}
          </div>
          <div>
            <TernaryStatusIcon status="ADEQUATE" />
            {formatMessage(i18n.unknown)}
          </div>
          <div>
            <TernaryStatusIcon status="BAD" />
            {formatMessage(i18n.offline)}
          </div>
        </BorderedBox>
      </ReactTooltip>
    </>
  );
}
