import React from "react";
import classNames from "classnames";
import styles from "./BackgroundVideo.module.scss";
import { ASSETS_WEBSITE } from "../../../assets";

export interface IProps {
  mimeType?: "video/mp4";
  video?: string;
  poster?: string;
  className?: string;
  style?: React.CSSProperties;
}

export default function BackgroundVideo({
  mimeType = "video/mp4",
  video = `${ASSETS_WEBSITE}/backgroundVideo.mp4`,
  poster = `${ASSETS_WEBSITE}/background.jpg`,
  className = "",
  style,
}: IProps): React.ReactElement {
  return (
    <div className={classNames(styles.wrapper, className)} style={style}>
      <video autoPlay loop muted poster={poster}>
        <source type={mimeType} src={video} />
      </video>
    </div>
  );
}
