import classNames from "classnames";
import React from "react";
import styles from "./SubHeaderRow.module.scss";

export interface IProps {
  children: React.ReactNode;
  className?: string;
  style?: React.CSSProperties;
}

export default function SubHeaderRow({ children, className = "", style }: IProps): React.ReactElement {
  return (
    <div className={classNames(styles.subHeaderRow, className)} style={style}>
      {children}
    </div>
  );
}
