import { defineMessages } from "react-intl";

export default defineMessages({
  discord: {
    id: "social.link.discord",
    defaultMessage: "Discord",
    description: "",
  },
  facebook: {
    id: "social.link.facebook",
    defaultMessage: "Facebook",
    description: "",
  },
  twitter: {
    id: "social.link.twitter",
    defaultMessage: "Twitter",
    description: "",
  },
  youtube: {
    id: "social.link.youtube",
    defaultMessage: "Youtube",
    description: "",
  },
  twitch: {
    id: "social.link.twitch",
    defaultMessage: "Twitch",
    description: "",
  },
  gitlab: {
    id: "social.link.gitlab",
    defaultMessage: "GitLab",
    description: "",
  },
});
