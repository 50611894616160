import React from "react";
import classNames from "classnames";
import { Button, EButtonType } from "@fhx/ui";
import { useNavigate } from "react-router-dom";
import { useIntl } from "react-intl";
import styles from "./ErrorMessageWindow.module.scss";
import i18n from "./ErrorMessageWindow.i18n";
import { CONTACT, HOME } from "../../../routes/paths";
import guardImg from "./img/guard.gif";
import { DefaultWindow } from "..";

export interface IProps {
  className?: string;
  style?: React.CSSProperties;
}

export default function ErrorMessageWindow({ className, style }: IProps): React.ReactElement {
  const { formatMessage } = useIntl();
  const navigate = useNavigate();
  return (
    <DefaultWindow
      header={formatMessage(i18n.error)}
      footer={
        <>
          <Button type={EButtonType.QUEST_USER} onClick={(): void => window.location.reload()}>
            {formatMessage(i18n.reloadPage)}
          </Button>
          <Button type={EButtonType.QUEST_USER} onClick={(): void => navigate(HOME)}>
            {formatMessage(i18n.home)}
          </Button>
          <Button type={EButtonType.QUEST_USER} onClick={(): void => navigate(CONTACT)}>
            {formatMessage(i18n.contact)}
          </Button>
        </>
      }
      className={classNames(styles.window, className)}
      style={style}
    >
      <div className={styles.imgAndText}>
        <img src={guardImg} alt="" className={styles.image} />
        <div className={styles.text}>
          <p>{formatMessage(i18n.textPt1)}</p>
          <p>{formatMessage(i18n.textPt2)}</p>
          <p>{formatMessage(i18n.textPt3)}</p>
        </div>
      </div>
    </DefaultWindow>
  );
}
