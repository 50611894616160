import React from "react";
import { BorderedBox } from "@fhx/ui";
import classNames from "classnames";
import styles from "./BorderedResponsiveVideo.module.scss";
import ResponsiveVideo from "./components/ResponsiveVideo/ResponsiveVideo";

export interface IProps {
  footNote?: React.ReactNode;
  className?: string;
  style?: React.CSSProperties;
}

export default function BorderedResponsiveVideo({ footNote, className, style }: IProps): React.ReactElement {
  return (
    <div className={classNames(className)} style={style}>
      <BorderedBox className={classNames(styles.box)}>
        <ResponsiveVideo />
      </BorderedBox>
      {footNote && <div className={styles.footNote}>{footNote}</div>}
    </div>
  );
}
