import React, { useCallback, useState } from "react";
import ModalsContext from "./ModalsContext";
import IModalsContext from "./IModalsContext";
import { IModalEntry } from ".";

export interface IProps {
  children: React.ReactNode;
}

export default function ModalsProvider({ children }: IProps): React.ReactElement {
  const [modals, setModals] = useState<Array<IModalEntry>>([]);

  const removeByIndex = useCallback(
    (index: number): number => {
      const newModals = Array.from(modals);
      if (index > -1) newModals.splice(index, 1);
      setModals(newModals);
      return newModals.length;
    },
    [modals],
  );

  const removeById = useCallback(
    (id: string): number => {
      const newModals = Array.from(modals).filter((entry) => entry.id && entry.id !== id);
      setModals(newModals);
      return newModals.length;
    },
    [modals],
  );

  const push = useCallback(
    (meta: IModalEntry): number => {
      setModals((old) => [...old, meta]);
      return modals.length + 1;
    },
    [modals.length],
  );

  const pop = useCallback(
    (amount = 1): number => {
      const newModals = Array.from(modals);
      // eslint-disable-next-line no-plusplus
      for (let i = 0; i++; i <= amount) newModals.pop();
      setModals(newModals);
      return newModals.length;
    },
    [modals],
  );

  const shift = useCallback(
    (amount = 1): number => {
      const newModals = Array.from(modals);
      // eslint-disable-next-line no-plusplus
      for (let i = 0; i++; i <= amount) newModals.shift();
      setModals(newModals);
      return newModals.length;
    },
    [modals],
  );

  const clear = useCallback((): number => {
    const amount = modals.length;
    setModals([]);
    return amount;
  }, [modals.length]);

  const context: IModalsContext = {
    modals,
    removeByIndex,
    removeById,
    push,
    pop,
    shift,
    clear,
  };
  return <ModalsContext.Provider value={context}>{children}</ModalsContext.Provider>;
}
