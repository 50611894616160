import { defineMessages } from "react-intl";

export default defineMessages({
  en: {
    id: "language.name.en",
    defaultMessage: "English",
    description: "",
  },
  de: {
    id: "language.name.de",
    defaultMessage: "German",
    description: "",
  },
  es: {
    id: "language.name.es",
    defaultMessage: "Spain",
    description: "",
  },
  fr: {
    id: "language.name.fr",
    defaultMessage: "French",
    description: "",
  },
  it: {
    id: "language.name.it",
    defaultMessage: "Italian",
    description: "",
  },
  ko: {
    id: "language.name.ko",
    defaultMessage: "Korean",
    description: "",
  },
  tr: {
    id: "language.name.tr",
    defaultMessage: "Turkish",
    description: "",
  },
  pt: {
    id: "language.name.pt",
    defaultMessage: "Portuguese",
    description: "",
  },
  he: {
    id: "language.name.he",
    defaultMessage: "Hebrew",
    description: "",
  },
});
