import React, { useState, useEffect, createRef, useCallback } from "react";
import { useIntl } from "react-intl";
import classNames from "classnames";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch, faTimesCircle } from "@fortawesome/free-solid-svg-icons";
import ReactDOMServer from "react-dom/server";
import { en, de, ko, pt, tr, he } from "../../../../data/faq";
import styles from "./FaqWindow.module.scss";
import i18n from "./FaqWindow.i18n";
import FaqEntry from "./components/FaqEntry/FaqEntry";
import { IFaqEntry } from "../../../../interfaces";
import DefaultWindow from "../../DefaultWindow/DefaultWindow";

export interface IProps {
  className?: string;
  style?: React.CSSProperties;
}

function filterEntriesBySearchString(input: Array<IFaqEntry>, search: string): Array<IFaqEntry> {
  return input.filter((entry) => {
    if (entry.question.toLowerCase().includes(search.toLowerCase())) return true;
    if (typeof entry.answer === "string" && entry.answer.includes(search.toLowerCase())) return true;
    const reactStr = ReactDOMServer.renderToString(entry.answer as React.ReactElement);
    const domStr = new DOMParser().parseFromString(reactStr, "text/html").body.innerText;
    if (domStr.toLowerCase().includes(search.toLowerCase())) return true;
    return false;
  });
}

export default function Faq({ className, style }: IProps): React.ReactElement {
  const { formatMessage, locale } = useIntl();
  const [searchValue, setSearchValue] = useState<string>("");
  const [filteredEntries, setFilteredEntries] = useState<Array<IFaqEntry>>([]);
  const inputRef = createRef<HTMLInputElement>();

  let entries = en;
  switch (locale) {
    case "en-GB":
      entries = en;
      break;
    case "de":
      entries = de;
      break;
    case "ko":
      entries = ko;
      break;
    case "pt":
      entries = pt;
      break;
    case "tr":
      entries = tr;
      break;
    case "he":
      entries = he;
      break;
    default:
      entries = en;
      console.error(`No FAQ entries found for locale ${locale}`);
  }

  const clearSearch = useCallback((): void => {
    setSearchValue("");
    if (inputRef.current) inputRef.current.focus();
  }, [inputRef]);

  useEffect(() => {
    setFilteredEntries(filterEntriesBySearchString(entries, searchValue));
  }, [entries, searchValue]);

  return (
    <DefaultWindow header={formatMessage(i18n.faq)} className={classNames(styles.window, className)} style={style}>
      <div className={styles.searchRow}>
        <FontAwesomeIcon icon={faSearch} />
        <input
          ref={inputRef}
          type="text"
          value={searchValue}
          placeholder={`${formatMessage(i18n.search)}...`}
          onChange={(event): void => setSearchValue(event.currentTarget.value)}
        />
        <FontAwesomeIcon icon={faTimesCircle} className={styles.clearSearchBtn} onClick={clearSearch} />
      </div>
      <div className={styles.entries}>
        {filteredEntries.map((entry) => (
          <FaqEntry key={entry.question} question={entry.question} answer={entry.answer} />
        ))}
        {filteredEntries.length < 1 && <div className={styles.noResults}>-- {formatMessage(i18n.noResults)} --</div>}
      </div>
    </DefaultWindow>
  );
}
