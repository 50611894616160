import React from "react";
import classNames from "classnames";
import styles from "./DefaultPage.module.scss";
import { setDocumentTitle } from "../../../helpers";

export interface IProps {
  header?: React.ReactNode;
  subHeader?: React.ReactNode;
  children: React.ReactNode;
  className?: string;
  style?: React.CSSProperties;
}

export default function DefaultPage({ header, subHeader, children, className, style }: IProps): React.ReactElement {
  if (typeof header === "string") setDocumentTitle(header);
  return (
    <div className={classNames(styles.page, className)} style={style}>
      {header && <div className={styles.header}>{header}</div>}
      {subHeader && <div className={styles.subHeader}>{subHeader}</div>}
      <div className={styles.content}>{children}</div>
    </div>
  );
}
