// HOSTS
export const CDN_HOST = "https://cdn.fhxrestoration.com";
const ASSETS_HOST_COMMON = `${CDN_HOST}/common`;
const ASSETS_HOST_WEBSITE = `${CDN_HOST}/website`;
const ASSETS_HOST_UPLOADS = `${CDN_HOST}/uploads`;
export const ASSETS_HOST_REL_THUMBNAIL_PATH = ".thumbnails";

// USERS
export const ASSETS_USERS = `${ASSETS_HOST_UPLOADS}/users`;

// COMMON
export const ASSETS_MUSIC = `${ASSETS_HOST_COMMON}/music`;
export const ASSETS_SKILLS = `${ASSETS_HOST_COMMON}/entities/skills`;
export const ASSETS_ITEMS = `${ASSETS_HOST_COMMON}/entities/items`;
export const ASSETS_RACES = `${ASSETS_HOST_COMMON}/entities/characterRaces`;
export const ASSETS_MAPS = `${ASSETS_HOST_COMMON}/entities/maps`;
export const ASSETS_GUILDS = `${ASSETS_HOST_COMMON}/entities/guilds`;
export const ASSETS_COUNTRY_FLAGS = `${ASSETS_HOST_COMMON}/entities/countries/icons`;
export const ASSETS_CLASSES = `${ASSETS_HOST_COMMON}/entities/characterClasses`;
export const ASSETS_INTERFACE_ICONS = `${ASSETS_HOST_COMMON}/interface/icons`;
export const ASSETS_INTERFACE_CURSORS = `${ASSETS_HOST_COMMON}/interface/cursors`;

// WEBSITE
export const ASSETS_WEBSITE = `${ASSETS_HOST_WEBSITE}`;
export const ASSETS_WEBSITE_CLASSES = `${ASSETS_HOST_WEBSITE}/characterClasses`;
export const ASSETS_HELP_CRAFTING = `${ASSETS_HOST_WEBSITE}/help/crafting`;
export const ASSETS_LORE = `${ASSETS_HOST_WEBSITE}/lore`;
export const ASSETS_GALLERY = `${ASSETS_HOST_WEBSITE}/gallery`;
export const ASSETS_DOWNLOADS = `${ASSETS_HOST_WEBSITE}/downloads`;
export const ASSETS_KEY_FEATURES = `${ASSETS_HOST_WEBSITE}/keyFeatures`;
