import { defineMessages } from "react-intl";

export default defineMessages({
  login: {
    id: "context.user.notification.title.login",
    defaultMessage: "Login",
    description: "",
  },
  youAreNowLoggedInAs: {
    id: "context.user.notification.content.login",
    defaultMessage: "You are now logged in as ''{username}''.",
    description: "",
  },
  logout: {
    id: "context.user.notification.title.logout",
    defaultMessage: "Logout",
    description: "",
  },
  youHaveBeenLoggedOut: {
    id: "context.user.notification.content.logout",
    defaultMessage: "You have been logged out.",
    description: "",
  },
});
