/* eslint-disable react/no-unescaped-entities */
/* eslint-disable react/jsx-curly-brace-presence */
import React from "react";
import classNames from "classnames";
import styles from "./ImprintWindow.module.scss";
import DefaultWindow from "../../DefaultWindow/DefaultWindow";

export interface IProps {
  className?: string;
  style?: React.CSSProperties;
}

export default function ImprintWindow({ className, style }: IProps): React.ReactElement {
  return (
    <DefaultWindow header={"Imprint"} className={classNames(styles.window, className)} style={style}>
      <div className={styles.content}>
        <h2>Organization</h2>
        {'"FHX Restoration"'}
        <h2>Represented by</h2>
        <a href="/development/team">{'"The Team"'}</a>
        <h2>Contact</h2>
        <h3>Discord</h3>
        <p>
          <a target="_blank" rel="noreferrer" href="https://discordapp.com/invite/M2KkfEK">
            https://discordapp.com/invite/M2KkfEK
          </a>
        </p>
        <h3>E-mail</h3>
        <p>
          <a href="mailto:mail.fhxrestoration@gmail.com">mail.fhxrestoration@gmail.com</a>
        </p>
        <h2>Responsible for content</h2>
        <p>
          <a href="/development/team">{'"The Team"'}</a>
        </p>
        <h2>Disclaimer</h2>
        <p>...</p>
      </div>
    </DefaultWindow>
  );
}
