import { EButtonType } from "@fhx/ui";

export default function getClassImgButtonTypeByClassId(classId: string): EButtonType {
  switch (classId) {
    case "archer":
      return EButtonType.CLASS_IMG_ARCHER;
    case "cleric":
      return EButtonType.CLASS_IMG_CLERIC;
    case "knight":
      return EButtonType.CLASS_IMG_KNIGHT;
    case "necromancer":
      return EButtonType.CLASS_IMG_NECROMANCER;
    case "paladin":
      return EButtonType.CLASS_IMG_PALADIN;
    case "summoner":
      return EButtonType.CLASS_IMG_SUMMONER;
    case "warrior":
      return EButtonType.CLASS_IMG_WARRIOR;
    case "wizard":
      return EButtonType.CLASS_IMG_WIZARD;
    default:
      throw new Error(`Unknown button type for classId '${classId}'.`);
  }
}
