import { defineMessages } from "react-intl";

export default defineMessages({
  search: {
    id: "window.announcements.entriesReducer.search",
    defaultMessage: "Search:",
    description: "",
  },
  tags: {
    id: "window.announcements.entriesReducer.tags",
    defaultMessage: "Tags:",
    description: "",
  },
  clear: {
    id: "window.announcements.entriesReducer.clear",
    defaultMessage: "Clear",
    description: "",
  },
  from: {
    id: "window.announcements.entriesReducer.from",
    defaultMessage: "From:",
    description: "",
  },
  to: {
    id: "window.announcements.entriesReducer.to",
    defaultMessage: "To:",
    description: "",
  },
});
