import { defineMessages } from "react-intl";

export default defineMessages({
  isLoading: {
    id: "apolloLoadingContent.isLoading",
    defaultMessage: "loading...",
    description: "Replacement text for a value from apollo in loading state.",
  },
  error: {
    id: "apolloLoadingContent.error",
    defaultMessage: "Error!",
    description: "Replacement text for a value from apollo in erroneous state.",
  },
});
