enum EFeatureToggleId {
  // Security
  ACCOUNT_REGISTRATION,
  ACCOUNT_LOGIN,

  // Pages
  PAGE_ACCOUNT_LOGIN,
  PAGE_ACCOUNT_REGISTRATION,

  PAGE_ANNOUNCEMENTS_NOTICES,
  PAGE_ANNOUNCEMENTS_UPDATES,
  PAGE_ANNOUNCEMENTS_EVENTS,
  PAGE_ANNOUNCEMENTS_GM_NOTES,

  PAGE_COLLECTIONS_CHARACTERS,
  PAGE_COLLECTIONS_GUILDS,
  PAGE_COLLECTIONS_USERS,

  PAGE_GAME_ADVENTURERS_GUIDE,
  PAGE_GAME_CLASS_INTRODUCTION,
  PAGE_GAME_FEATURES,
  PAGE_GAME_HISTORY,
  PAGE_GAME_LORE,
  PAGE_GAME_STATISTICS,
  PAGE_GAME_RULES,

  PAGE_LEGAL_EULA,
  PAGE_LEGAL_IMPRINT,
  PAGE_LEGAL_PRIVACY_POLICY,
  PAGE_LEGAL_TOS,

  PAGE_WIKI_CLASSES,
  PAGE_WIKI_CRAFTING,
  PAGE_WIKI_ITEMS,
  PAGE_WIKI_MAPS,
  PAGE_WIKI_MONSTERS,
  PAGE_WIKI_RACES,
  PAGE_WIKI_SKILLS,

  PAGE_MULTIMEDIA_ARTWORKS,
  PAGE_MULTIMEDIA_DOWNLOADS,
  PAGE_MULTIMEDIA_SCREENSHOTS,
  PAGE_MULTIMEDIA_SOUNDTRACKS,
  PAGE_MULTIMEDIA_VIDEOS,
  PAGE_MULTIMEDIA_WALLPAPERS,

  PAGE_SUPPORT_ADMIN,
  PAGE_SUPPORT_SUBMIT_TICKET,
  PAGE_SUPPORT_CONTACT,
  PAGE_SUPPORT_DEVELOPMENT,
  PAGE_SUPPORT_FAQ,
  PAGE_SUPPORT_HELP,
  PAGE_SUPPORT_SECURITYCENTER,
}

export default EFeatureToggleId;
