import React, { useCallback } from "react";
import { Link } from "react-router-dom";
import classNames from "classnames";
import styles from "./Navigation.module.scss";

import { INavigationEntry } from "../../../../../interfaces";
import { useNavigation } from "../../../../../hooks";

export interface IProps {
  className?: string;
  style?: React.CSSProperties;
}

export default function Navigation({ className, style }: IProps): React.ReactElement {
  const [navigation] = useNavigation();

  const renderNavigationEntry = useCallback((entry: INavigationEntry) => {
    const { children, label, route, isEnabled, onClick } = entry;
    if (isEnabled === false) return null;
    const hasSubEntries = children && children.length > 0;
    return (
      <li key={entry.id} className={classNames(hasSubEntries && styles.hasSubEntries)}>
        {route && (
          <Link to={route} onClick={onClick}>
            {label}
          </Link>
        )}
        {!route && <label onClick={onClick}>{label}</label>}
        {children && hasSubEntries && <ul>{children.map(renderNavigationEntry)}</ul>}
      </li>
    );
  }, []);

  return (
    <div className={classNames(styles.navigation, className)} style={style}>
      <ul>{navigation.map(renderNavigationEntry)}</ul>
    </div>
  );
}
