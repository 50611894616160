import axios, { AxiosError, AxiosRequestConfig, AxiosResponse } from "axios";
import { AUTH_SERVER_HOST } from "../../../config";

export default function sendPostRequest<IReq, IRes>(
  endpoint: string,
  data: IReq,
  config?: AxiosRequestConfig,
): Promise<IRes | undefined> {
  return axios
    .post<IReq, AxiosResponse<IRes>>(`${AUTH_SERVER_HOST}${endpoint}`, data, config)
    .then((result) => {
      return result.data;
    })
    .catch((error: AxiosError<IRes>) => {
      return error.response?.data;
    });
}
