import { sendPostRequest } from "./helpers";
import { ILoginServerResponse, IUser } from "./interfaces";

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface IRequestBody {}

export type IResponseBody = ILoginServerResponse<{
  user: Pick<IUser, "id" | "username" | "permLvl">;
  authToken: string;
  authTokenExpiresIn: number;
}>;

export default function getNewAuthToken(): Promise<IResponseBody | undefined> {
  return sendPostRequest<IRequestBody, IResponseBody>("/getNewAuthToken", {}, { withCredentials: true });
}
