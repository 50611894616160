import { IWebsiteCharacterClass } from "../../interfaces";
import { ECharacterStrengthMeter } from "../../enums";
import defaultAttributMultipliers from "./defaultAttributMultipliers";

const necromancer: IWebsiteCharacterClass = {
  id: "necromancer",
  isEnabled: false,
  i18nTag: {
    en: "Ne",
    de: "Ge",
  },
  i18nName: {
    en: "Necromancer",
    de: "Totenbeschwörer",
  },
  roles: [],
  i18nDescription: {
    en: "Necromancers take control of all dead souls and sometimes manipulate dead bodies for their own purposes. Necromancers are the experts of poisoning and have vast knowledge of various poisons.",
    de: "Totenbeschwörer übernehmen die Kontrolle über alle toten Seelen und manipulieren manchmal Leichen für ihre eigenen Zwecke. Totenbeschwörer sind Experten im Vergiften und besitzen ein großes Wissen über verschiedene Gifte.",
  },
  strengthMeters: [
    { id: ECharacterStrengthMeter.ATTACK, value: 0 },
    { id: ECharacterStrengthMeter.DEFENSE, value: 0 },
    { id: ECharacterStrengthMeter.PARTY, value: 0 },
    { id: ECharacterStrengthMeter.SOLO, value: 0 },
  ],
  difficulty: undefined,
  offensive: undefined,
  defense: undefined,
  classSkillModels: [],
  classSkillModelsV2: [],
  classSkillComboModels: [],
  classSkillComboModelsV2: [],
  attributeMultipliers: defaultAttributMultipliers,
};

export default necromancer;
