import React, { useState, useEffect } from "react";
import classNames from "classnames";
import styles from "./ServerEntry.module.scss";
import { TernaryStatusIcon } from "../../../../../../common";

export interface IProps {
  name: string;
  validator: () => Promise<"OK" | "ADEQUATE" | "BAD">;
  className?: string;
  style?: React.CSSProperties;
}

export default function ServerEntry({ name, validator, className, style }: IProps): React.ReactElement {
  const [status, setStatus] = useState<"OK" | "ADEQUATE" | "BAD">("BAD");

  useEffect(() => {
    validator()
      .then((result) => setStatus(result))
      .catch(() => setStatus("ADEQUATE"));
  }, [validator]);

  return (
    <div className={classNames(styles.entry, className)} style={style}>
      <TernaryStatusIcon status={status} />
      <div className={styles.name}>{name}</div>
    </div>
  );
}
