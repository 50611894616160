export const HOME = "/";

// LEGAL
export const IMPRINT = "/imprint";
export const EULA = "/end-user-license-agreement";
export const TOS = "/terms-of-service";
export const PRIVACY_POLICY = "/privacy-policy";

// ACCOUNT
export const LOGIN = "/login";
export const REGISTER = "/register";
export const VERIFY_EMAIL = "/verify-email";
export const FORGOT_PASSWORD = "/forgot-password";
export const RESEND_VERIFICATION_MAIL = "/resend-verification-mail";
export const RESET_PASSWORD = "/reset-password";

// ANNOUNCEMENTS
export const NOTICES = "/notices";
export const UPDATES = "/updates";
export const EVENTS = "/events";
export const GM_NOTES = "/gm-notes";

// COLLECTIONS
export const CHARACTERS = "/characters";
export const GUILDS = "/guilds";
export const USERS = "/users";

// GAME
export const ADVENTURERS_GUIDE = "/adventurers-guide";
export const CLASS_INTRODUCTION = "/class-introduction";
export const FEATURES = "/features";
export const HISTORY = "/history";
export const LORE = "/lore";
export const STATISTICS = "/statistics";
export const RULES = "/rules";

// WIKI
export const CLASSES = "/classes";
export const CRAFTING = "/crafting";
export const ITEMS = "/items";
export const MAPS = "/maps";
export const MONSTERS = "/monsters";
export const RACES = "/races";
export const SKILLS = "/skills";

// MULTIMEDIA
export const ARTWORKS = "/artworks";
export const DOWNLOADS = "/downloads";
export const SCREENSHOTS = "/screenshots";
export const SOUNDTRACKS = "/soundtracks";
export const VIDEOS = "/videos";
export const WALLPAPERS = "/wallpapers";

// SUPPORT
export const ADMIN = "/admin";
export const SUBMIT_TICKET = "/submit-ticket";
export const CONTACT = "/contact";
export const DEVELOPMENT = "/development";
export const FAQ = "/faq";
export const HELP = "/help";
export const SECURITY_CENTER = "/security-center";
