import React from "react";
import { useIntl } from "react-intl";
import { QueryResult } from "@apollo/client";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import { Spinner } from "@fhx/ui";
import i18n from "./ApolloLoadingContent.i18n";
import styles from "./ApolloLoadingContent.module.scss";

export interface IProps {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  queryResult: QueryResult<any>;
  renderer(queryResultData: unknown): React.ReactNode;
  spinnerWidth?: number;
  spinnerColor?: string;
  spinnerText?: string;
  errorMessage?: React.ReactNode;
  isLegacyStyle?: boolean;
}

export default function ApolloLoadingContent({
  queryResult,
  renderer,
  spinnerWidth = 16,
  spinnerColor = "white",
  spinnerText,
  errorMessage,
  isLegacyStyle = false,
}: IProps): React.ReactElement {
  const { formatMessage } = useIntl();
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  const { error: queryResultError, loading: isLoading, data: queryResultData } = queryResult;
  let content: React.ReactNode = (
    <div className={styles.spinnerWrapper}>
      {isLegacyStyle && (
        <>
          <FontAwesomeIcon icon={faSpinner} pulse style={{ fontSize: `${spinnerWidth}px`, color: spinnerColor }} />
          <br />
          {spinnerText}
        </>
      )}
      {!isLegacyStyle && <Spinner width={spinnerWidth} text={spinnerText} />}
    </div>
  );

  if (!isLoading) {
    if (queryResultError || !queryResultData) {
      if (errorMessage !== undefined) {
        content = errorMessage;
      } else if (queryResultError) {
        console.error(queryResultError.message);
        content = queryResultError.message;
      } else {
        content = <div className={styles.errorWrapper}>{formatMessage(i18n.error)}</div>;
      }
    } else {
      content = renderer(queryResultData);
    }
  }
  return <>{content}</>;
}
