import axios, { AxiosError } from "axios";
import { AUTH_SERVER_HOST } from "../../../../../../config";

interface IGameServer {
  id: number;
  name: string;
  minimumRequiredRoleToJoin: number;
}

interface ILoginServerGameServersResponse {
  data?: Array<IGameServer>;
  error?: unknown;
}

function getGameServers(): Promise<Array<IGameServer>> {
  return new Promise((resolve, reject) => {
    axios
      .get(`${AUTH_SERVER_HOST}/gameServers`)
      .then((response) => {
        const responseData = response.data as ILoginServerGameServersResponse;
        if (responseData.data) {
          resolve(responseData.data);
        } else {
          reject();
        }
      })
      .catch((error: AxiosError) => {
        if (error.response && error.response.status === 401) {
          resolve([]);
        } else {
          reject();
        }
      });
  });
}

export default function checkGameServerTriState(): Promise<"OK" | "ADEQUATE" | "BAD"> {
  return getGameServers()
    .then((gameServers) => {
      const gs1 = gameServers.find((server) => server.id === 1);
      if (gs1) return "OK";
      return "ADEQUATE";
    })
    .catch(() => {
      return "BAD";
    });
}
