import React, { useCallback } from "react";
import classNames from "classnames";
import { BorderedBox, Button, EButtonType } from "@fhx/ui";
import { Link, useNavigate } from "react-router-dom";
import { useIntl } from "react-intl";
import { REGISTER, LOGIN, HOME } from "../../../routes/paths";
import styles from "./ResponsiveMenu.module.scss";
import logo from "./img/fhx-logo.png";
import { useFooterLinks, useNavigation, useSocialLinks, useSupportedLanguages } from "../../../hooks";
import { ICommonLink, IFooterLink, INavigationEntry, ISocialLink } from "../../../interfaces";
import { WEBSITE_NAME, WEBSITE_TITLE, WEBSITE_VERSION } from "../../../config";
import commonI18n from "../../../i18n/common.i18n";
import { setDesiredLocale } from "../../../i18n";

function renderCommonLink(entry: ICommonLink, closeHandler?: () => void): React.ReactElement {
  const { id, route, label, onClick, target } = entry;
  const clickHandler = (): void => {
    if (onClick) onClick();
    if (closeHandler) closeHandler();
  };
  const attributes = {
    key: id,
    href: route,
    target,
    rel: "noopener noreferrer",
    onClick: clickHandler,
    className: styles.socialLink,
  };
  // eslint-disable-next-line react/jsx-props-no-spreading
  if (!route || target) return <a {...attributes}>{label}</a>;
  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <Link to={route} {...attributes}>
      {label}
    </Link>
  );
}

function renderNavigationEntry(entry: INavigationEntry, closeHandler?: () => void): React.ReactNode {
  const { children, label, route, isEnabled, onClick } = entry;
  if (isEnabled === false) return undefined;
  const hasSubEntries = children && children.length > 0;
  return (
    <li key={entry.id} className={classNames(hasSubEntries && styles.hasSubEntries)}>
      {route && (
        <Link
          to={route}
          onClick={(): void => {
            if (onClick) onClick();
            if (closeHandler) closeHandler();
          }}
        >
          {label}
        </Link>
      )}
      {!route && <label onClick={onClick}>{label}</label>}
      {children && hasSubEntries && <ul>{children.map((child) => renderNavigationEntry(child, closeHandler))}</ul>}
    </li>
  );
}

function renderFooterLink(entry: IFooterLink, closeHandler?: () => void): React.ReactElement {
  return renderCommonLink(entry, closeHandler);
}

function renderSocialLink(entry: ISocialLink, closeHandler?: () => void): React.ReactElement {
  return renderCommonLink(entry, closeHandler);
}

export interface IProps {
  className?: string;
  style?: React.CSSProperties;
  onCloseMenu?: () => void;
}

export default function ResponsiveMenu({ className, style, onCloseMenu }: IProps): React.ReactElement {
  const { formatMessage } = useIntl();
  const navigate = useNavigate();
  const [languages] = useSupportedLanguages();
  const [navigation] = useNavigation();
  const [footerLinks] = useFooterLinks();
  const [socialLinks] = useSocialLinks();

  const handleClose = useCallback(() => {
    if (onCloseMenu) onCloseMenu();
  }, [onCloseMenu]);

  return (
    <BorderedBox
      type="TOOLTIP"
      isGapless
      className={classNames(styles.menu, className)}
      contentClassName={styles.content}
      style={style}
    >
      <div className={styles.header}>
        <Button className={styles.closeBtn} type={EButtonType.LOBBY_EXIT} onClick={handleClose} />
        <div className={styles.logo}>
          <Link to={HOME} onClick={handleClose}>
            <img src={logo} alt={WEBSITE_TITLE} />
          </Link>
        </div>
      </div>
      <div className={styles.buttons}>
        <Button
          type={EButtonType.LAUNCHER_LOGIN}
          onClick={(): void => {
            navigate(LOGIN);
            handleClose();
          }}
        >
          {formatMessage(commonI18n.loginCapitalized)}
        </Button>
        <Button
          type={EButtonType.LAUNCHER_REGISTRATION}
          onClick={(): void => {
            navigate(REGISTER);
            handleClose();
          }}
        >
          {formatMessage(commonI18n.registerCapitalized)}
        </Button>
      </div>
      <div className={styles.navigation}>
        <ul>
          {navigation.filter((entry) => entry.id !== "home").map((entry) => renderNavigationEntry(entry, handleClose))}
        </ul>
      </div>
      <div className={styles.languages}>
        {languages.map((language) => (
          <a
            key={language.locale}
            onClick={(): void => {
              setDesiredLocale(language.locale);
              handleClose();
              window.location.reload();
            }}
          >
            <img src={language.iconSrc} alt="" />
          </a>
        ))}
      </div>
      <div className={styles.footer}>
        <div className={styles.social}>
          {socialLinks.filter((entry) => entry.isEnabled).map((entry) => renderSocialLink(entry, handleClose))}
        </div>
        <div className={styles.links}>
          {footerLinks.filter((entry) => entry.isEnabled).map((entry) => renderFooterLink(entry, handleClose))}
        </div>
        <div className={styles.version}>
          {WEBSITE_NAME} v{WEBSITE_VERSION}
        </div>
      </div>
    </BorderedBox>
  );
}
