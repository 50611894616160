import { defineMessages } from "react-intl";

export default defineMessages({
  error: {
    id: "content.errorBoundary.header.error",
    defaultMessage: "Error !",
    description: "",
  },
  textPt1: {
    id: "content.errorBoundary.text.pt1",
    defaultMessage: "We have caught an unexpected error illegally produced by a group of monkeys.",
    description: "",
  },
  textPt2: {
    id: "content.errorBoundary.text.pt2",
    defaultMessage: "The monkeys have been arrested and are now being lectured about the damage they have done to you.",
    description: "",
  },
  textPt3: {
    id: "content.errorBoundary.text.pt3",
    defaultMessage: "They apologize for the inconvenience and promise to avoid this in the future.",
    description: "",
  },
  reloadPage: {
    id: "content.errorBoundary.button.reloadPage",
    defaultMessage: "Reload Page",
    description: "",
  },
  home: {
    id: "content.errorBoundary.button.home",
    defaultMessage: "Home",
    description: "",
  },
  contact: {
    id: "content.errorBoundary.button.contact",
    defaultMessage: "Contact",
    description: "",
  },
});
