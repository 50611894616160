import React from "react";
import { BorderedBox } from "@fhx/ui";
import classNames from "classnames";
import styles from "./DefaultWindow.module.scss";

export interface IProps {
  children: React.ReactNode;
  header?: React.ReactNode;
  footer?: React.ReactNode;
  className?: string;
  contentClassName?: string;
  style?: React.CSSProperties;
}

export default function DefaultWindow({
  children,
  header,
  footer,
  className,
  contentClassName,
  style,
}: IProps): React.ReactElement {
  return (
    <BorderedBox type="PROMPT_WINDOW" className={classNames(styles.window, className)} style={style}>
      {header && <div className={styles.header}>{header}</div>}
      <div className={classNames(styles.content, contentClassName)}>{children}</div>
      {footer && <div className={styles.footer}>{footer}</div>}
    </BorderedBox>
  );
}
