import { IWebsiteCharacterRace } from "../../interfaces";
import { archer, summoner, warrior, wizard } from "../characterClasses";

const characterRace: IWebsiteCharacterRace = {
  id: "woodelf",
  isEnabled: true,
  i18nTag: {
    en: "We",
    de: "We",
  },
  i18nName: {
    en: "Wood-Elf",
    de: "Waldelf",
  },
  i18nDescription: {
    en: "Possessing a strong affinity with fairies and protected by the woods and forests, Wood-Elves are an agile species specializing in archery, wielding daggers, and sword fighting. They are the guardians of nature.",
    /*
    Wood-Elfes closely live together with their nature and make use of natural power.
    Often, their power is underrated concerning a great fight. With their ability Wood-Elfes
    can be doom of every enemy.
    */
    de: "Waldelfen sind mit den Feen verwandt und werden von den Wäldern beschützt. Sie sind flink und auf das Bogenschießen, das Schwingen von Dolchen und den Schwertkampf spezialisiert. Sie sind die Wächter der Natur.",
  },
  mainAttributesBases: {
    vitality: 7,
    dexterity: 12,
    intelligence: 11,
    strength: 8,
  },
  allowsCharacterClasses: [archer, summoner, warrior, wizard],
};

export default characterRace;
