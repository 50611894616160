import { defineMessages } from "react-intl";

export default defineMessages({
  description: {
    id: "resendVerificationMailWindow.description",
    defaultMessage:
      "If you have missed to verify your email address after registration or if you have an old account, we can re-send you the verification mail. All we need is your username or email address:",
    description: "",
  },
  notFoundDescription: {
    id: "resendVerificationMailWindow.notFoundDescription",
    defaultMessage:
      "We cannot find a not yet verified user with this name or email address. Please make sure that you have entered your correct username or email address.",
    description: "",
  },
  successDescription: {
    id: "resendVerificationMailWindow.successDescription",
    defaultMessage:
      "We have sent you an email with instructions on how to verify your email address. Please check your mailbox and spam folder.",
    description: "",
  },
  title: {
    id: "resendVerificationMailWindow.title",
    defaultMessage: "Re-Send Verification Mail",
    description: "",
  },
  usernameOrEmail: {
    id: "resendVerificationMailWindow.usernameOrEmail",
    defaultMessage: "Username or Email",
    description: "",
  },
  cancel: {
    id: "resendVerificationMailWindow.cancel",
    defaultMessage: "Cancel",
    description: "",
  },
  back: {
    id: "resendVerificationMailWindow.back",
    defaultMessage: "Back",
    description: "",
  },
  close: {
    id: "resendVerificationMailWindow.close",
    defaultMessage: "Close",
    description: "",
  },
  sendMail: {
    id: "resendVerificationMailWindow.sendMail",
    defaultMessage: "Send Mail",
    description: "",
  },
});
