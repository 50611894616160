import { defineMessages } from "react-intl";

export default defineMessages({
  description: {
    id: "resetPasswordWindow.description",
    defaultMessage:
      "You can now set a new password for your account. Choose a password that is strong enough and easy to remember. Both input fields must be identical:",
    description: "",
  },
  failDescription: {
    id: "resetPasswordWindow.failDescription",
    defaultMessage:
      "The password could not be reset. Probably the verification link has expired. Please re-initiate the whole process to reset your password.",
    description: "",
  },
  successDescription: {
    id: "resetPasswordWindow.successDescription",
    defaultMessage: "Your password has been changed successfully. You can now use your new login data to log in.",
    description: "",
  },
  title: {
    id: "resetPasswordWindow.title",
    defaultMessage: "Reset Password",
    description: "",
  },
  password: {
    id: "resetPasswordWindow.password",
    defaultMessage: "Password",
    description: "",
  },
  confirmPassword: {
    id: "resetPasswordWindow.confirmPassword",
    defaultMessage: "Confirm Password",
    description: "",
  },
  setPassword: {
    id: "resetPasswordWindow.setPassword",
    defaultMessage: "Set Password",
    description: "",
  },
  cancel: {
    id: "resetPasswordWindow.cancel",
    defaultMessage: "Cancel",
    description: "",
  },
  close: {
    id: "resetPasswordWindow.close",
    defaultMessage: "Close",
    description: "",
  },
});
