/* eslint-disable react/jsx-curly-brace-presence */
import React from "react";
import { IFaqEntry } from "../../interfaces";

const entries: Array<IFaqEntry> = [];
export default entries;

entries.push({
  question: "Oynanabilir mi?",
  answer: <>Hayır, ancak #duyurularda gelecekteki etkinlikler iCin bizi izlemeye devam edin.</>,
});
entries.push({ question: "Cevrimdısı/yalnız oynayabilir miyim?", answer: <>Hayır.</> });
entries.push({
  question: "Neden olmasın?",
  answer: (
    <>
      Bu asama, Cok az özelliği olan ve Cok az özelliği olan Cok dengesiz bir gelistirme sunucusu oturum aC.
      <br /> Cevre kurulumu teknik olmayan kullanıcılar iCin önemsiz değildir.
    </>
  ),
});
entries.push({
  question: "Ne zaman yayınlanacak?",
  answer: (
    <>
      Sabırlı olun, bu bir hobi projesi. <br />
      Halka ilk gizlice zirve sağlandı Aralık 2018, <br />, 2019’da daha fazla etkinlik iCin bizi izlemeye devam edin.
    </>
  ),
});
entries.push({ question: "Sunucuyu nereden indirebilirim?", answer: <>su anda yapamazsın.</> });
entries.push({
  question: "İstemcileri nereden indirebilirim?",
  answer: (
    <>
      Burada mevcut orijinal oyun istemcilerinin bir koleksiyonu var. <br />
      Eğer baska varsa müsteri sürümü lütfen benimle iletisime geCin. http://bit.ly/2ti5bAC
    </>
  ),
});

entries.push({
  question: "Yardım edebilir miyim?",
  answer: (
    <>
      Yazılım gelistirme veya ters mühendislik konularında deneyimli olmadığınız sürece Bu projeye zaman ayırmak, hayır.{" "}
      <br />
      Herhangi bir teknik olmayan yardıma ihtiyaC duyulduğunda,#general_english kanalında.
    </>
  ),
});
entries.push({
  question: "Nerede bağısta bulunabilirim?",
  answer: (
    <>
      Her türlü bağıs kabul etmiyoruz. <br />
      Finansal olarak kar amacı gütmek niyetinde değiliz. oyun.
    </>
  ),
});
entries.push({
  question: "Neye ihtiyacın var?",
  answer: (
    <>
      Yukarıdaki bağlantıdan farklı oyun istemcileriniz varsa (özellikle erken Korece sürümleri veya daha yeni bir
      Xiones istemcisi) lütfen bana ulasın. <br /> su anda daha fazla bir sey gerekli değil.
    </>
  ),
});
entries.push({ question: "Oyun Windows 10'da mı (64bit) Calısıyor mu?", answer: <>Evet.</> });
entries.push({ question: "Adımı rezerve edebilir miyim?", answer: <>Hayır.</> });
entries.push({
  question: "Herhangi bir güncelleme var mı?",
  answer: (
    <>
      Yeni bir sey varsa, @ Duyurularda @Moderator veya {"@Admin'de"} gösterilir. <br />
      Bu arada, su ana kadar yaptığımız ilerlemenin bir videosunu izleyin (sürekli olarak güncelleniyor): <br />
      <a href="http://bit.ly/2TJQnGo" target="_blank" rel="noopener noreferrer">
        http://bit.ly/2TJQnGo
      </a>
      <br />
      <a href="http://bit.ly/2URBYIe" target="_blank" rel="noopener noreferrer">
        http://bit.ly/2URBYIe
      </a>
      <br />
      veya <a href="https://fhxrestoration.com/"> https://fhxrestoration.com/ </a> adresinden veya ana sayfanıza bakın.
    </>
  ),
});
entries.push({
  question: "Bu proje ne hakkında?",
  answer: (
    <>
      Hedef, FHX (Ferentus, Herrcot, Xiones) sunucu emülatörünü gelistirerek gelistirmektir. orijinal oyun sunucusu gibi
      davranan orijinal oyun istemcileri. <br />
      Bunu yapmak iCin oyunun ağ protokolünü tersine Cevirir ve yeni bir iletisim kurarız. Oyun iCin katman.
      <br />
      Uygulanacak sunucu tarafında da Cok fazla mantık var (NPC). kontrol, spawns, teleports, hareket, envanter, ürün,
      karakter, giris, ..). <br /> Neyse ki, oyun istemcisinin kendisinde depolanan oyun hakkında birCok bilgi var.{" "}
      <br /> su anda {"V1'e"} odaklanıyoruz, Cünkü oyunun ilk (kullanılabilir) tekrarı. daha sonraki sürümlere göre daha
      basit ağ protokolü, ancak diğer oyun sürümleri gibi görünüyor Sunucu tarafında Cok benzer sekilde Calısırız, bu
      nedenle Coğu istemci sürümünde Coğu seyi desteklemekteyiz. an
    </>
  ),
});
entries.push({
  question: "Mevcut durum nedir?",
  answer: <>Zaman zaman buradaki ilerleme, anlasmazlık ve Youtube kanalı.</>,
});
entries.push({
  question: "#Git-feed kanalı ne hakkında?",
  answer: (
    <>
      Git, Coğunlukla kaynak kod değisikliklerini takip etmek iCin gelistirici tarafından kullanılan bir araCtır. Her
      değisiklik (taahhüt), neyin değistiğini iCeren bir aCıklama iCerir. Bu kanalda otomatik olarak bir bot sizi güncel
      tutmak iCin bu taahhüt mesajlarını yayınlar; böylece aktif olduğumuzu görebilir ve kabaca ne yapıyoruz. <br />
      Oyunu orijinali gibi Calısacak sekilde nasıl yeniden kurar ve dengelersiniz? Öğeler, {"NPC'ler"} ve becerilerle
      ilgili tüm orijinal değerlere sahibiz. <br /> Kesin mücadele hesaplamalar hala aCık ve orijinal dame kullanılarak
      yapılması ve doğrulanması gerekiyor veri (ekran görüntüleri, videolar, günlükler).
    </>
  ),
});
entries.push({
  question: "Oyuna yeni esyalar, beceriler, haritalar ve NPC ekleyebilir misiniz?",
  answer: (
    <>
      su anda yeni modeller veya animasyonlar olusturamıyoruz, ancak mevcut olanı tekrar kullanın ve değistirin
      istatistikler
    </>
  ),
});
entries.push({
  question: "Oyuna yeni yarıslar / sınıflar ekleyebilir misin?",
  answer: (
    <>
      Hayır. Belki kısmen uygulanan ırklardan (insan, cüce, ..) faydalanabiliriz, ama biz Model ve animasyon yok. Ayrıca
      oyun tamamen değisecek.
    </>
  ),
});
entries.push({ question: "Yeni Cözünürlükleri / en boy oranlarını destekler misiniz?", answer: <>Evet.</> });
entries.push({ question: "Bir XYZ videosu yayınlayabilir misiniz?", answer: <>Bize mesaj at. ;-)</> });
entries.push({
  question: "Nasıl basladın?",
  answer: (
    <>
      Bu Discord kanalını kurduk ve bu yıl Temmuz ayında birlikte Calısmaya basladık. @Wildriff ağ protokolünü tersine
      Cevirmek misyonunda Cok katıydı. Ama olmadı @AcarX (bu oyunu oynamayan harici bir adam) bize yardımcı olana kadar
      Ağ protokolünün temellerini anlayın ve bize bir kavram kanıtı sağladı. daha az ters mühendislik becerisi ile
      üstlenebileceğimiz uygulama. BirkaC diğerleri uzun yıllar Coğunlukla bağımsız olarak farklı bölgelerinde Calısmaya
      basladı. oyun (@Robb (rbb138)) 2014 yılında harita formatının bölümlerini Cözdü, @ b4ttl3m4st3r (Tobi) Oyunun
      metin versiyonunda Calıstı ve oyun hakkında birCok bilgi edindi. ve @Marcel (Cynicer), {"2012'den"} beri .tbl
      (oyun veri dosyaları) üzerinde Calısıyordu. <br />
      dürüst olmak gerekirse, son birkaC ayın en Cok ilerlemesiyle).
    </>
  ),
});
