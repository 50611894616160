import {
  REGISTRATION_USERNAME_VALID_REGEX,
  REGISTRATION_USERNAME_LENGTH_MIN,
  REGISTRATION_USERNAME_LENGTH_MAX,
} from "../../config";

export default function validateUsername(username: string): boolean {
  if (username.length < REGISTRATION_USERNAME_LENGTH_MIN) return false;
  if (username.length > REGISTRATION_USERNAME_LENGTH_MAX) return false;
  if (!REGISTRATION_USERNAME_VALID_REGEX.test(username)) return false;
  return true;
}
