import { defineMessages } from "react-intl";

export default defineMessages({
  announcements: {
    id: "window.announcements.announcements",
    defaultMessage: "Announcements",
    description: "",
  },

  noResults: {
    id: "window.announcements.noResults",
    defaultMessage: "noResults",
    description: "No results",
  },
});
