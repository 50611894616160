import { sendGetRequest } from "./helpers";
import { ILoginServerResponse, IUser } from "./interfaces";

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface IRequestBody {}

export type IResponseBody = ILoginServerResponse<IUser>;

export default function user(username: string, token: string): Promise<IResponseBody | undefined> {
  return sendGetRequest<IRequestBody, IResponseBody>(
    `/users/${username}`,
    {},
    { withCredentials: true, headers: { Authorization: `Bearer ${token}` } },
  );
}
