import IFeatureTogglesContext from "./IFeatureTogglesContext";
import EFeatureToggleId from "./EFeatureToggleId";

const featureToggles = new Map<EFeatureToggleId, boolean>();

// ACCOUNT
featureToggles.set(EFeatureToggleId.ACCOUNT_LOGIN, false);
featureToggles.set(EFeatureToggleId.ACCOUNT_REGISTRATION, true);

// PAGES: ACCOUNT
featureToggles.set(EFeatureToggleId.PAGE_ACCOUNT_LOGIN, true);
featureToggles.set(EFeatureToggleId.PAGE_ACCOUNT_REGISTRATION, true);

// PAGES: ANNOUNCEMENTS
featureToggles.set(EFeatureToggleId.PAGE_ANNOUNCEMENTS_NOTICES, false);
featureToggles.set(EFeatureToggleId.PAGE_ANNOUNCEMENTS_UPDATES, false);
featureToggles.set(EFeatureToggleId.PAGE_ANNOUNCEMENTS_EVENTS, false);
featureToggles.set(EFeatureToggleId.PAGE_ANNOUNCEMENTS_GM_NOTES, false);

// PAGES: COLLECTIONS
featureToggles.set(EFeatureToggleId.PAGE_COLLECTIONS_CHARACTERS, false);
featureToggles.set(EFeatureToggleId.PAGE_COLLECTIONS_GUILDS, false);
featureToggles.set(EFeatureToggleId.PAGE_COLLECTIONS_USERS, true);

// PAGES: GAME
featureToggles.set(EFeatureToggleId.PAGE_GAME_ADVENTURERS_GUIDE, false);
featureToggles.set(EFeatureToggleId.PAGE_GAME_CLASS_INTRODUCTION, false);
featureToggles.set(EFeatureToggleId.PAGE_GAME_FEATURES, false);
featureToggles.set(EFeatureToggleId.PAGE_GAME_HISTORY, false);
featureToggles.set(EFeatureToggleId.PAGE_GAME_LORE, true);
featureToggles.set(EFeatureToggleId.PAGE_GAME_STATISTICS, true);
featureToggles.set(EFeatureToggleId.PAGE_GAME_RULES, true);

// PAGES: LEGAL
featureToggles.set(EFeatureToggleId.PAGE_LEGAL_EULA, true);
featureToggles.set(EFeatureToggleId.PAGE_LEGAL_IMPRINT, false);
featureToggles.set(EFeatureToggleId.PAGE_LEGAL_PRIVACY_POLICY, true);
featureToggles.set(EFeatureToggleId.PAGE_LEGAL_TOS, false);

// PAGES: WIKI
featureToggles.set(EFeatureToggleId.PAGE_WIKI_CLASSES, false);
featureToggles.set(EFeatureToggleId.PAGE_WIKI_CRAFTING, false);
featureToggles.set(EFeatureToggleId.PAGE_WIKI_ITEMS, false);
featureToggles.set(EFeatureToggleId.PAGE_WIKI_MAPS, false);
featureToggles.set(EFeatureToggleId.PAGE_WIKI_MONSTERS, false);
featureToggles.set(EFeatureToggleId.PAGE_WIKI_RACES, true);
featureToggles.set(EFeatureToggleId.PAGE_WIKI_SKILLS, false);

// PAGES: MULTIMEDIA
featureToggles.set(EFeatureToggleId.PAGE_MULTIMEDIA_ARTWORKS, true);
featureToggles.set(EFeatureToggleId.PAGE_MULTIMEDIA_DOWNLOADS, true);
featureToggles.set(EFeatureToggleId.PAGE_MULTIMEDIA_SCREENSHOTS, true);
featureToggles.set(EFeatureToggleId.PAGE_MULTIMEDIA_WALLPAPERS, true);
featureToggles.set(EFeatureToggleId.PAGE_MULTIMEDIA_VIDEOS, false);
featureToggles.set(EFeatureToggleId.PAGE_MULTIMEDIA_SOUNDTRACKS, false);

// PAGES: SUPPORT
featureToggles.set(EFeatureToggleId.PAGE_SUPPORT_ADMIN, true);
featureToggles.set(EFeatureToggleId.PAGE_SUPPORT_SUBMIT_TICKET, true);
featureToggles.set(EFeatureToggleId.PAGE_SUPPORT_CONTACT, false);
featureToggles.set(EFeatureToggleId.PAGE_SUPPORT_DEVELOPMENT, true);
featureToggles.set(EFeatureToggleId.PAGE_SUPPORT_FAQ, true);
featureToggles.set(EFeatureToggleId.PAGE_SUPPORT_HELP, true);
featureToggles.set(EFeatureToggleId.PAGE_SUPPORT_SECURITYCENTER, true);

const initialState: Pick<IFeatureTogglesContext, "featureToggles"> = {
  featureToggles,
};

export default initialState;
