import { gql } from "@apollo/client";
import { IAnnouncement, IAnnouncementFilter, IAnnouncementI18n, IAnnouncementTagI18n } from "@fhx/api-interfaces";

export const QUERY = gql`
  query getAnnouncements($filter: AnnouncementFilter, $limit: Float) {
    announcements(filter: $filter, limit: $limit, orderBy: [CREATED_AT_DESC]) {
      id
      title
      text
      createdAt
      tags {
        id
        title
        i18n {
          id
          title
        }
      }
      i18n {
        id
        title
      }
    }
  }
`;

export interface IPickedAnnouncementTag extends Pick<IAnnouncement, "id" | "title"> {
  i18n: Array<Pick<IAnnouncementTagI18n, "id" | "locale" | "title">>;
}

export interface IPickedAnnouncement extends Pick<IAnnouncement, "id" | "title" | "text" | "createdAt"> {
  tags: Array<IPickedAnnouncementTag>;
  i18n: Array<Pick<IAnnouncementI18n, "id" | "title">>;
}

export interface IQueryVariables {
  filter?: IAnnouncementFilter;
  limit?: number;
}

export interface IQueryResultData {
  announcements: Array<IPickedAnnouncement>;
}
