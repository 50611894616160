import { IWebsiteCharacterClass } from "../../interfaces";
import { ECharacterStrengthMeter } from "../../enums";
import defaultAttributMultipliers from "./defaultAttributMultipliers";

const summoner: IWebsiteCharacterClass = {
  id: "summoner",
  isEnabled: true,
  i18nTag: {
    en: "Sn",
    de: "Be",
  },
  i18nName: {
    en: "Summoner",
    de: "Beschwörer",
  },
  roles: ["Melee damage", "Tank", "Enemy control", "Support"],
  i18nDescription: {
    en: "Summoners specialize in spiritualistic magic inherited from ancient times. They summon guardians to fight and protect casters.",
    /*
    Summoners are the experts of spiritualistic magic inherited from ancient times. They summon guardians to fight and protect casters.
    It is also possible that their knowledge to elemntal and other magic can daze and dazzle opponents.
    They can also cast additional buff magic to colleagues.
    If summoners are to be one of your enemies, then you should not underestimate the contract between them and other classes.
    */
    de: "Beschwörer sind auf spirituelle Magie aus alten Zeiten spezialisiert. Sie rufen Wächter zusammen, die kämpfen und Magier beschützen.",
  },
  strengthMeters: [
    { id: ECharacterStrengthMeter.ATTACK, value: 50 },
    { id: ECharacterStrengthMeter.DEFENSE, value: 50 },
    { id: ECharacterStrengthMeter.PARTY, value: 20 },
    { id: ECharacterStrengthMeter.SOLO, value: 80 },
  ],
  difficulty: "HARD",
  offensive: "MEDIUM",
  defense: "MEDIUM",
  preferredWeapons: ["sword"],
  preferredArmor: ["jacket", "pants", "helmet", "gloves", "boots", "cape"],
  preferredAccessoires: ["ring", "necklace"],
  classSkillModels: [],
  classSkillModelsV2: [],
  classSkillComboModels: [],
  classSkillComboModelsV2: [],
  attributeMultipliers: defaultAttributMultipliers,
};

export default summoner;
