import { sendPostRequest } from "./helpers";

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface IRequestBody {}

// eslint-disable-next-line @typescript-eslint/ban-types
export type IResponseBody = {};

export default function logout(): Promise<IResponseBody | undefined> {
  return sendPostRequest<IRequestBody, IResponseBody>("/logout", {}, { withCredentials: true });
}
