import React from "react";
import { IFaqEntry } from "../../interfaces";

const entries: Array<IFaqEntry> = [];
export default entries;

entries.push({ question: "Já pode jogar?", answer: <>Não, mas fique atento a eventos futuros em #announcements.</> });
entries.push({ question: "Posso jogar offline / sozinho?", answer: <>Não.</> });
entries.push({
  question: "Por que não?",
  answer: (
    <>
      Nesta fase, é um servidor de desenvolvimento muito instável, com muito poucos recursos e sem login. <br /> A
      configuração do ambiente não é trivial para usuários não técnicos.
    </>
  ),
});
entries.push({
  question: "Quando o jogo será lançado?",
  answer: (
    <>
      Seja paciente, é um projeto de lazer. <br />
      O primeiro teste ao público foi fornecido em dezembro de 2018, <br /> fique ligado para mais eventos em 2019.
    </>
  ),
});
entries.push({ question: "Onde posso baixar o servidor?", answer: <>Você não pode no momento.</> });
entries.push({
  question: "Onde posso baixar o(s) cliente(s)?",
  answer: (
    <>
      Há uma coleção de clientes de jogos originais disponíveis aqui. <br />
      Se você tiver qualquer outra versão do cliente, entre em contato. http://bit.ly/2ti5bAC
    </>
  ),
});
entries.push({
  question: "Posso ajudar?",
  answer: (
    <>
      A menos que você tenha experiência em desenvolvimento de software ou engenharia reversa e esteja disposto a
      investir tempo nesse projeto, não. <br />
      Se for necessária alguma ajuda não técnica, ela será solicitada no canal #general_english.
    </>
  ),
});
entries.push({
  question: "Onde posso doar?",
  answer: (
    <>
      NÃO aceitamos doações de qualquer tipo. <br />
      NÃO pretendemos lucrar financeiramente com o jogo.
    </>
  ),
});
entries.push({
  question: "O que você precisa?",
  answer: (
    <>
      Se você tiver clientes de jogos diferentes dos do link acima (especialmente versões coreanas anteriores ou um
      cliente Xiones mais recente), entre em contato. <br /> No momento não há mais nada necessário.
    </>
  ),
});
entries.push({ question: "O jogo roda no Windows 10 (64 bits)?", answer: <>Sim.</> });
entries.push({ question: "Posso reservar meu nome?", answer: <>Não.</> });
entries.push({
  question: "Existem atualizações?",
  answer: (
    <>
      Se houver algo novo, isso será mostrado em #announcements por @Moderator ou @Admin. <br />
      Enquanto isso, assista a um vídeo do progresso que fizemos até o momento (atualizado continuamente): <br />
      <a href="http://bit.ly/2TJQnGo" target="_blank" rel="noopener noreferrer">
        http://bit.ly/2TJQnGo
      </a>
      <br />
      <a href="http://bit.ly/2URBYIe" target="_blank" rel="noopener noreferrer">
        http://bit.ly/2URBYIe
      </a>
      <br />
      ou confira a página inicial em <a href="https://fhxrestoration.com/"> https://fhxrestoration.com/ </a>
    </>
  ),
});
entries.push({
  question: "Sobre o que é esse projeto?",
  answer: (
    <>
      O objetivo é desenvolver um emulador de servidor FHX (Ferentus, Herrcot, Xiones) para trabalhar com os clientes do
      jogo original, que se comporta exatamente como o servidor do jogo original. <br />
      Para fazer isso, fazemos engenharia reversa do protocolo de rede do jogo e implementamos uma nova camada de
      comunicação para o jogo. <br />
      Também há muita lógica no lado do servidor a ser implementada (controle de NPC, spawns, teleports, movimento,
      inventário, itens, personagem, login, etc.). Felizmente, existem muitas informações sobre o jogo armazenadas no
      próprio cliente do jogo. No momento, focamos na V1, porque é a primeira versão (disponível) do jogo com um
      protocolo de rede mais simples do que as versões posteriores, mas parece que as outras versões de jogos funcionam
      de maneira muito semelhante no servidor, por isso apoiamos mais coisas na maioria das versões de clientes no
      momento.
    </>
  ),
});
entries.push({
  question: "Qual é o estado atual?",
  answer: <>Periodicamente, você será atualizado sobre o progresso aqui, no Discord e em nosso canal do YouTube.</>,
});
entries.push({
  question: "Sobre o que é o canal #git-feed?",
  answer: (
    <>
      O Git é uma ferramenta usada principalmente pelo desenvolvedor para acompanhar as alterações no código-fonte. Cada
      alteração (confirmação) inclui uma descrição do que foi alterado. Nesse canal, um bot automaticamente publica
      essas mensagens de confirmação para mantê-lo atualizado, para que você possa ver que estamos ativos e o que
      estamos fazendo aproximadamente. <br />
      Como você reconstrói e equilibra o jogo para que ele funcione exatamente como o original? Temos todos os valores
      originais em relação aos itens, NPCs e habilidades. <br /> Os cálculos exatos de combate ainda estão abertos e
      precisam ser elaborados e verificados usando dados originais de dame (capturas de tela, vídeos, registros).
    </>
  ),
});
entries.push({
  question: "Você pode adicionar novos itens, habilidades, mapas e NPCs ao jogo?",
  answer: (
    <>
      Atualmente, não podemos criar novos modelos ou animações, mas sim reutilizar um existente e alterar as
      estatísticas.
    </>
  ),
});
entries.push({
  question: "Você pode adicionar novas raças / classes ao jogo?",
  answer: (
    <>
      Não. Talvez possamos fazer uso das raças parcialmente implementadas (humanas, anãs, etc.), mas não temos modelos e
      animações. Também mudaria o jogo completamente.
    </>
  ),
});
entries.push({ question: "Você suportará novas resoluções / proporções?", answer: <>Sim.</> });
entries.push({ question: "Você pode postar um vídeo de XYZ?", answer: <>Envie-nos uma mensagem. ;-)</> });
entries.push({
  question: "Como tudo começou?",
  answer: (
    <>
      Fundamos este canal Discord e começamos a trabalhar juntos em julho deste ano. @Wildriff foi muito rígido em sua
      missão de fazer engenharia reversa no protocolo de rede. Mas não decolou até que o @AcarX (um cara externo, que
      não jogou esse jogo) nos ajudou a entender o básico do protocolo de rede e nos forneceu uma implementação de prova
      de conceito, que poderíamos assumir com menos habilidades de engenharia reversa. Vários outros começaram a
      trabalhar muitos anos atrás, principalmente de forma independente em diferentes partes do game (@Robb (rbb138))
      descobriu partes do formato do mapa em 2014, @ b4ttl3m4st3r (Tobi) trabalhou na versão em texto do jogo e extraiu
      muitas informações para o jogo e @Marcel (Cynicer) estava trabalhando no .tbl (arquivos de dados do jogo) desde
      2012, <br />
      com mais progresso nos últimos meses para ser honesto.
    </>
  ),
});
