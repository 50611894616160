enum ECharacterStrengthMeter {
  ATTACK = "ATTACK",
  DEFENSE = "DEFENSE",
  PARTY = "PARTY",
  SOLO = "SOLO",
  // RESISTANCE = "RESISTANCE",
  // ENDURANCE = "ENDURANCE",
  // EVASION = "EVASION",
  // SUPPORT = "SUPPORT",
  // MOBILITY = "MOBILITY",
  // SPEED = "SPEED",
}

export default ECharacterStrengthMeter;
