import { INotificationMeta } from "./contexts";

declare global {
  interface Window {
    ENV?: Record<string, unknown>;
  }
}

function getWindowEnvVar<T = unknown>(id: string): T | undefined {
  return window.ENV ? (window.ENV[id] as T) : undefined;
}

// Hosts
export const WEBSITE_HOST = "https://fhxrestoration.com";
export const GAME_SERVER_HOST = "https://gs1.fhxrestoration.com";
export const API_SERVER_HOST = "https://api.fhxrestoration.com";
export const API_SERVER_GRAPHQL = `${API_SERVER_HOST}/graphql`;
export const AUTH_SERVER_HOST = "https://auth.fhxrestoration.com/v2";
export const PATCH_SERVER_HOST = "https://patch.fhxrestoration.com/v2";
export const API_GRAPHQL_SUBSCRIPTIONS_TIMEOUT_MS = 5000;

// Temporary endpoints
/** @deprecated */ export const TEMP_CHARACTER_RANKINGS_ENDPOINT = `${API_SERVER_HOST}/v1/characterRankings`;

// Environment
export const APP_NAME = process.env.REACT_APP_NAME;
export const APP_VERSION = process.env.REACT_APP_VERSION;
export const APP_COMMIT_SHA = process.env.REACT_APP_COMMIT_SHA;
export const APP_IS_UNDER_MAINTENANCE = getWindowEnvVar<boolean>("APP_IS_UNDER_MAINTENANCE") || false;
export const STATIC_NOTIFICATIONS = getWindowEnvVar<Array<INotificationMeta>>("STATIC_NOTIFICATIONS") || [];

// Website
export const WEBSITE_TITLE = "FHX Restoration";
export const WEBSITE_NAME = "FHX Restoration Website";
export const WEBSITE_VERSION = APP_VERSION;
export const LOGIN_SERVER_RECAPTCHA_KEY = "6LdJDvwUAAAAABuE5g2oTew1xN4E9dj1iGGBf-Cy";
export const IS_WINTER_THEME_ENABLED = false;

/**
 * @deprecated Should be replaced by auth-server permissions later.
 * Used to show/hide specific UI elements which rely on auth-server endpoints
 * that are currently only accessible when a user has at least a specific permLvl.
 * */
export const MINIMUM_PERMLVL_TO_BE_GM = 10;

/** @deprecated Temporarily limits the login feature to a subset of users */
export const ALLOWED_USERNAMES_FOR_LOGIN = [
  "cypress",
  "cynicer",
  "wildriff",
  "lucas",
  "rbb138",
  "avigal",
  "avigaltest",
  "avigaltest2",
  "Daniel",
  "b4ttl3m4st3r",
  "b4ttl3m4st3r2",
];

// Game Launch
export const LAUNCH_GAME_URL = (authToken: string): string => {
  const originName = WEBSITE_NAME;
  const gameServerHost = "gs1.fhxrestoration.com";
  return `
  fhx://launchGame?originName=${originName}&gameServerHost=${gameServerHost}&authToken=${authToken}
  `;
};

// Social
export const DISCORD_URL = "https://discordapp.com/invite/M2KkfEK";
export const YOUTUBE_URL = "https://www.youtube.com/user/CynicerSH";
export const GITLAB_URL = "https://gitlab.com/fhx";
export const FACEBOOK_URL = "https://www.facebook.com/fhx.onlines/posts"; // "https://www.facebook.com/FHXRestoration";
export const TWITCH_URL = "https://www.twitch.tv/search?term=FHX%20Restoration&type=videos";
export const TWITTER_URL = "https://twitter.com/fhxonline";

// Registration
export const REGISTRATION_USERNAME_VALID_REGEX = new RegExp(/^[0-9a-z]+$/);
export const REGISTRATION_USERNAME_LENGTH_MIN = 3;
export const REGISTRATION_USERNAME_LENGTH_MAX = 20;
export const REGISTRATION_PASSWORD_LENGTH_MIN = 8;
export const REGISTRATION_PASSWORD_LENGTH_MAX = 30;
export const SUBMIT_TICKET_CHARACTER_LENGTH_MIN = 3;
export const SUBMIT_TICKET_CHARACTER_LENGTH_MAX = 20;
export const SUBMIT_TICKET_NAME_VALID_REGEX = new RegExp(/^[0-9a-zA-Z]+$/);
