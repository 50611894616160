import { IWebsiteCharacterClass } from "../../interfaces";
import { ECharacterStrengthMeter } from "../../enums";
import defaultAttributMultipliers from "./defaultAttributMultipliers";

const knight: IWebsiteCharacterClass = {
  id: "knight",
  isEnabled: true,
  i18nTag: {
    en: "Kn",
    de: "Ri",
  },
  i18nName: {
    en: "Knight",
    de: "Ritter",
  },
  roles: ["Tank", "Count Control", "Pulling of mobs"],
  i18nDescription: {
    en: "A knight is a class that has achieved formal military training and is assigned to the regiment of Royal Knights.",
    /*
    Knights were trained in the art of war and belong to the regiment of the king's knights. They consider it as their noble duty to guard the weak.
    They prefer defence to attack. At the same time they lead the party and protect other members.
    The knights need much VIT to take even the highest damage. But he also needs STR to wear big and heavy armour.
    */
    de: "Ein Ritter ist eine Klasse, die ein offizielles Militärtraining abgeschlossen hat und dem Regiment der Königsritter zugewiesen wurde.",
  },
  strengthMeters: [
    { id: ECharacterStrengthMeter.ATTACK, value: 10 },
    { id: ECharacterStrengthMeter.DEFENSE, value: 100 },
    { id: ECharacterStrengthMeter.PARTY, value: 90 },
    { id: ECharacterStrengthMeter.SOLO, value: 20 },
  ],
  difficulty: "EASY",
  offensive: "LOW",
  defense: "HIGHT",
  preferredWeapons: ["sword", "shield"],
  preferredArmor: ["jacket", "pants", "helmet", "gloves", "boots", "cape"],
  preferredAccessoires: ["ring", "necklace"],
  classSkillModels: [],
  classSkillModelsV2: [],
  classSkillComboModels: [],
  classSkillComboModelsV2: [],
  attributeMultipliers: defaultAttributMultipliers,
};

export default knight;
