import React, { useCallback, useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { Button, EButtonType, Spinner } from "@fhx/ui";
import axios from "axios";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheckCircle, faTimesCircle } from "@fortawesome/free-solid-svg-icons";
import classNames from "classnames";
import styles from "./ResetPasswordWindow.module.scss";
import i18n from "./ResetPasswordWindow.i18n";
import { AUTH_SERVER_HOST } from "../../../../config";
import { Input } from "../../../common";
import { verifyEmailTokenSyntax, validatePassword } from "../../../../helpers";
import DefaultWindow from "../../DefaultWindow/DefaultWindow";

type IStatus = "IDLE" | "LOADING" | "SUCCESS" | "FAILED";

export interface IProps {
  token: string;
  onClose?: () => void;
  className?: string;
  style?: React.CSSProperties;
}

// eslint-disable-next-line complexity
export default function ResetPasswordWindow({ token, onClose, className, style }: IProps): React.ReactElement {
  const { formatMessage } = useIntl();
  const [password, setPassword] = useState<string>("");
  const [confirmPassword, setConfirmPassword] = useState<string>("");
  const [status, setStatus] = useState<IStatus>("IDLE");

  const sendRequest = useCallback(async (): Promise<void> => {
    setStatus("LOADING");
    await axios
      .post(`${AUTH_SERVER_HOST}/changePassword`, { emailToken: token, password })
      .then(() => {
        setStatus("SUCCESS");
      })
      .catch(() => {
        setStatus("FAILED");
      });
  }, [password, token]);

  const validateForm = useCallback((): boolean => {
    if (password === "" || confirmPassword === "") return false;
    if (!validatePassword(password)) return false;
    if (password !== confirmPassword) return false;
    return true;
  }, [confirmPassword, password]);

  const renderIsLoading = useCallback((): React.ReactElement => {
    return <Spinner className={styles.spinner} />;
  }, []);

  const handleCancel = useCallback((): void => {
    if (onClose) onClose();
  }, [onClose]);

  const renderSuccess = useCallback((): React.ReactNode => {
    return (
      <>
        <div className={classNames(styles.statusIcon, styles.ok)}>
          <FontAwesomeIcon icon={faCheckCircle} />
        </div>
        <div className={styles.description}>{formatMessage(i18n.successDescription)}</div>
      </>
    );
  }, [formatMessage]);

  function renderFailed(): React.ReactNode {
    return (
      <>
        <div className={classNames(styles.statusIcon, styles.error)}>
          <FontAwesomeIcon icon={faTimesCircle} />
        </div>
        <div className={styles.description}>{formatMessage(i18n.failDescription)}</div>
      </>
    );
  }

  const handleSubmit = useCallback(() => {
    if (validateForm()) {
      sendRequest().catch((error) => console.error(error));
    }
  }, [sendRequest, validateForm]);

  let footer = <></>;
  switch (status) {
    case "LOADING":
      footer = <></>;
      break;
    case "FAILED":
    case "SUCCESS":
      footer = (
        <Button type={EButtonType.CN} onClick={handleCancel} className={styles.submitButton}>
          {formatMessage(i18n.close)}
        </Button>
      );
      break;
    case "IDLE":
      footer = (
        <>
          <Button type={EButtonType.PVP_INFO} onClick={handleCancel} className={styles.submitButton}>
            {formatMessage(i18n.cancel)}
          </Button>
          <Button
            type={EButtonType.CN}
            onClick={handleSubmit}
            className={styles.submitButton}
            isDisabled={!validateForm()}
          >
            {formatMessage(i18n.setPassword)}
          </Button>
        </>
      );
      break;
    default:
      throw new Error(`Unknown status '${JSON.stringify(status)}'.`);
  }

  useEffect(() => {
    if (!verifyEmailTokenSyntax(token)) {
      setStatus("FAILED");
    }
  }, [token]);

  return (
    <DefaultWindow
      header={formatMessage(i18n.title)}
      footer={footer}
      className={classNames(styles.window, className)}
      style={style}
    >
      {status === "SUCCESS" && renderSuccess()}
      {status === "LOADING" && renderIsLoading()}
      {status === "FAILED" && renderFailed()}
      {status === "IDLE" && (
        <>
          <div className={styles.description}>{formatMessage(i18n.description)}</div>
          <Input
            type="password"
            tint={password === "" || !validatePassword(password) ? "error" : undefined}
            label={formatMessage(i18n.password)}
            placeholder={`${formatMessage(i18n.password)} ...`}
            value={password}
            onChange={(event): void => {
              setPassword(event.target.value);
            }}
            className={styles.input}
          />
          <Input
            type="password"
            tint={confirmPassword === "" || password !== confirmPassword ? "error" : undefined}
            label={formatMessage(i18n.confirmPassword)}
            placeholder={`${formatMessage(i18n.confirmPassword)} ...`}
            value={confirmPassword}
            onChange={(event): void => {
              setConfirmPassword(event.target.value);
            }}
            wrapperClassName={styles.inputWrapper}
          />
        </>
      )}
    </DefaultWindow>
  );
}
