import React from "react";
import { Link } from "react-router-dom";
import classNames from "classnames";
import styles from "./Logo.module.scss";
import logo from "./img/fhx-logo-blue.png";
import { HOME } from "../../../../../routes/paths";
import { WEBSITE_TITLE } from "../../../../../config";

export interface IProps {
  className?: string;
  style?: React.CSSProperties;
}

export default function Logo({ className, style }: IProps): React.ReactElement {
  return (
    <div className={classNames(styles.logo, className)} style={style}>
      <Link to={HOME}>
        <img src={logo} alt={WEBSITE_TITLE} />
      </Link>
    </div>
  );
}
