import { defineMessages } from "react-intl";

export default defineMessages({
  // General
  downloadCapitalized: {
    id: "common.account.downloadCapitalized",
    defaultMessage: "Download",
    description: "",
  },

  // Dates
  yearCapitalized: {
    id: "common.dates.yearCapitalized",
    defaultMessage: "Year",
    description: "",
  },

  // Account
  loginCapitalized: {
    id: "common.account.loginCapitalized",
    defaultMessage: "Log In",
    description: "",
  },
  logoutCapitalized: {
    id: "common.account.logoutCapitalized",
    defaultMessage: "Log out",
    description: "",
  },
  registerCapitalized: {
    id: "common.account.registerCapitalized",
    defaultMessage: "Register",
    description: "",
  },
});
