import { IWebsiteCharacterRace } from "../../interfaces";
import { cleric, paladin, summoner, warrior } from "../characterClasses";

const characterRace: IWebsiteCharacterRace = {
  id: "dwarf",
  isEnabled: false,
  i18nTag: {
    en: "Df",
    de: "Zw",
  },
  i18nName: {
    en: "Dwarf",
    de: "Zwerg",
  },
  i18nDescription: {
    en: "Mining, creating, and refinement. A blacksmith is what best describes this race. Their knowledge on weapons and armor are valuable in battles.",
    de: "",
  },
  mainAttributesBases: {
    vitality: 10,
    dexterity: 8,
    intelligence: 9,
    strength: 11,
  },
  allowsCharacterClasses: [cleric, paladin, summoner, warrior],
};

export default characterRace;
