import React from "react";
import classNames from "classnames";
import { useIntl } from "react-intl";
import { SnowAnimation } from "@fhx/ui";
import { Header, Content, Footer, BackgroundVideo, NotificationsArea, ModalsArea } from "../../layout";
import styles from "./DefaultView.module.scss";
import { IS_WINTER_THEME_ENABLED } from "../../../config";
import { useSupportedLanguages } from "../../../hooks";

export interface IProps {
  className?: string;
  style?: React.CSSProperties;
}

export default function DefaultView({ className, style }: IProps): React.ReactElement {
  const { locale } = useIntl();
  const [languages] = useSupportedLanguages();
  const found = languages.find((entry) => entry.locale === locale);
  const isRtl = found && found.isRtl;

  return (
    <div className={classNames(styles.view, className)} dir={isRtl ? "rtl" : undefined} style={style}>
      <BackgroundVideo className={styles.backgroundVideo} />
      <div className={styles.headerContentFooterWrapper}>
        {IS_WINTER_THEME_ENABLED && <SnowAnimation snowflakeCount={100} className={styles.snowAnimation} />}
        <Header />
        <NotificationsArea />
        <ModalsArea />
        <Content className={styles.content} />
        <Footer className={styles.footer} />
      </div>
    </div>
  );
}
