/* eslint-disable react/jsx-props-no-spreading */
import React, { useCallback, useRef } from "react";
import classNames from "classnames";
import ReactTooltip from "react-tooltip";
import styles from "./TextArea.module.scss";

export interface IProps extends React.InputHTMLAttributes<HTMLTextAreaElement> {
  label?: React.ReactNode;
  tint?: "ok" | "error" | "warning";
  tooltipId?: string;
  tooltipContent?: React.ReactNode;
  wrapperClassName?: string;
}

export default function TextArea({
  label,
  tint,
  tooltipId,
  tooltipContent,
  wrapperClassName,
  ...args
}: IProps): React.ReactElement {
  const textAreaRef = useRef<HTMLTextAreaElement>(null);

  const focus = useCallback((): void => {
    if (textAreaRef.current) textAreaRef.current.focus();
  }, []);

  return (
    <div className={classNames(styles.wrapper, tint && styles[tint], wrapperClassName)} style={args.style}>
      {label && (
        <label onClick={focus} className={styles.label}>
          {label}
        </label>
      )}
      <textarea ref={textAreaRef} data-tip data-for={tooltipId} {...args} className={styles.textarea} />
      {tooltipContent && (
        <ReactTooltip
          id={tooltipId}
          type="light"
          place="right"
          effect="solid"
          event="keydown"
          eventOff="blur"
          arrowColor="#888"
          className={styles.tooltip}
        >
          <div className={styles.tooltipContent}>{tooltipContent}</div>
        </ReactTooltip>
      )}
    </div>
  );
}
