import React from "react";
import classNames from "classnames";
import ReactPlayer, { ReactPlayerProps } from "react-player";
import styles from "./ResponsiveVideo.module.scss";

export interface IProps extends ReactPlayerProps {
  className?: string;
  style?: React.CSSProperties;
}

export default function ResponsiveVideo({ className, style, ...props }: IProps): React.ReactElement {
  return (
    <div className={classNames(styles.responsiveVideo, className)} style={style}>
      <div className={classNames(styles.videoWrapper)}>
        <ReactPlayer
          controls
          playing
          light
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...props}
          className={styles.video}
          url="https://www.youtube.com/watch?v=VUwjEyKAT_I"
          width="100%"
          height="100%"
        />
      </div>
    </div>
  );
}
