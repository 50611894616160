import { IWebsiteCharacterRace } from "../../interfaces";

const characterRace: IWebsiteCharacterRace = {
  id: "rogue",
  isEnabled: false,
  i18nTag: {
    en: "Ro",
    de: "Sc",
  },
  i18nName: {
    en: "Rogue",
    de: "Schurke",
  },
  i18nDescription: {
    en: "",
    de: "",
  },
  mainAttributesBases: {
    vitality: 0,
    dexterity: 0,
    intelligence: 0,
    strength: 0,
  },
  allowsCharacterClasses: [],
};

export default characterRace;
