import React, { useContext } from "react";
import { useIntl } from "react-intl";
import classNames from "classnames";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUserCircle, faSignInAlt, faUser } from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";
import { Button, EButtonType } from "@fhx/ui";
import styles from "./AccountControls.module.scss";
import { IfUserIsLoggedIn, IfUserIsLoggedOut, ModalsContext, UserContext } from "../../../../../contexts";
import { REGISTER, USERS } from "../../../../../routes/paths";
import { ASSETS_INTERFACE_ICONS } from "../../../../../assets";
import i18n from "./AccountControls.i18n";
import { LoginWindow, LogOutWindow } from "../../../../windows";
import EModalEntryId from "../../../../../EModalEntryId";

export interface IProps {
  className?: string;
  style?: React.CSSProperties;
}

export default function AccountControls({ className, style }: IProps): React.ReactElement {
  const { formatMessage } = useIntl();
  const { user, isGm } = useContext(UserContext);
  const { push: pushModal, removeById: removeModalById } = useContext(ModalsContext);
  const profileRoute = `${USERS}/${user?.username || "undefined"}`;
  return (
    <div className={classNames(styles.accountControls, className)} style={style}>
      <IfUserIsLoggedOut>
        <div className={styles.loggedOutOptions}>
          <a
            onClick={(): number =>
              pushModal({
                id: EModalEntryId.LOGIN_MODAL,
                content: <LoginWindow onClose={(): number => removeModalById(EModalEntryId.LOGIN_MODAL)} />,
              })
            }
          >
            <FontAwesomeIcon icon={faSignInAlt} />
            {formatMessage(i18n.logIn)}
          </a>
          <Link to={REGISTER}>
            <FontAwesomeIcon icon={faUser} />
            {formatMessage(i18n.register)}
          </Link>
        </div>
      </IfUserIsLoggedOut>
      <IfUserIsLoggedIn>
        <div className={styles.accountInfo}>
          <Link to={profileRoute}>
            {isGm && (
              <img
                src={`${ASSETS_INTERFACE_ICONS}/GM00.gif`}
                alt={formatMessage(i18n.gm)}
                title={formatMessage(i18n.gm)}
                className={styles.gmIcon}
              />
            )}
            <FontAwesomeIcon icon={faUserCircle} className={styles.icon} />
            <div className={styles.username}>{user?.username || "undefined"}</div>
          </Link>
        </div>
        <div className={styles.loggedInOptions}>
          <Button
            type={EButtonType.BILLING_CHARGING}
            onClick={(): number =>
              pushModal({
                id: EModalEntryId.LOGOUT_MODAL,
                content: <LogOutWindow onClose={(): number => removeModalById(EModalEntryId.LOGOUT_MODAL)} />,
              })
            }
          >
            {formatMessage(i18n.logOut)}
          </Button>
        </div>
      </IfUserIsLoggedIn>
    </div>
  );
}
