/* eslint-disable react/no-unescaped-entities */
/* eslint-disable react/jsx-curly-brace-presence */
import React from "react";
import classNames from "classnames";
import styles from "./EulaWindow.module.scss";
import DefaultWindow from "../../DefaultWindow/DefaultWindow";

export interface IProps {
  className?: string;
  style?: React.CSSProperties;
}

export default function EulaWindow({ className, style }: IProps): React.ReactElement {
  return (
    <DefaultWindow
      header="End-User License Agreement"
      footer={
        <>
          This EULA was created by{" "}
          <a href="https://www.eulatemplate.com" target="_blank" rel="noopener noreferrer">
            EULA Template
          </a>
          .
        </>
      }
      className={classNames(styles.window, className)}
      style={style}
    >
      <p>
        This End-User License Agreement ({'"EULA"'}) is a legal agreement between you and FHX Restoration ({'"Company"'}
        ). It governs your acquisition and use of our software and/or website ({'"Software"'}) directly from us or
        indirectly through a companie's authorized reseller or distributor ({'"Reseller"'}).
      </p>

      <p>
        Please read this EULA carefully before completing any registration/installation process and using the Software.
        It provides a license to use the Software and contains warranty information and liability disclaimers.
      </p>

      <p>
        By installing and/or using the Software, you are confirming your acceptance of the Software and agreeing to
        become bound by the terms of this EULA.
      </p>

      <p>
        If you are entering into this EULA on behalf of a company or other legal entity, you represent that you have the
        authority to bind such entity and its affiliates to these terms and conditions. If you do not have such
        authority or if you do not agree with the terms and conditions of this EULA, do not install or use the Software,
        and you must not accept this EULA.
      </p>

      <p>
        This EULA shall apply only to the Software supplied by the Company herewith, regardless of whether other
        software is referred to or described herein. The terms also apply to any of the company's updates, supplements,
        Internet-based services, and support services for the Software, unless other terms accompany those items on
        delivery. If so, those terms apply.
      </p>

      <h1>License Grant</h1>

      <p>
        The compan hereby grants you a personal, non-transferable, non-exclusive licence to use the Software on your
        devices in accordance with the terms of this EULA.
      </p>

      <p>
        You are permitted to load the Software (for example a PC, laptop, mobile or tablet) under your control. You are
        responsible for ensuring your device meets the minimum requirements of the Software.
      </p>

      <p>You are not permitted to:</p>
      <ul>
        <li>
          Edit, alter, modify, adapt, translate or otherwise change the whole or any part of the Software nor permit the
          whole or any part of the Software to be combined with or become incorporated in any other software, nor
          decompile, disassemble or reverse engineer the Software or attempt to do any such things
        </li>
        <li>Reproduce, copy, distribute, resell or otherwise use the Software for any commercial purpose</li>
        <li>Allow any third party to use the Software on behalf of or for the benefit of any third party</li>
        <li>Use the Software in any way which breaches any applicable local, national or international law</li>
        <li>use the Software for any purpose that the company considers is a breach of this EULA</li>
      </ul>

      <h1>Intellectual Property and Ownership</h1>

      <p>
        The company shall at all times retain ownership of the Software as originally downloaded by you and all
        subsequent downloads of the Software by you. The Software (and the copyright, and other intellectual property
        rights of whatever nature in the Software, including any modifications made thereto) are and shall remain the
        property of the company.
      </p>

      <p>The company reserves the right to grant licences to use the Software to third parties.</p>

      <h1>Termination</h1>

      <p>
        This EULA is effective from the date you first use the Software and shall continue until terminated. You may
        terminate it at any time upon written notice to the company.
      </p>

      <p>
        It will also terminate immediately if you fail to comply with any term of this EULA. Upon such termination, the
        licenses granted by this EULA will immediately terminate and you agree to stop all access and use of the
        Software. The provisions that by their nature continue and survive will survive any termination of this EULA.
      </p>

      <h1>Governing Law</h1>

      <p>
        This EULA, and any dispute arising out of or in connection with this EULA, shall be governed by and construed in
        accordance with the laws of us.
      </p>
    </DefaultWindow>
  );
}
