import React, { useCallback } from "react";
import classNames from "classnames";
import { BorderedBox, Button, EButtonType } from "@fhx/ui";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInfoCircle, faCheck, faExclamationTriangle } from "@fortawesome/free-solid-svg-icons";
import { INotificationMeta, INotificationType } from "../../../../../contexts";
import styles from "./Notification.module.scss";

function renderIconByType(type: INotificationType): React.ReactNode {
  switch (type) {
    case "INFO":
      return <FontAwesomeIcon icon={faInfoCircle} className={styles.icon} />;
    case "OK":
      return <FontAwesomeIcon icon={faCheck} className={styles.icon} />;
    case "WARNING":
      return <FontAwesomeIcon icon={faExclamationTriangle} className={styles.icon} />;
    case "ERROR":
      return <FontAwesomeIcon icon={faExclamationTriangle} className={styles.icon} />;
    default:
      throw new Error(`Unknown notification type '${JSON.stringify(type)}'.`);
  }
}

export interface IProps {
  meta: INotificationMeta;
  onClose?: () => void;
  className?: string;
  style?: React.CSSProperties;
}

export default function Notification({ meta, onClose, className = "", style }: IProps): React.ReactElement {
  const { type, title, content, isClosable } = meta;
  const isTitlelessAndClosable = title === undefined && isClosable;
  const handleOnClose = useCallback((): void => {
    if (onClose) onClose();
  }, [onClose]);

  return (
    <BorderedBox
      type="TOOLTIP"
      className={classNames(
        styles.notification,
        styles[type || "INFO"],
        isTitlelessAndClosable && styles.isTitlelessAndClosable,
        className,
      )}
      contentClassName={styles.contentWrapper}
      style={style}
    >
      {isClosable && <Button type={EButtonType.WINDOW_CLOSE} className={styles.closeBtn} onClick={handleOnClose} />}
      {title && (
        <div className={styles.iconAndTitle}>
          {renderIconByType(type || "INFO")}
          <div className={styles.title}>{title}</div>
        </div>
      )}
      <div className={styles.content}>{content}</div>
    </BorderedBox>
  );
}
