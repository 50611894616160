import { IWebsiteCharacterClass } from "../../interfaces";
import { ECharacterStrengthMeter } from "../../enums";
import defaultAttributMultipliers from "./defaultAttributMultipliers";

const paladin: IWebsiteCharacterClass = {
  id: "paladin",
  isEnabled: true,
  i18nTag: {
    en: "Pa",
    de: "Pa",
  },
  i18nName: {
    en: "Paladin",
    de: "Paladin",
  },
  roles: ["Tank", "Enemy control", "Support"],
  i18nDescription: {
    en: "Paladins are the class that learned holy magic from holy knights - the Hollen Horsemanship of Rog - and use their strength to maintain the goodness of their beliefs.",
    /*
    Paladins once learned the holy magic from the holy knights of the Hellriders of Rog. They put their power to the service of justice and their faith.
    They control the same magic as clerics do, their fighting abilities can almost compete with those of warriors and they all but defend like knights.
    Many a battle decides to the benefit of Paladins.
    The Paladin does not have a main status value because he needs STR and VIT for fighting, INT for spellcasting and DEX for endurance.
    He can't concentrate on one status value, the best thing is to raise them all evenly.
    */
    de: "Die Paladin-Klasse lernte die Magie von heiligen Rittern - den heiligen Rittern von Rog - und setzt ihre Kraft dafür ein, ihren Glauben zu verteidigen.",
  },
  strengthMeters: [
    { id: ECharacterStrengthMeter.ATTACK, value: 30 },
    { id: ECharacterStrengthMeter.DEFENSE, value: 70 },
    { id: ECharacterStrengthMeter.PARTY, value: 40 },
    { id: ECharacterStrengthMeter.SOLO, value: 40 },
  ],
  difficulty: "MEDIUM",
  offensive: "MEDIUM",
  defense: "HIGHT",
  preferredWeapons: ["hammer", "wand", "shield"],
  preferredArmor: ["jacket", "pants", "helmet", "gloves", "boots", "cape"],
  preferredAccessoires: ["ring", "necklace"],
  classSkillModels: [],
  classSkillModelsV2: [],
  classSkillComboModels: [],
  classSkillComboModelsV2: [],
  attributeMultipliers: defaultAttributMultipliers,
};

export default paladin;
