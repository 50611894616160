import React, { useCallback } from "react";
import classNames from "classnames";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  IconDefinition,
  faExclamationTriangle,
  faInfoCircle,
  faCode,
  faBalanceScaleRight,
  faBullhorn,
  faSpider,
} from "@fortawesome/free-solid-svg-icons";
import styles from "./NewsScroll.module.scss";
import INewsScrollEntry, { INewsScrollEntryType } from "./INewsScrollEntry";

interface IFooterLink extends React.AnchorHTMLAttributes<HTMLAnchorElement> {
  label: React.ReactNode;
}

function getShortTextForEntry(origText: string, maxLength = 200): string {
  if (origText.length <= maxLength) return origText;
  return `${origText.substring(0, maxLength)} ...`;
}

function getFaIconForType(type: INewsScrollEntryType): IconDefinition {
  switch (type) {
    case "INFO":
      return faInfoCircle;
    case "EVENT":
      return faBullhorn;
    case "WARNING":
      return faExclamationTriangle;
    case "DEVELOPMENT":
      return faCode;
    case "BALANCING":
      return faBalanceScaleRight;
    case "BOSS":
      return faSpider;
    default:
      throw new Error(`No corresponding icon for IEntryType '${JSON.stringify(type)}' `);
  }
}

export interface IProps {
  entries: Array<INewsScrollEntry>;
  entryTextMaxLength?: number;
  header?: React.ReactNode;
  footerLinks?: Array<IFooterLink>;
  className?: string;
  style?: React.CSSProperties;
}

export default function NewsScroll({
  entries,
  entryTextMaxLength = 200,
  header,
  footerLinks,
  className = "",
  style,
}: IProps): React.ReactElement {
  const renderEntry = useCallback(
    (entry: INewsScrollEntry) => {
      const { id, date, title, text, type } = entry;
      const faIcon = getFaIconForType(type || "INFO");
      return (
        <div key={id} className={classNames(styles.entry, type && styles[type])}>
          <FontAwesomeIcon icon={faIcon} className={styles.icon} />
          <div className={styles.content}>
            <div className={styles.title}>
              <span className={styles.date}>({date.toLocaleDateString()}) </span>
              {title}
            </div>
            <div className={styles.text}>{getShortTextForEntry(text as string, entryTextMaxLength)}</div>
          </div>
        </div>
      );
    },
    [entryTextMaxLength],
  );

  const renderFooterLink = useCallback((link: IFooterLink) => {
    // eslint-disable-next-line react/jsx-props-no-spreading
    return <a {...link}>{link.label}</a>;
  }, []);

  return (
    <div className={classNames(styles.scroll, className)} style={style}>
      {header && <div className={styles.header}>{header}</div>}
      <div className={styles.entries}>{entries.map(renderEntry)}</div>
      <div className={styles.footer}>{footerLinks?.map(renderFooterLink)}</div>
    </div>
  );
}
