import { ASSETS_HOST_REL_THUMBNAIL_PATH } from "../../assets";

export default function getThumbnailUrlForImageUrl(imageUrl: string): string {
  const url = new URL(imageUrl);
  const { pathname } = url;
  const dir = `${pathname.substring(0, pathname.lastIndexOf("/"))}/`;
  const file = pathname.substring(pathname.lastIndexOf("/") + 1);
  url.pathname = `${dir}${ASSETS_HOST_REL_THUMBNAIL_PATH}/${file}`;
  return url.toString();
}
