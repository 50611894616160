import { sendPostRequest } from "./helpers";
import { IUser, ILoginServerResponse } from "./interfaces";

export interface IRequestBody {
  username: string;
  password: string;
}

export type IResponseBody = ILoginServerResponse<{
  user: Pick<IUser, "id" | "username" | "permLvl">;
  authToken: string;
  authTokenExpiresIn: number;
}>;

export default function login(data: IRequestBody): Promise<IResponseBody | undefined> {
  return sendPostRequest<IRequestBody, IResponseBody>("/login", data, { withCredentials: true });
}
