/* eslint-disable react/jsx-curly-brace-presence */
import React from "react";
import { IFaqEntry } from "../../interfaces";

const entries: Array<IFaqEntry> = [];
export default entries;

entries.push({
  question: "האם ניתן לשחק?",
  answer: "לא, אבל עקבו אחרי עדכונים בדיסקורד בערוץ announcements# או באתר שלנו.",
});

entries.push({
  question: "האם יש עדכונים?",
  answer: (
    <>
      אם יש משהו חדש הוא יפורסם ב- announcements# בדיסקורד על ידי חבר צוות ויפורסם באתר אתר אינטרנט. בינתיים צפו בכמה
      סרטונים של ההתקדמות שעשינו עד כה (מעדכנים באופן קבוע):
      <br />
      <a href="http://bit.ly/2TJQnGo" target="_blank" rel="noopener noreferrer">
        http://bit.ly/2TJQnGo
      </a>
      <br />
      <a href="http://bit.ly/2URBYIe" target="_blank" rel="noopener noreferrer">
        http://bit.ly/2URBYIe
      </a>
    </>
  ),
});

entries.push({
  question: "מה המצב הנוכחי של הפרויקט?",
  answer: <>אנחנו נעדכן אותכם מעת לעת לגבי ההתקדמות בדיסקורד בערוץ announcements#, בערוץ ה- YouTube ובאתר שלנו.</>,
});

entries.push({
  question: "מתי המשחק ישוחרר?",
  answer: (
    <>
      היו סבלניים, זה פרויקט שנעשה כתחביב.
      <br />
      איוונט ההצצה הראשון שלנו שנפתח לכולם היה בדצמבר 2018.
      <br />
      הישארו מעודכנים על מנת לקבל מידע לגבי האיוונטים הבאים.
    </>
  ),
});

entries.push({
  question: "האם אפשר לשחק במצב לא מקוון / לבד?",
  answer: "לא.",
});

entries.push({
  question: "האם אפשרי לשמור את שמי במשחק?",
  answer: "לא.",
});

entries.push({
  question: "היכן אפשר להוריד את השרת?",
  answer: <>לא אפשרי מאחר והפרויקט הוא לא פרויקט קוד פתוח(עדיין).</>,
});

entries.push({
  question: "היכן ניתן להוריד את הקליינט?",
  answer: (
    <>
      בקישור המצורף נמצאים אוסף הגרסאות השונות של הקליינטים שניתנים להורדה,
      <br />
      אם יש לכם גרסה אחרת שלא נמצאת פה אנא צרו איתנו קשר.
      <br />
      <a href="http://bit.ly/2URBYIe" target="_blank" rel="noopener noreferrer">
        http://bit.ly/2ti5bAC
      </a>
    </>
  ),
});

entries.push({
  question: "האם ניתן לעזור?",
  answer: <>אלא אם אתם מנוסים בפיתוח תוכנה או reverse engineering ומוכנים להשקיע מזמנכם בפרויקט, לא.</>,
});

entries.push({
  question: "האם אפשר לתרום?",
  answer: (
    <>
      אנחנו לא מקבלים תרומות בשום דרך!
      <br />
      אנחנו לא מתכוונים לעשות שום רווח פיננסי מהמשחק!
    </>
  ),
});

entries.push({
  question: "מה אתם צריכים?",
  answer: (
    <>
      אם יש לכם גרסאות שונות של קליינטים שלא נמצאות בלינק שצירפנו למעלה (במיוחד בגרסאות המוקדמות בשפה הקוראינית או
      בגרסאות המתקדמות של Xiones) בבקשה צרו איתנו קשר. כרגע אין שום צורך במשהו אחר.
    </>
  ),
});

entries.push({
  question: "האם משחק רץ על ווינדוס 10 (64 ביט)?",
  answer: "כן.",
});

entries.push({
  question: "מהן דרישות המערכת למשחק?",
  answer: "כל מחשב שתומך ב-64 ביט מהעשר שנים האחרונים כנראה נתמך, המשחק הוא ישן למדי",
});

entries.push({
  question: "על מה הפרויקט הזה?",
  answer: (
    <>
      המטרה היא לפתח שרת אמולטור ל-FHX (Ferentus, Herrcot Xiones) על מנת שיעבוד עם הקליינט המקורי של המשחק, וזאת על מנת
      שיתפקד בידיוק כמו השרת המקורי של המשחק. על מנת לעשות זאת ביצעו reverse engineering על פרוטוקול הרשת של המשחק
      והטמענו שכבת תקשורת חדשה למשחק. יש הרבה לוגיקה להטמיע בצד השרת (ניהול מפלצות, חפצים, שחקנים, התחברות, השתגרויות,
      שליטה בשחקן,...). למרבה המזל יש מידע רב על המשחק שנמצא בקליינט עצמו.
      <br />
      אנחנו משתמשים בקליינט המקורי ולא מתכוונים לפתח אותו מחדש (שזה פרויקט גדול בפני עצמו). עם זאת, כאשר אנחנו מוצאים
      באגים בקליינט אנחנו מנסים לתקן אותם. זה מאוד קשה לנו משום שאין לנו את קוד המקור של הקליינט ואנחנו נאלצים לעדכן
      אותו בעזרת assembler.
    </>
  ),
});

entries.push({
  question: "איזו גרסה של המשחק תהיה זמינה? V1 או V3?",
  answer: (
    <>
      בתור התחלה התבססנו על הגרסה V1. ככל שהתקדמנו פיענחנו (באופן חלקי) קבצים שונים של הקליינט (מפות וכדומה) ולבסוף
      הוספנו את כל התוכן של V1 לגרסת V3(Xiones). כעת יש לנו שילוב של שתי הגרסאות {'"V4"'}, שמכיל כל המידע של שתי הגרסאות
      בקליינט אחד.
      <br />
      כרגע אנחנו עובדים על לייצר גרסה שבה שני העולמות יהיו זמינים בו זמנית ויהיה פורטל המקשר ביניהם. זה רק רעיון כללי
      ועשוי להשתנות בעתיד. מכיוון שיש לנו שחקנים מגרסאות שונות אנחנו רוצים שיהיה לנו מה להציע לכולכם.
    </>
  ),
});

entries.push({
  question: "מה זה הערוץ git-feed# בדיסקורד?",
  answer: (
    <>
      Git הוא כלי המשמש בעיקר את המפתחים כדי לעקוב אחר שינויי קוד בפרוייקט. כל שינוי (Commit) כולל תיאור של מה השתנה.
      בערוץ הזה יש בוט שמפרסם אוטומטית את ההודעות (מה- Commits) על מנת להשאיר אותכם מעודכנים לגבי כמה אנחנו פעילים ומה
      באופן כללי קורה.
    </>
  ),
});

entries.push({
  question: "איך אתם בונים מחדש ומאזנים את המשחק כך שיעבוד כמו המשחק המקורי?",
  answer: (
    <>
      יש לנו את כל הערכים המקוריים לגבי חפצים, מפלצות ומיומנויות. החישובים עבור מערכת הלחימה עדיין לא פוענחו סופית
      ודורשים עוד עבודה ווידוא עם המידע שיש לנו מהמשחק המקורי (תמונות, סרטונים, לוגים).
    </>
  ),
});

entries.push({
  question: "האם אתם יכולים להוסיף חפצים, מתקפות, מפלצות ומפות חדשות במשחק?",
  answer: <>נכון לעכשיו אנחנו לא יכולים להוסיף מודלים או אנימציות חדשות, אבל כן אפשרי להשתמש במה שקיים ולשנות אותו.</>,
});

entries.push({
  question: "האם אתם יכולים להוסיף סוגים חדשים של דמויות במשחק?",
  answer: (
    <>
      לא. אולי נוכל להשתמש בדמויות שהוטמעו באופן חלקי במשחק(human, dwarf,...) אבל אין לנו שום מודלים ואנימציות. בנוסף זה
      ישנה את המשחקיות של המשחק לחלוטין.
    </>
  ),
});

entries.push({
  question: "האם תתמכו ברזולוציות חדשות?",
  answer: "כן.",
});

entries.push({
  question: "האם תוכלו לפרסם סרטון/תמונה של משהו מסוים?",
  answer: "צרו איתנו קשר. ;-)",
});

entries.push({
  question: "איך הכל התחיל?",
  answer: (
    <>
      מצאנו את ערוץ הדיסקורד הזה והתחלנו לעבוד יחד ביולי 2018. Wildriff היה חדור מטרה במשימה שלו לבצע reverse
      engineering מחדש לפרוטוקול הרשת של המשחק, אך הוא לא הצליח עד שאדם בשם AcarX (מישהו שמעולם לא שיחק במשחק) עזר לנו
      להבין את הבסיס של פרוטוקולי הרשת של המשחק וסיפק לנו מידע כיצד ניתן ליישם זאת וכך יכולנו להמשיך בפרוייקט מבלי יותר
      מידי ידע וניסיון ב-reverse engineering. כמה אנשים אחרים ניסו לעבוד על המשחק במשך המון שנים בעבר, לדוגמה Robbie
      פיענח את הפורמט של המפות בשנת 2014. b4ttl3m4st3r פיתח גירסת טקסט עבור המשחק וחילץ הרבה מידע על המשחק ו- Cynicer
      עבד על קבצים מסוג .tbl (המכילים מידע על המשחק) מאז 2012. מאותו היום אנחנו עובדים בצורה מתמשכת על מנת להחזיר את
      המשחק בחזרה לחיים.
    </>
  ),
});
