import React, { useState } from "react";
import { BorderedBox } from "@fhx/ui";
import ReactImageCarousel, { ModalGateway, Modal as ReactImageModal, ViewType as OrigViewType } from "react-images";
import classNames from "classnames";
import styles from "./ImageGallery.module.scss";
import { getThumbnailUrlForImageUrl } from "../../../helpers";

interface ViewType extends OrigViewType {
  filename: string;
  source: {
    download?: string;
    fullscreen?: string;
    regular: string;
    thumbnail?: string;
  };
}

function getViewTypeForImgSrc(imgSrc: string): ViewType {
  const filename = imgSrc.replace(/^.*[\\/]/, "");
  const thumbnailUrl = getThumbnailUrlForImageUrl(imgSrc);
  return {
    filename,
    caption: filename,
    source: {
      regular: imgSrc,
      thumbnail: thumbnailUrl,
      download: imgSrc,
      fullscreen: imgSrc,
    },
  };
}

export interface IProps {
  entries: Array<string>;
  className?: string;
  entryBoxContentClassName?: string;
  style?: React.CSSProperties;
}

export default function ImageGallery({
  entries,
  className,
  entryBoxContentClassName,
  style,
}: IProps): React.ReactElement {
  const viewTypeEntries = entries.map(getViewTypeForImgSrc);
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [currentIndex, setCurrentIndex] = useState<number>(0);

  return (
    <>
      <ModalGateway>
        {isModalOpen && (
          <ReactImageModal onClose={(): void => setIsModalOpen(false)} allowFullscreen>
            <ReactImageCarousel views={viewTypeEntries} currentIndex={currentIndex} />
          </ReactImageModal>
        )}
      </ModalGateway>
      <div className={classNames(styles.entries, className)} style={style}>
        {viewTypeEntries.map((entry, index) => {
          const { source, filename } = entry;
          const { regular } = source;
          return (
            <BorderedBox
              key={regular}
              type="MAIN_2"
              contentClassName={classNames(styles.entryBoxContent, entryBoxContentClassName)}
            >
              <a
                key={regular}
                href={`#${source.regular}`}
                onClick={(): void => {
                  setCurrentIndex(index);
                  setIsModalOpen(true);
                }}
              >
                <img src={source.thumbnail || source.regular} alt={filename} />
              </a>
            </BorderedBox>
          );
        })}
      </div>
    </>
  );
}
