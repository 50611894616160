import {
  IItem,
  EItemRarity,
  ERequiredGenderToUseItem,
  EItemGlowing,
  IItemModel,
  ELegacyEquipmentSlotType,
} from "@fhx/interfaces";

const model: IItemModel = {
  id: 2352345,
  itemType: {
    id: "staff",
    category: {
      id: "weapon",
      i18nName: {
        en: "Weapon",
        de: "Waffe",
      },
      isRandomizeable: true,
      types: [],
    },
    i18nName: {
      en: "Staff",
      de: "Stab",
    },
  },
  game3DModelFileName: "",
  iconFileName: "ex_Wood_Staff.gif",
  iconSizeX: 2,
  iconSizeY: 3,
  isForBothHands: true,
  legacyEquipSlotType: ELegacyEquipmentSlotType.BOTH_HANDS,
  legacyIsMeele: true,
  isTradeable: true,
  particularity: undefined,
  requiredClassesStr: "",
  requiredRacesStr: "",
  name: "Mocked Staff",
  stackSizeMax: 1,
  soundDragFileName: "wood_icon.wav",
  refinementLevelMax: 10,
  soundFileName: "",
  usableByCharacterRaces: [],
  usableByCharacterClasses: [],
  requiredGender: ERequiredGenderToUseItem.ANY,
};

const item: IItem = {
  model,
  posX: 1,
  posY: 1,
  id: 12345345,
  magicOpts: [],
  repairedCount: 0,
  attackSpeed: 42,
  avoidanceTimeDecrement: 0,
  castingTimeDecrement: 0,
  description: "",
  durability: 50,
  durabilityMax: 100,
  glowing: EItemGlowing.RED,
  isIdentified: true,
  phyAttkMin: 10,
  phyAttkMax: 20,
  magAttkMin: 50,
  magAttkMax: 100,
  phyDef: 20,
  magDef: 24,
  manaBonus: 0,
  namePrefix: "",
  nameSuffix: "",
  price: 34534535,
  rarity: EItemRarity.RARE,
  refinementLevel: 0,
  requiredLevel: 5,
  requiredDexterity: 10,
  requiredIntelligence: 20,
  requiredStrength: 30,
  stackSize: 1,
  weaponRange: 0,
  createdAt: new Date(),
  updatedAt: new Date(),
  deletedAt: undefined,
};

export default item;
