import React from "react";
import { Link } from "react-router-dom";
import classNames from "classnames";
import styles from "./Footer.module.scss";
import { WEBSITE_TITLE, WEBSITE_NAME, WEBSITE_VERSION } from "../../../config";
import { useFooterLinks, useSocialLinks } from "../../../hooks";
import { ICommonLink, IFooterLink, ISocialLink } from "../../../interfaces";

function renderCommonLink(entry: ICommonLink): React.ReactElement {
  const { id, route, label, onClick, target } = entry;
  const attributes = {
    key: id,
    href: route,
    target,
    rel: "noopener noreferrer",
    onClick,
    className: styles.socialLink,
  };
  // eslint-disable-next-line react/jsx-props-no-spreading
  if (!route || target) return <a {...attributes}>{label}</a>;
  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <Link to={route} {...attributes}>
      {label}
    </Link>
  );
}

function renderSocialLink(entry: ISocialLink): React.ReactElement {
  return renderCommonLink(entry);
}

function renderFooterLink(entry: IFooterLink): React.ReactElement {
  return renderCommonLink(entry);
}

export interface IProps {
  className?: string;
  style?: React.CSSProperties;
}

export default function Footer({ className, style }: IProps): React.ReactElement {
  const [footerLinks] = useFooterLinks();
  const [socialLinks] = useSocialLinks();

  return (
    <footer className={classNames(styles.footer, className)} style={style}>
      <div className={styles.content}>
        <div className={styles.row}>
          <div className={styles.brand}>{WEBSITE_TITLE}</div>
          <div className={styles.buttons}>{socialLinks.filter((entry) => entry.isEnabled).map(renderSocialLink)}</div>
        </div>
        <div className={styles.row}>
          <div className={styles.links}>{footerLinks.filter((entry) => entry.isEnabled).map(renderFooterLink)}</div>
        </div>
        <div className={styles.row}>
          <div className={styles.version}>
            {WEBSITE_NAME} v{WEBSITE_VERSION}
          </div>
          {/* <div className={styles.copyright}>©{WEBSITE_TITLE}. All rights reserved.</div> */}
        </div>
      </div>
    </footer>
  );
}
