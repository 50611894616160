import React, { lazy } from "react";

function sleeper<T = unknown>(ms: number): (x: T) => Promise<T> {
  return (x: T): Promise<T> => {
    return new Promise((resolve) => setTimeout(() => resolve(x), ms));
  };
}

// HOME
export const HomePage = lazy(() => import("../components/pages/HomePage/HomePage"));

// ACCOUNT
export const LoginPage = lazy(() => import("../components/pages/account/LoginPage/LoginPage"));
export const RegisterPage = lazy(() => import("../components/pages/account/RegisterPage/RegisterPage"));
export const UserBankPage = lazy(() => import("../components/pages/account/UserBankPage/UserBankPage"));
export const UserCharactersPage = lazy(
  () => import("../components/pages/account/UserCharactersPage/UserCharactersPage"),
);
export const UserFriendlistPage = lazy(
  () => import("../components/pages/account/UserFriendlistPage/UserFriendlistPage"),
);
export const UserLogsPage = lazy(() => import("../components/pages/account/UserLogsPage/UserLogsPage"));
export const UserProfilePage = lazy(() => import("../components/pages/account/UserProfilePage/UserProfilePage"));
export const UserSettingsPage = lazy(() => import("../components/pages/account/UserSettingsPage/UserSettingsPage"));
export const UsersPage = lazy(() => import("../components/pages/account/UsersPage/UsersPage"));

// ANNOUNCEMENTS
export const EventsPage = lazy(() => import("../components/pages/announcements/EventsPage/EventsPage"));
export const GmNotesPage = lazy(() => import("../components/pages/announcements/GmNotesPage/GmNotesPage"));
export const NoticesPage = lazy(() => import("../components/pages/announcements/NoticesPage/NoticesPage"));
export const UpdatesPage = lazy(() => import("../components/pages/announcements/UpdatesPage/UpdatesPage"));

// COLLECTIONS
// TODO

// GAME
export const AdventurersGuidePage = lazy(
  () => import("../components/pages/game/AdventurersGuidePage/AdventurersGuidePage"),
);
export const ClassIntroductionPage = lazy(
  () => import("../components/pages/game/ClassIntroductionPage/ClassIntroductionPage"),
);
export const FeaturesPage = lazy(() => import("../components/pages/game/FeaturesPage/FeaturesPage"));
export const HistoryPage = lazy(() => import("../components/pages/game/HistoryPage/HistoryPage"));
export const LorePage = lazy(() => import("../components/pages/game/LorePage/LorePage"));
export const StatisticsPage = lazy(() => import("../components/pages/game/StatisticsPage/StatisticsPage"));
export const RulesPage = lazy(() => import("../components/pages/game/RulesPage/RulesPage"));

// LEGAL
export const ImprintPage = lazy(() => import("../components/pages/legal/ImprintPage/ImprintPage"));
export const EulaPage = lazy(() => import("../components/pages/legal/EulaPage/EulaPage"));
export const TosPage = lazy(() => import("../components/pages/legal/TosPage/TosPage"));
export const PrivacyPolicyPage = lazy(() => import("../components/pages/legal/PrivacyPolicyPage/PrivacyPolicyPage"));

// WIKI
export const ClassesPage = lazy(() => import("../components/pages/wiki/ClassesPage/ClassesPage"));
export const ClassPage = lazy(() => import("../components/pages/wiki/ClassPage/ClassPage"));
export const CraftingPage = lazy(() => import("../components/pages/wiki/CraftingPage/CraftingPage"));
export const ItemsPage = lazy(() => import("../components/pages/wiki/ItemsPage/ItemsPage"));
export const MapsPage = lazy(() => import("../components/pages/wiki/MapsPage/MapsPage"));
export const MonstersPage = lazy(() => import("../components/pages/wiki/MonstersPage/MonstersPage"));
export const RacesPage = lazy(() => import("../components/pages/wiki/RacesPage/RacesPage"));
export const SkillsPage = lazy(() => import("../components/pages/wiki/SkillsPage/SkillsPage"));

// MULTIMEDIA
export const ArtworksPage = lazy(() => import("../components/pages/multimedia/ArtworksPage/ArtworksPage"));
export const DownloadsPage = lazy(() => import("../components/pages/multimedia/DownloadsPage/DownloadsPage"));
export const ScreenshotsPage = lazy(() => import("../components/pages/multimedia/ScreenshotsPage/ScreenshotsPage"));
export const SoundtracksPage = lazy(() => import("../components/pages/multimedia/SoundtracksPage/SoundtracksPage"));
export const VideosPage = lazy(() => import("../components/pages/multimedia/VideosPage/VideosPage"));
export const WallpapersPage = lazy(() => import("../components/pages/multimedia/WallpapersPage/WallpapersPage"));

// SUPPORT
export const AdminPage = React.lazy(() =>
  // AdminPage is being lazy loaded for testing purpose
  import("../components/pages").then(sleeper(2000)).then((module) => ({ default: module.AdminPage })),
);
export const SubmitTicketPage = lazy(() => import("../components/pages/support/SubmitTicketPage/SubmitTicketPage"));
export const ContactPage = lazy(() => import("../components/pages/support/ContactPage/ContactPage"));
export const DevelopmentPage = lazy(() => import("../components/pages/support/DevelopmentPage/DevelopmentPage"));
export const FaqPage = lazy(() => import("../components/pages/support/FaqPage/FaqPage"));
export const HelpPage = lazy(() => import("../components/pages/support/HelpPage/HelpPage"));
export const SecurityCenterPage = lazy(
  () => import("../components/pages/support/SecurityCenterPage/SecurityCenterPage"),
);
