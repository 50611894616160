import translationsEN from "../translations/en.json";
import translationsDE from "../translations/de.json";
import translationsKO from "../translations/ko.json";
import translationsTR from "../translations/tr.json";
import translationsPT from "../translations/pt.json";
import translationsHE from "../translations/he.json";

/**
 * NOTE: This is not 100% DRY. Consider changing the following as well:
 * - src/hooks/useSupportedLanguages.ts
 */
export default function getTranslations(): Record<string, Record<string, string>> {
  return {
    en: translationsEN,
    de: translationsDE,
    ko: translationsKO,
    tr: translationsTR,
    pt: translationsPT,
    he: translationsHE,
  };
}
