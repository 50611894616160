import React from "react";
import classNames from "classnames";

import { Button, Checkbox, EButtonType } from "@fhx/ui";
import Money from "@fhx/ui/build/components/common/Money/Money";
import Item from "@fhx/ui/build/components/entities/Item/Item";
import styles from "./AuctionHouseWindow.module.scss";
import item from "../../../mocks/item";
import DefaultWindow from "../DefaultWindow/DefaultWindow";

export interface IProps {
  className?: string;
  style?: React.CSSProperties;
}

export default function AuctionHouseWindow({ className, style }: IProps): React.ReactElement {
  return (
    <DefaultWindow header="Auction House" className={classNames(styles.window, className)} style={style}>
      <div className={styles.filters}>
        Filters:
        <div>
          Category:
          <select>
            <option>...</option>
            <option>Armor</option>
            <option>Weapons</option>
            <option>Jewelery</option>
            <option>Potations</option>
            <option>Materials</option>
            <option>Sheets</option>
            <option>Special</option>
          </select>
        </div>
        <div>
          Type Weapons:
          <select>
            <option>...</option>
            <option>Axes</option>
            <option>Bows</option>
            <option>Clubes</option>
            <option>Daggers</option>
            <option>Hammer</option>
            <option>Polearms</option>
            <option>Staffs</option>
            <option>Swords</option>
            <option>Wands</option>
          </select>
        </div>
        <div>
          Type Armor:
          <select>
            <option>...</option>
            <option>Boots</option>
            <option>Capes</option>
            <option>Circlets</option>
            <option>Gloves</option>
            <option>Helmets</option>
            <option>Jackets</option>
            <option>Pants</option>
            <option>Robes</option>
          </select>
        </div>
        <div>
          Required Level:
          <select>
            <option>...</option>
            <option>1</option>
            <option>5</option>
            <option>10</option>
            <option>15</option>
            <option>20</option>
            <option>28</option>
            <option>32</option>
            <option>36</option>
            <option>40</option>
            <option>44</option>
            <option>47</option>
            <option>50</option>
            <option>53</option>
            <option>56</option>
            <option>59</option>
            <option>62</option>
            <option>65</option>
            <option>72</option>
          </select>
        </div>
        <div>
          Unique:
          <Checkbox type="RADIO_BTN" />
        </div>
        <div>
          Rarity:
          <select>
            <option>...</option>
            <option style={{ color: "#005500" }}>Unique</option>
            <option style={{ color: "#DAA949" }}>Soul</option>
            <option style={{ color: "#FFFF00" }}>Rare</option>
            <option style={{ color: "#3EC1C5" }}>Magic</option>
            <option style={{ color: "#FFF" }}>Normal</option>
          </select>
        </div>
        <div>
          Magic Opts:
          <select>
            <option>...</option>
            <option>1</option>
            <option>2</option>
            <option>3</option>
            <option>4</option>
            <option>5</option>
          </select>
        </div>
        <div>
          Enhnacements:
          <select>
            <option>...</option>
            <option>+1</option>
            <option>+2</option>
            <option>+3</option>
            <option>+4</option>
            <option>+5</option>
            <option>+6</option>
            <option>+7</option>
            <option>+8</option>
            <option>+9</option>
            <option>+10</option>
          </select>
        </div>
        <button type="button">Apply</button>
      </div>
      <div className={styles.offers}>
        <table>
          <thead>
            <tr>
              <td />
              <td>Trade ID</td>
              <td>Icon</td>
              <td>Name</td>
              <td>Rarity</td>
              <td>Unique</td>
              <td>Quantity</td>
              <td>Price</td>
              <td>Buy</td>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>
                <Checkbox type="OPTION_CHECK_BTN" checked />
              </td>
              <td>1</td>
              <div>
                <Item item={item} style={{ height: "32px" }} />
              </div>
              <td>Andorra Lion Skin</td>
              <td>Normal</td>
              <td>
                <Checkbox type="RADIO_BTN" disabled />
              </td>
              <td>3487</td>
              <td>
                <Money moneyDivide={{ pp: 0, gp: 0, cp: 21 }} showSum />
              </td>
              <td>
                <input type="number" style={{ width: "24px" }} value={1} />
                <Button type={EButtonType.CMD_EDIT}>Buy</Button>
              </td>
            </tr>
            <tr>
              <td>
                <Checkbox type="OPTION_CHECK_BTN" checked />
              </td>
              <td>2</td>
              <div>
                <Item item={item} style={{ height: "32px" }} />
              </div>
              <td style={{ color: "#FF0" }}>Forbidden Blade of the Ancient</td>
              <td style={{ color: "#FF0" }}>Rare</td>
              <td>
                <Checkbox type="RADIO_BTN" checked disabled />
              </td>
              <td>3487</td>
              <td>
                <Money moneyDivide={{ pp: 20, gp: 2, cp: 21 }} showSum />
              </td>
              <td>
                <Button type={EButtonType.CMD_EDIT}>Buy</Button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div className={styles.options}>
        Selected (2):
        <select>
          <option>mark as favorite</option>
        </select>
        <button type="button">OK</button>
      </div>
      <div className={styles.selling}>
        Cash in Bank: <Money moneyDivide={{ pp: 12, gp: 4230, cp: 21 }} showSum />
        <Button type={EButtonType.GUILD_BASE1_230X26}>Create Offer</Button>
        <Button type={EButtonType.GUILD_BASE1_230X26}>View Offers</Button>
        <div style={{ color: "#A00", fontWeight: "bold" }}>Your bank is full !</div>
      </div>
    </DefaultWindow>
  );
}
