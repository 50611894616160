import React from "react";
import { Modal } from "@fhx/ui";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faWrench } from "@fortawesome/free-solid-svg-icons";
import styles from "./MaintenanceView.module.scss";
import discord from "./img/discord.jpg";
import { DISCORD_URL, WEBSITE_TITLE } from "../../../config";
import { BackgroundVideo } from "../../layout";
import { DefaultWindow } from "../../windows";

export default function MaintenanceView(): React.ReactElement {
  return (
    <div className={styles.view}>
      <BackgroundVideo className={styles.backgroundVideo} />
      <div className={styles.content}>
        <Modal>
          <DefaultWindow className={styles.window}>
            <FontAwesomeIcon icon={faWrench} />
            <h4>{WEBSITE_TITLE} is currently under maintenance.</h4>
            <h4>Please be patient and try again later.</h4>
            <p>For more information or updates, please visit your Discord Server.</p>
            <a href={DISCORD_URL} target="_blank" rel="noopener noreferrer">
              <img src={discord} alt="Join us on Discord" />
            </a>
          </DefaultWindow>
        </Modal>
      </div>
    </div>
  );
}
