import React, { useCallback, useState } from "react";
import classNames from "classnames";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronCircleLeft,
  faChevronCircleRight,
  faPauseCircle,
  faPlayCircle,
  faVolumeDown,
  faVolumeUp,
} from "@fortawesome/free-solid-svg-icons";
import ReactSound from "react-sound";
import styles from "./MusicPlayer.module.scss";
import { ASSETS_MUSIC } from "../../../../../assets";

interface IMusicEntry {
  filename: string;
  title: string;
}

const entries: Array<IMusicEntry> = [
  { filename: "battleground.mp3", title: "Battleground" },
  { filename: "darkknight.mp3", title: "Dark Knight" },
  { filename: "desert.mp3", title: "Desert" },
  { filename: "forestevil.mp3", title: "Forest Evil" },
  { filename: "grassland01.mp3", title: "Grassland 01" },
  { filename: "grassland02.mp3", title: "Grassland 02" },
  { filename: "lobby.mp3", title: "Lobby" },
  { filename: "movefast.mp3", title: "Move Fast" },
  { filename: "paradiseinthecity.mp3", title: "Paradise In The City" },
  { filename: "peaceofmindinthecity.mp3", title: "Peace Of Mind In The City" },
  { filename: "swamp.mp3", title: "Swamp" },
  { filename: "thecastle.mp3", title: "The Castle" },
  { filename: "thetemple.mp3", title: "The Temple" },
  { filename: "whiteforest.mp3", title: "White Forest" },
];

function getRandomGapDuration(min = 10000, max = 10000): number {
  return Math.floor(Math.random() * (+min - +max)) + +max;
}

export interface IProps {
  className?: string;
  style?: React.CSSProperties;
}

export default function MusicPlayer({ className, style }: IProps): React.ReactElement {
  const [status, setStatus] = useState<"PLAYING" | "STOPPED" | "PAUSED">("STOPPED");
  const [currentIndex, setCurrentIndex] = useState<number>(11);
  const [volume, setVolume] = useState<number>(50);
  const currentEntry = entries[currentIndex];
  const src = `${ASSETS_MUSIC}/${currentEntry.filename}`;

  const increaseVolume = useCallback(
    // eslint-disable-next-line @typescript-eslint/no-inferrable-types
    (by: number = 10): void => {
      // setStatus("STOPPED");
      const newVal = volume + by;
      setVolume(volume + 10);
      if (newVal > 100) {
        setVolume(100);
      } else if (newVal < 0) {
        setVolume(0);
      } else {
        setVolume(newVal);
      }
    },
    [volume],
  );

  const increaseIdx = useCallback(
    // eslint-disable-next-line @typescript-eslint/no-inferrable-types
    (by: number = 1): void => {
      const newVal = currentIndex + by;
      if (newVal > entries.length - 1) {
        setCurrentIndex(0);
      } else if (newVal < 0) {
        setCurrentIndex(entries.length - 1);
      } else {
        setCurrentIndex(newVal);
      }
    },
    [currentIndex],
  );

  const onFinishedPlaying = useCallback((): void => {
    const gapDuration = getRandomGapDuration();
    setTimeout(() => {
      setStatus("STOPPED");
      setStatus("PLAYING");
    }, gapDuration);
  }, []);

  return (
    <div className={classNames(styles.player, className)} style={style}>
      <ReactSound key={src} url={src} volume={volume} playStatus={status} onFinishedPlaying={onFinishedPlaying} />
      <div className={styles.buttonsAndVolume}>
        <div>
          <div className={styles.buttons}>
            <FontAwesomeIcon
              icon={faChevronCircleLeft}
              className={classNames(styles.button, styles.prev)}
              onClick={(): void => increaseIdx(-1)}
            />
            {status === "PLAYING" && (
              <FontAwesomeIcon
                icon={faPauseCircle}
                className={classNames(styles.button, styles.pause)}
                onClick={(): void => setStatus("PAUSED")}
              />
            )}
            {status !== "PLAYING" && (
              <FontAwesomeIcon
                icon={faPlayCircle}
                className={classNames(styles.button, styles.play)}
                onClick={(): void => setStatus("PLAYING")}
              />
            )}
            <FontAwesomeIcon
              icon={faChevronCircleRight}
              className={classNames(styles.button, styles.next)}
              onClick={(): void => increaseIdx()}
            />
          </div>
          <div className={styles.title}>{currentEntry.title}</div>
        </div>
        <div className={styles.volume}>
          <FontAwesomeIcon
            icon={faVolumeUp}
            className={classNames(styles.button, styles.volUp)}
            onClick={(): void => increaseVolume()}
          />
          <div className={styles.value}>{volume}</div>
          <FontAwesomeIcon
            icon={faVolumeDown}
            className={classNames(styles.button, styles.volDown)}
            onClick={(): void => increaseVolume(-10)}
          />
        </div>
      </div>
    </div>
  );
}
