/**
 * NOTE: This is not 100% DRY. Consider changing the following as well:
 * - src/hooks/useSupportedLanguages.ts
 */
export function getSupportedLocales(): Array<string> {
  return ["en-GB", "de", "pt", "he", "ko", "tr"];
}

export function getDefaultLocale(): string {
  return "en-GB";
}

export function getDesiredLocale(): string | null {
  return localStorage.getItem("language");
}

export function setDesiredLocale(desiredLocale: string): void {
  return localStorage.setItem("language", desiredLocale);
}

export function getLocale(): string {
  const desiredLocale = getDesiredLocale();
  const supportedLocales = getSupportedLocales();
  if (desiredLocale && supportedLocales.includes(desiredLocale)) {
    return desiredLocale;
  }
  return getDefaultLocale();
}

export function getClientLanguage(): string {
  return window.navigator.language;
}

export function getIsoLocale(locale: string): string {
  // Transformation from BCP 78 (IETF) to ISO 639‑1
  // Example: "en-US" => "en"
  // Example: "de" => "de"
  return locale.split("-").shift() || locale;
}

export function getClientLocale(): string {
  const userLanguage = getClientLanguage();
  return getIsoLocale(userLanguage);
}
