import { ApolloClient, InMemoryCache, NormalizedCacheObject } from "@apollo/client";
import { WEBSITE_NAME, WEBSITE_VERSION } from "../../config";
import defaultOptions from "./defaultOptions";
import getApolloClientLink from "./getApolloClientLink";

export default function getApolloClient(): ApolloClient<NormalizedCacheObject> {
  return new ApolloClient({
    link: getApolloClientLink(),
    cache: new InMemoryCache(),
    name: WEBSITE_NAME,
    version: WEBSITE_VERSION,
    defaultOptions,
  });
}
