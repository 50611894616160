import { defineMessages } from "react-intl";

export default defineMessages({
  title: {
    id: "verifyEmailWindow.title",
    defaultMessage: "Verify Email Address",
    description: "",
  },
  failDescription: {
    id: "verifyEmailWindow.failDescription",
    defaultMessage:
      "Email address could not be verified. Maybe your email address is already verified or the verification link has expired. In case of the latter, please re-create the account and verify the used email address immediately.",
    description: "",
  },
  successDescription: {
    id: "verifyEmailWindow.successDescription",
    defaultMessage: "Your email address has been verified successfully. You can now use your account to log in.",
    description: "",
  },
  close: {
    id: "verifyEmailWindow.close",
    defaultMessage: "Close",
    description: "",
  },
});
