import { IWebsiteCharacterClass } from "../../interfaces";
import { ECharacterStrengthMeter } from "../../enums";
import defaultAttributMultipliers from "./defaultAttributMultipliers";

const warrior: IWebsiteCharacterClass = {
  id: "warrior",
  isEnabled: true,
  i18nTag: {
    en: "Wr",
    de: "Kr",
  },
  i18nName: {
    en: "Warrior",
    de: "Krieger",
  },
  roles: ["Melee damage", "Enemy control"],
  i18nDescription: {
    en: "A warrior stands ahead of the party group, possessing an overwhelming life-force and physical strength.",
    /*
    Warriors with their toughness and immense strength are always in the front line. They are specialised in close combat weapons.
    They prefer mighty weapons, in particular two-handed swords.Concerning warriors, attacking is the best defence.
    The main status values are STR and VIT. Strength embiggens their attacking energy and vitality enables them to put away blows.
    */
    de: "Ein Krieger steht einer Gruppe vor, da er über eine überwältigende Lebenskraft und körperliche Stärke verfügt.",
  },
  strengthMeters: [
    { id: ECharacterStrengthMeter.ATTACK, value: 80 },
    { id: ECharacterStrengthMeter.DEFENSE, value: 50 },
    { id: ECharacterStrengthMeter.PARTY, value: 40 },
    { id: ECharacterStrengthMeter.SOLO, value: 30 },
  ],
  difficulty: "MEDIUM",
  offensive: "HIGHT",
  defense: "MEDIUM",
  preferredWeapons: ["axe", "shield"],
  preferredArmor: ["jacket", "pants", "helmet", "gloves", "boots", "cape"],
  preferredAccessoires: ["ring", "necklace"],
  classSkillModels: [],
  classSkillModelsV2: [],
  classSkillComboModels: [],
  classSkillComboModelsV2: [],
  attributeMultipliers: defaultAttributMultipliers,
};

export default warrior;
