import { defineMessages } from "react-intl";

export default defineMessages({
  logIn: {
    id: "header.accountControls.logIn",
    defaultMessage: "Log In",
    description: "",
  },
  logOut: {
    id: "header.accountControls.logOut",
    defaultMessage: "Log Out",
    description: "",
  },
  register: {
    id: "header.accountControls.register",
    defaultMessage: "Register",
    description: "",
  },
  gm: {
    id: "header.accountControls.icon.gm",
    defaultMessage: "GM",
    description: "",
  },
});
