import { IWebsiteCharacterRace } from "../../interfaces";
import { archer, knight, paladin, warrior } from "../characterClasses";

const characterRace: IWebsiteCharacterRace = {
  id: "barbarian",
  isEnabled: true,
  i18nTag: {
    en: "Ba",
    de: "Ba",
  },
  i18nName: {
    en: "Barbarian",
    de: "Barbar",
  },
  i18nDescription: {
    en: "Born and raised to be mighty warriors, barbarians are dominant with their fighting powers and strength. These factors make them superior in melee battles.",
    /*
      Barbarians always been known as good defender of the kingdom. Their endurance is just one of many characteristics they are blessed with.
      */
    de: "Da die Barbaren von Geburt an darauf trainiert werden, mächtige Krieger zu sein, dominieren sie durch ihre Kampfkünste und Kraft. Durch diese Faktoren sind sie in Nahkämpfen überlegen.",
  },
  mainAttributesBases: {
    vitality: 11,
    dexterity: 9,
    intelligence: 7,
    strength: 11,
  },
  allowsCharacterClasses: [archer, knight, paladin, warrior],
};

export default characterRace;
