import { defineMessages } from "react-intl";

export default defineMessages({
  username: {
    id: "loginWindow.username",
    defaultMessage: "Username",
    description: "",
  },
  password: {
    id: "loginWindow.password",
    defaultMessage: "Password",
    description: "",
  },
  login: {
    id: "loginWindow.login",
    defaultMessage: "Log In",
    description: "",
  },
  successfullyLoggedIn: {
    id: "loginWindow.successfullyLoggedIn",
    defaultMessage: "Successfully Logged In",
    description: "",
  },
  invalidUsernameOrPassword: {
    id: "loginWindow.invalidUsernameOrPassword",
    defaultMessage: "Invalid username or password",
    description: "",
  },
  emailNotVerified: {
    id: "loginWindow.emailNotVerified",
    defaultMessage: "The email address for this account has not been verified yet",
    description: "",
  },
  accountIsBanned: {
    id: "loginWindow.accountIsBanned",
    defaultMessage: "This account has been banned",
    description: "",
  },
  invalidCaptcha: {
    id: "loginWindow.invalidCaptcha",
    defaultMessage: "Invalid captcha",
    description: "",
  },
  unknownReason: {
    id: "loginWindow.unknownReason",
    defaultMessage: "Unknown reason",
    description: "",
  },
  timeout: {
    id: "loginWindow.timedOut",
    defaultMessage: "Timeout",
    description: "",
  },
  forgotUsernameOrPassword: {
    id: "loginWindow.forgotUsernameOrPassword",
    defaultMessage: "Forgot Username or Password",
    description: "",
  },
  resendVerificationMail: {
    id: "loginWindow.resendVerificationMail",
    defaultMessage: "Re-Send Verification Mail",
    description: "",
  },
  loginIsCurrentlyDisabled: {
    id: "loginWindow.loginIsCurrentlyDisabled",
    defaultMessage: "Log In is currently disabled",
    description: "",
  },
});
