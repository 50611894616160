import { IWebsiteCharacterClass } from "../../interfaces";
import { archer, cleric, enchanter, knight, necromancer, paladin, summoner, thief, warrior, wizard } from ".";

const entries: Array<IWebsiteCharacterClass> = [
  archer,
  cleric,
  enchanter,
  knight,
  necromancer,
  paladin,
  summoner,
  thief,
  warrior,
  wizard,
];

export default entries;
