import { HttpLink, ApolloLink, split, from } from "@apollo/client";
import { getMainDefinition } from "@apollo/client/utilities";
import { ConnectionParams, SubscriptionClient } from "subscriptions-transport-ws";
import { WebSocketLink } from "@apollo/client/link/ws";
import { createUploadLink } from "apollo-upload-client";
import { API_SERVER_HOST, API_GRAPHQL_SUBSCRIPTIONS_TIMEOUT_MS, API_SERVER_GRAPHQL } from "../../config";

function customFetch(input: RequestInfo, init?: RequestInit | undefined): Promise<Response> {
  return fetch(input, init).then((response) => {
    const { status, statusText } = response;
    if (response.status >= 400) {
      const errorMessage = `Error: ${statusText ? `${statusText} (${status})` : status.toString()}`;
      return Promise.reject(new Error(errorMessage));
    }
    return response;
  });
}

function getWsLink(headers?: Record<string, string>): WebSocketLink {
  return new WebSocketLink(
    new SubscriptionClient(API_SERVER_HOST, {
      reconnect: true,
      lazy: true,
      timeout: API_GRAPHQL_SUBSCRIPTIONS_TIMEOUT_MS,
      connectionParams: (): ConnectionParams => {
        return { headers };
      },
    }),
  );
}

function getAuthMiddleware(headers?: Record<string, string>): ApolloLink {
  return new ApolloLink((operation, forward) => {
    operation.setContext({ headers });
    return forward(operation);
  });
}

export default function getApolloClientLink(authToken?: string): ApolloLink {
  const headers: Record<string, string> = {};
  if (authToken) headers.Authorization = `Bearer ${authToken}`;

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const link = split(
    ({ query }) => {
      const definition = getMainDefinition(query);
      return definition.kind === "OperationDefinition" && definition.operation === "subscription";
    },
    getWsLink(headers),
    new HttpLink({ uri: API_SERVER_GRAPHQL, fetch: customFetch }),
  );
  const uploadLink = createUploadLink({ uri: API_SERVER_GRAPHQL });
  return from([getAuthMiddleware(headers), uploadLink /* ,  link */]);
}
